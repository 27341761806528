import { FireOutlined, StarOutlined } from "@ant-design/icons";
import TagWithIcon from "layouts/gallery/components/common/TagWithIcon";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setQueryParams } from "store/gallerySlice";
import { EMPTY_QUERY, LATEST_QUERY, TOP_QUERY, TRENDING_QUERY } from "variables/public";

const TAGS = [
  {
    query: TRENDING_QUERY,
    Icon: FireOutlined,
    name: "Trending",
  },
  {
    query: TOP_QUERY,
    Icon: StarOutlined,
    name: "Top",
  },
];

export default function HeaderBox() {
  const dispatch = useDispatch();
  const [activeTag, setActiveTag] = useState({});

  const toggleActive = (tag) => {
    // tag is currently active => remove
    if (activeTag.name === tag.name) {
      dispatch(setQueryParams(EMPTY_QUERY));
    } else {
      // tag is currently inactive => add
      dispatch(setQueryParams(tag.query));
    }

    setActiveTag((prevActiveTag) => (prevActiveTag.name === tag.name ? {} : tag));
  };

  return (
    <div className="sm:pl-6 flex flex-col w-full">
      <div className="flex flex-wrap gap-4 justify-between items-center w-full">
        <h1 className="flex items-center h-16 text-xl sm:text-3xl md:text-4xl font-medium text-slate-50">
          Community Showcase
        </h1>
        <div className="sm:pr-3 flex flex-row grow gap-1 sm:justify-end justify-start items-center w-auto">
          <div className="flex gap-2">
            {TAGS.map((tag) => (
              <TagWithIcon
                key={tag.name}
                className="py-2"
                onClick={() => toggleActive(tag)}
                Icon={tag.Icon}
                title={tag.name}
                isSelected={activeTag.name === tag.name}
              >{tag.name}</TagWithIcon>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
