import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api_v1 from 'plugins/axios';

// Get one prompt_template 
export const fetchPowerFormByToken = createAsyncThunk('power_form_store/fetchPowerFormByToken', async ({token, payload}) => {
  try {
    const response = await api_v1.get(`/webview/power_form/${token}`, { params: payload });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

// Sens Post Question
export const postQuestions = createAsyncThunk('power_form_store/postQuestions', async ({token, payload}) => {
  try {
    const response = await api_v1.post(`/webview/get_info/${token}`, payload);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

export const powerFormSlice = createSlice({
  name: 'powerForm',
  initialState: {
    formDetail: {},
    loading: false,
    error: null,
    localHistories: [],
    currentHistoryId: 0,
  },
  reducers: {
    addLocalHistory: (state, action) => { 
      state.localHistories = [{...action.payload}, ...state.localHistories ]
      state.currentHistoryId = action.payload.id
    },
    addTextToResponsePrompt: (state, action) => {
      state.localHistories= state.localHistories.map(history => {
        if (history.id === state.currentHistoryId) {
          return {
            ...history,
            answer: history.answer ? history.answer + action.payload : action.payload 
          };
        } else {
          return history;
        }
      });
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPowerFormByToken.pending, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchPowerFormByToken.fulfilled, (state, action) => {
        state.formDetail = action.payload.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchPowerFormByToken.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(postQuestions.pending, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(postQuestions.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(postQuestions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  }
})

export const { addLocalHistory, addTextToResponsePrompt } = powerFormSlice.actions


export default powerFormSlice.reducer