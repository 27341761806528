// @mui material components
import Card from "@mui/material/Card";
import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, Result, Typography } from 'antd';
const { Paragraph, Text } = Typography;
const StripeFail = () => (
  <div className='h-screen flex items-center justify-center flex-col'>
    <Card>
      <Result
        status="error"
        title="Apologies, but your payment was not successful or has been canceled."
        subTitle="Please double-check your payment information and try again. For further assistance, contact our customer support."
        extra={[
          <a href="/projects">
            <Button type="primary" key="console">
              View Projects
            </Button>
          </a>,
          <a href="/upgrade">
            <Button key="console">
              Buy Again
            </Button>
          </a>,
        ]}
      >
      </Result>
    </Card>
  </div>
);
export default StripeFail;