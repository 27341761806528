

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import linearGradient from "assets/theme/functions/linearGradient";
import colors from "assets/theme/base/colors";
import DeleteFile from "./DeleteFile";
import StatusFile from "./StatusFile";
import PreviewFile from "./PreviewFile";

function WebsiteUrl({ file, project }) {
  const { gradients } = colors;
  const { bill } = gradients;

  const convertToMegaBytes = (number) => {
    const bytes = Number(number);
    const megabytes = (bytes / 1000000).toFixed(2);
    return megabytes + " MB";
  }

  return (
    <VuiBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      sx={{ background: linearGradient(bill.main, bill.state, bill.deg) }}
      borderRadius="lg"
      p="24px"
      mb="12px"
      mt="10px"
    >
      <VuiBox width="100%" display="flex" flexDirection="column">
        <VuiBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mb="5px"
        >
          <VuiTypography
            variant="button"
            color="white"
            fontWeight="medium"
            textTransform="none"
          >
            <StatusFile status={file.status}/>
            Website
          </VuiTypography>

          <VuiBox
            display="flex"
            alignItems="center"
            mt={{ xs: 2, sm: 0 }}
            ml={{ xs: -1.5, sm: 0 }}
            sx={({ breakpoints }) => ({
              [breakpoints.only("sm")]: {
                flexDirection: "column",
              },
            })}
          >
            <VuiBox mr={1}>
              <DeleteFile file={file}/>
            </VuiBox>
            <PreviewFile file={file}/>
          </VuiBox>
        </VuiBox>
        <VuiBox mb={1} lineHeight={0}>
          <VuiTypography variant="caption" color="text">
            URL:&nbsp;&nbsp;&nbsp;
            <VuiTypography
              variant="caption"
              color="text"
              fontWeight="regular"
              textTransform="none"
            >
              <a href={file.meta_data.url} target="_blank" rel="nofollow noopener">{file.meta_data.url}</a>
              
            </VuiTypography>
          </VuiTypography>
        </VuiBox>
      </VuiBox>
    </VuiBox>
  );
}

// Setting default values for the props of WebsiteUrl
WebsiteUrl.defaultProps = {
  noGutter: false,
};

// Typechecking props for the WebsiteUrl
WebsiteUrl.propTypes = {
  file: PropTypes.object.isRequired,
};

export default WebsiteUrl;
