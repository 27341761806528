import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { uploadFile, fetchDetailData } from "store/projectSlice";
const { Dragger } = Upload;
const MAX_FILE_SIZE = 5; // 6MB

const UploadFiles = ({project}) => {
  const [fileList, setFileList] = useState([]);
  const dispatch = useDispatch()
  const props = {
    name: 'file',
    multiple: false,
    fileList: fileList,
    customRequest: ({ file, onSuccess }) => {
      let formData = new FormData();
      formData.append('file', file);
      dispatch(uploadFile({id: project.id, formData})).then((res) => {
        if(res.error) {
          message.error(res.error.message);
          setFileList([]); 
          return;
        }
        dispatch(fetchDetailData(project.id))
        onSuccess("ok");
      })
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
        setFileList([]); 
      }
    },
    onDrop(e) {
    },
    beforeUpload(file) {
      const isLimit = file.size / 1024 / 1024 < MAX_FILE_SIZE;
      if (!isLimit) {
        message.error(`Error: ${file.name} exceeds 5MB`);
      }
      if(isLimit) setFileList([file]);
      return isLimit;
    },
  };
  return (
    <Dragger {...props}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag file to upload</p>
      <p className="ant-upload-hint">
        Supported: CSV (max 5MB, 300 rows), PDF (max 5MB), TXT (max 5MB)
      </p>
    </Dragger>
  )
};
export default UploadFiles;