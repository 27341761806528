import { Tag } from 'antd';
import VuiTypography from 'components/VuiTypography';
const StatusFile = ({status}) => {
  const statusColor = (status) => {
    switch (status) {
      case "Pending":
        return "gold"
      case "Processing":
        return "blue"
      case "Completed":
        return "green"
      case "Error":
        return "red"
      default:
        return "orange"
    }
  }

  return (
    <Tag color={statusColor(status)}>
      <VuiTypography
        variant="caption"
        color="text"
        fontWeight="regular"
        textTransform="uppercase"
      >{status}</VuiTypography>
    </Tag>
  )
};
export default StatusFile;