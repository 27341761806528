import React, { useState } from 'react'
import { Input } from 'antd';
import { useDispatch } from 'react-redux';
import { updatePromptDetail } from "store/promptSlice";

const { TextArea } = Input;
export default function TextAreaOptionWithInput({placeholder = '', keyword, label, defaultValue}) {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState(defaultValue);
  const onChange = (e) => {
    setInputValue(e.target.value);
    dispatch(updatePromptDetail({[keyword]: e.target.value}))
  };
  
  const renderLabel = () => {
    if(!label) return <></>
    return <label><b>{label}</b></label>
  }

  return (
    <div className='mb-4'>
      {renderLabel()}
      <TextArea rows={4} placeholder={placeholder} maxLength={100} onChange={onChange} value={inputValue}/>
    </div>
  )
}
