import React, { useRef, useEffect } from 'react'
import { Col, Row, Select, Space } from 'antd';
import VuiTypography from "components/VuiTypography";
import { Input, Divider, Button, Tag  } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ContentEditable from 'react-contenteditable'
import sanitizeHtml from 'sanitize-html'
import { v4 as uuidv4 } from 'uuid';
import Gpt from 'examples/Icons/Gpt';
import { useDispatch, useSelector } from 'react-redux';
import { addQuestion, updatePromptDetail, sortQuestionByIdOrder, getPromptDetail, setHighlightPrompt } from "store/promptSlice";
import { Tooltip } from 'antd';
const { TextArea } = Input;

export default function PromptTextArea({promptHtml = "", questions}) {
  const dispatch = useDispatch()
  const promptRef = useRef(null);
  const highlightPrompt = useSelector(state => state.prompt.highlightPrompt)
  const model = useSelector(state => state.prompt.promptDetail.model)
  const plan = useSelector(state => state.user.user.agent.package.kind)
  const [highlightMark, setHighlightMark] = React.useState(null)
  let clipboardText = ''
  const sanitizedConfig = {
    allowedTags: ['mark', 'br', 'div'],
    allowedAttributes: {
      mark: ['id', 'class', 'contenteditable'],
    },
    parseStyleAttributes: false,
  }

  useEffect(() => {
    let currentMark = getAllMarks().find(e => e.id == highlightPrompt?.id)
    if(currentMark) { 
      currentMark.innerText = highlightPrompt.example 
      handleChange(promptRef.current.el.current.innerHTML)
    }
  }, [highlightPrompt?.example]);

  useEffect(() => {
    if(questions && questions.length) {
      dispatch(setHighlightPrompt(questions.find(e => e.id === highlightMark)))
    }
  }, [highlightMark])

  const handleChange = (value) => { 
    let allMarks = getAllMarks()
    let mark_ids = allMarks.map(e => e.id)
    let question_ids = questions.map(e => e.id)

    dispatch(updatePromptDetail({questions: questions.filter(e => mark_ids.includes(e.id)) }))
    allMarks.map(mark => { 
      if(!question_ids.includes(mark.id)) {
        createQuestion({id: mark.id, content: mark.outerText, main: findMainQuestion(mark.outerText)})
      }
    })

    let sanitizedHtml = sanitizeHtml(promptRef.current.el.current.innerHTML, sanitizedConfig);
    dispatch(updatePromptDetail({prompt: sanitizedHtml}))
  }

  const handleAddClick = (event) => {
    event.preventDefault(); // Avoids loosing focus from the editable area

    document.getElementById('prompt').focus(); // Focuses again on the editable area
    let range = window.getSelection().getRangeAt(0);

    const id = uuidv4();
    let content = range.toString() || 'User Input';

    createMark({range, id, content})
    createQuestion({id, content})
  }

  const createMark = async ({range, id, content}) => { 
    const oldContent = document.createTextNode(content);
    const newElement = document.createElement('mark');
    newElement.contentEditable = false;
    newElement.className = 'prompt-tag';
    newElement.id = id;
    newElement.append(oldContent);
    range.deleteContents();
    range.insertNode(document.createTextNode("\u00A0"));
    range.insertNode(newElement);
    range.insertNode(document.createTextNode("\u00A0"));

    handleChange(promptRef.current.el.current.innerHTML)
  }

  const createQuestion = ({id, content, main = ""}) => {
    dispatch(addQuestion({
        "id": id,
        "answerlength": "255",
        "example": content,
        "main": main,
        "secondary": "",
        "type": "text"
      }))
    dispatch(sortQuestionByIdOrder(getAllMarks().map(e=>e.id)))
  }

  const getAllMarks = () => { 
    return Array.from(promptRef.current.el.current.querySelectorAll('mark'))
  }

  const handlePromptClick = (event) => {
    if (event.target.tagName == 'MARK') {
      setHighlightMark(event.target.id)
    }
  };

  const handleModelGptChange = (value) => {
    dispatch(updatePromptDetail({model: value}))
  }

  const handleCopy = (e) => {
    let copyHtml = getSelectionHtml()
    let _clipboard = updateMarkIds(copyHtml)
    clipboardText = _clipboard
    navigator.clipboard.write([new ClipboardItem({
      'text/html': new Blob([_clipboard], {type: 'text/html'})
    })])
  }

  const handleKeyDown = (e) => {
    var ctrlDown = e.ctrlKey || e.metaKey
    if(ctrlDown && e.which === 86) {
      let _clipboard = updateMarkIds(clipboardText)
      navigator.clipboard.write([new ClipboardItem({
        'text/html': new Blob([_clipboard], {type: 'text/html'})
      })])
    }
  }

  function getSelectionHtml() {
    var html = "";
    if (typeof window.getSelection != "undefined") {
        var sel = window.getSelection();
        if (sel.rangeCount) {
            var container = document.createElement("div");
            for (var i = 0, len = sel.rangeCount; i < len; ++i) {
                container.appendChild(sel.getRangeAt(i).cloneContents());
            }
            html = container.innerHTML;
        }
    } else if (typeof document.selection != "undefined") {
        if (document.selection.type == "Text") {
            html = document.selection.createRange().htmlText;
        }
    }
    return html;
  }

  const updateMarkIds = (input) => {
    const temporaryElement = document.createElement('div');
    temporaryElement.innerHTML = input;
  
    const markElements = temporaryElement.querySelectorAll('mark');
    markElements.forEach((markElement) => {
      markElement.id = uuidv4();
    });
  
    return temporaryElement.innerHTML;
  };

  const findMainQuestion = (example) => {
    if(!example) return ''
    let mainValue = null;
    for (const question of questions) {
      if (question.example === example) {
        mainValue = question.main;
        break;
      }
    }
    return mainValue
  }

  return (
    <div className="my-2" id="prompt-textarea-form">
      <VuiTypography color="white" fontWeight="bold" variant="xs" mb="4px">
        Prompt 
      </VuiTypography>
      <ContentEditable
        html={promptHtml}
        id="prompt"
        onChange={(e) => handleChange(e.target.value)}
        data-placeholder="Write your prompt here..."
        ref={promptRef}
        onClick={handlePromptClick}
        onCopy={handleCopy}
        onCut={handleCopy}
        onKeyDown={handleKeyDown}
      />
      <div className="flex justify-between">
        <Button type="primary" icon={<PlusOutlined />} onClick={handleAddClick}>
          Add User Input
        </Button>
        <Select
          style={{
            width: 200
          }}
          placeholder="Take it from here..."
          value={model || 'gpt-3.5-turbo'}
          optionLabelProp="label"
          onChange={handleModelGptChange}
        >
          <Select.Option value="gpt-3.5-turbo" label="GPT-3.5-turbo">
            <Tooltip placement="leftBottom" title="ChatGPT 3.5 Turbo (Cloud, Fastest)">
              <Space>
                <span role="img" aria-label="ChatGPT">
                  <Gpt />
                </span>
                GPT-3.5-turbo
              </Space>
            </Tooltip>
          </Select.Option>
          <Select.Option value="makergpt" label="MakerGPT" disabled={plan == 1}>
            <Tooltip placement="leftBottom" title="MakerGPT (Local, data not shared externally)">
              <Space>
                <span role="img" aria-label="ChatGPT">
                  <Gpt />
                </span>
                MakerGPT
              </Space>
            </Tooltip>
          </Select.Option>
          <Select.Option value="gpt-4" label="GPT-4" disabled>
            <Space>
              <span role="img" aria-label="ChatGPT">
                <Gpt />
              </span>
              GPT-4
            </Space>
          </Select.Option>
        </Select>
      </div>
    </div>
  )
}
