import { useState } from "react";
import { Card, Stack } from "@mui/material";
import { Form, Divider, Input, Button, Col, Row } from "antd";
import VuiTypography from "components/VuiTypography";
import Grid from "@mui/material/Grid";
import VuiBox from "components/VuiBox";
import { useDispatch } from "react-redux";
import Tier from "../Tier";
const TierGroup = ({packages = []}) => {
  const dispatch = useDispatch();
  //input styles
  const inputStyle = {
    background: "none",
    backgroundColor: "none",
    border: "0.5px solid rgba(226, 232, 240, 0.4)",
    borderRadius: "0.9375rem",
  };
  //button styles
  const buttonStyle = {
    width: "100%",
    padding: "0",
  };

  const renderTier = () => {
    return packages.map((tier, index) => {
      return (
        <Col xs={24} xl={8} xxl={8} key={index}>
          <Tier tier={tier} />
        </Col>
      );
    });
  }

  return (
    <Card style={{ height: "100%" }}>
      <VuiBox display="flex" flexDirection="column">
        <VuiTypography variant="lg" fontWeight="bold" mb="6px" color="white">
          Plan subscription
        </VuiTypography>
        <VuiTypography
          variant="button"
          fontWeight="regular"
          mb="30px"
          color="white"
        >
          Upgrade for enjoy more of MakerGPT
        </VuiTypography>
        <Stack>
          <VuiBox>
            <Row gutter={[25, 25]} align="space-between">
              { renderTier() }
            </Row>
          </VuiBox>
        </Stack>
      </VuiBox>
    </Card>
  );
};

export default TierGroup;
