import { Card as MuiCard } from "@mui/material";
import { Col, Tabs } from 'antd';
import AdvancedOptions from '../AdvancedOptions';
import PromptFrameTab from './PromptFrameTabs/PromptFrameTab';
import TrainModelBox from "../../../../examples/TrainModelBox";

export default function PromptFrame({promptDetail}) {
  const items = [
    {
      key: '1',
      label: `Prompt Template`,
      children: <PromptFrameTab promptDetail={promptDetail} />,
    },
    {
      key: '2',
      label: `Train Model`,
      // children: <>Hello</>,
      children: <TrainModelBox projectId={promptDetail.project_id}/>,
    },
    {
      key: '3',
      label: `Advanced Options`,
      children: <AdvancedOptions promptDetail={promptDetail}/>,
    },
  ];

  return (
    <Col span={11}>
      <MuiCard>
        <Tabs defaultActiveKey="1" items={items} />
      </MuiCard>
    </Col>
  )
}
