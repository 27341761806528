

// @mui material components
import Card from "@mui/material/Card";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import CustomProjectCard from "examples/Cards/ProjectCards/CustomProjectCard";
import Grid from "@mui/material/Grid";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import { Button, Empty, Spin } from 'antd';
import { useState, useEffect, useRef } from 'react';
import CreateProjectModal from "layouts/chat/components/CreateProjectModal";

import { useSelector, useDispatch } from 'react-redux';
import { getData, fetchData, fetchMoreData, setPage } from "../../store/projectSlice";

import SearchProject from "./components/SearchProject";
import { Row, Col } from "antd"
import useInfiniteScroll from "hooks/useInfiniteScroll";
function Memory() {
  const projectState = useSelector((state) => ({
    data: state.project.data,
    paginator: state.project.paginator,
    page: state.project.page,
    searchQuery: state.project.searchQuery,
  }));

  const projectData = projectState.data

  const { isFetchingData } = useInfiniteScroll({ paginatorData: projectState, fetchDataAction: fetchData, fetchMoreDataAction: fetchMoreData, setPageAction: setPage });

  const renderProjectsV2 = () => { 
    return projectData.map((project, index) => {
      return (
        <Col xs={24} md={12} xl={8} key={index}>
          <CustomProjectCard
            label={`Project #${index + 1}`}
            title={project.name}
            description={project.description}
            created_at={project.created_at}
            action={{
              type: "internal",
              route: `/projects/${project.id}`,
              color: "white",
              label: "MANAGE",
            }}
            kind={project.kind}
            kind_text={project.kind_text}
            id={project.id}
            visitorsCount={project.visitorsCount}
            promptRunsCount={project.promptRunsCount}
          />
        </Col>
      )
    })
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <VuiBox mb={3}>
          <div>
            <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="22px">
              <div className="flex gap-4">
                <VuiTypography variant="lg" color="white" className="flex items-center">
                  My Projects
                </VuiTypography>
                <VuiBox className="center-an-item">
                  <SearchProject />
                </VuiBox>
              </div>
              <CreateProjectModal />
            </VuiBox>
            <VuiBox
              sx={{
                "& th": {
                  borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                    `${borderWidth[1]} solid ${grey[700]}`,
                },
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                      `${borderWidth[1]} solid ${grey[700]}`,
                  },
                },
              }}
            >
              { projectData?.length <= 0 ? 
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"Start your first GPT-powered project by clicking New Project"} /> :
                <Row gutter={[25, 25]}>
                  {renderProjectsV2()}
                  <Col xs={24} md={12} xl={8}>
                    <Spin spinning={isFetchingData} className="flex items-center justify-center h-full"/>
                  </Col>
                </Row>
              }
            </VuiBox>
          </div>
        </VuiBox>
      </VuiBox>
    </DashboardLayout>
  );
}

export default Memory;
