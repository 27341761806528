import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import { classNames } from "helpers/utils";
import TagList from "layouts/gallery/components/TagSection/TagList";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTags, resetSelectedTags, selectPublicTags } from "store/tagSlice";

function FilterTagModal({ shouldShowModal = true, onClose }) {
  const { isFetched, tags, selectedTags } = useSelector(selectPublicTags);
  const dispatch = useDispatch();

  useEffect(() => {
    if (tags.length === 0) {
      dispatch(fetchTags());
    }
  }, []);

  const handleClearSelectedTags = () => dispatch(resetSelectedTags())

  return (
    <>
      <div
        onClick={onClose}
        className={classNames(
          shouldShowModal ? "opacity-50 pointer-events-auto" : "pointer-events-none opacity-0",
          "z-40 transition-opacity fixed inset-0 bg-black"
        )}
      ></div>
      <div
        className={classNames(
          shouldShowModal ? "top-[10%]" : "top-[20%]",
          "z-50 bottom-[10%] pointer-events-none fixed  left-0 right-0 flex justify-center items-center"
        )}
      >
        <div
          className={classNames(
            shouldShowModal
              ? "translate-y-0 pointer-events-auto opacity-100"
              : "translate-y-full opacity-0",
            "transform transition-all duration-500 ",
            "gap-5 md:gap-0 flex flex-col h-[calc(100vh-74px)] max-w-xl p-6 bg-[#1f1f1f] text-white border border-gray-800/50 rounded-lg"
          )}
        >
          <div className="flex items-center justify-between">
            <h5 className="text-2xl font-bold tracking-tight text-white">Filters</h5>
            <CloseOutlined className="cursor-pointer" onClick={onClose} />
          </div>

          <Divider />

          <FilterSection title="Select topics that interest you">
            <TagList
              variant="blacknwhite"
              Icon={PlusOutlined}
              tags={tags}
              isFetched={isFetched}
              selectedTags={selectedTags}
            />
          </FilterSection>

          <Button
            onClick={handleClearSelectedTags}
            className={classNames(
              selectedTags.length > 0 && "bg-gray-300/40",
              "my-5 border border-white/50 bg-transparent hover:bg-gray-300/50 max-w-[150px]"
            )}
            type="primary"
          >
            Clear ({selectedTags.length} selected)
          </Button>
        </div>
      </div>
    </>
  );
}

function FilterSection({ title, children }) {
  return (
    <div className="max-h-[80%] overflow-auto opacity-90">
      <h3 className="font-medium text-lg">{title}</h3>
      <div className="mt-4 flex gap-2 flex-wrap items-center">{children}</div>
    </div>
  );
}

export default FilterTagModal;
