import { Col, InputNumber, Row, Slider, Space } from 'antd';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updatePromptDetail } from "store/promptSlice";

const SliderWithInput = ({min = 0, max = 100, label, keyword, step = 1, prefix, defaultValue}) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState(defaultValue);
  const onChange = (newValue) => {
    setInputValue(newValue);
    dispatch(updatePromptDetail({[keyword]: newValue}))
  };

  useEffect(() => {
    setInputValue(defaultValue)
  }, [defaultValue])

  const renderLabel = () => {
    if(!label) return <></>
    return <label><b>{label}</b></label>
  }
  return (
    <div>
      {renderLabel()}
      <Space
        style={{
          width: '100%',
        }}
        direction="vertical"
      >
        <Row>
          <Col xs={24} md={12} xl={18}>
            <Slider
              min={min}
              max={max}
              onChange={onChange}
              value={typeof inputValue === 'number' ? inputValue : 0}
              step={step}
            />
          </Col>
          <Col xs={24} md={12} xl={6}>
            <InputNumber
              min={min}
              max={max}
              style={{
                width: '100%',
              }}
              value={inputValue}
              onChange={onChange}
              step={step}
              addonAfter={prefix}
            />
          </Col>
        </Row>
      </Space>

    </div>
  );
};


export default SliderWithInput;