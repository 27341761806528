// @mui material components
import Card from "@mui/material/Card";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import Grid from "@mui/material/Grid";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import { Button, Modal, Tabs, Spin } from 'antd';
import { Fragment, useEffect, useState } from 'react';
import CreateProjectModal from "layouts/chat/components/CreateProjectModal";
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentTab, getDetailData } from "../../store/projectSlice";
import { FireFilled, SettingFilled, ApiFilled } from '@ant-design/icons';
import Header from "layouts/projects/components/Header";
import Overview from "layouts/projects/components/Overview";
import { fetchDetailData, resetProjectState } from "store/projectSlice";
import { resetPromptState } from "store/promptSlice";
import { fetchData } from "store/widgetSlice";
import Publish from "./components/Publish";
import Appearance from "./components/Appearance";
import PromptBuilder from "layouts/prompt/components/PromptBuilder";
import AppearancePrompt from "layouts/prompt/components/AppearancePrompt";
import PublishPrompt from "layouts/prompt/components/PublishPrompt";
import { setPromptDetail } from "store/promptSlice";
import { setWidget } from "store/widgetSlice";
import { projectTypes } from "variables/projects";

function Project() {
  const projectDetail = useSelector(getDetailData);
  const currentTab = useSelector(getCurrentTab);
  const dispatch = useDispatch();
  const {id} = useParams();

  useEffect(() => { 
    dispatch(fetchDetailData(id)).then((response) => {
      const promtTemplate = response.payload.data?.prompt_template
      if (promtTemplate) dispatch(setPromptDetail(promtTemplate))

      const widget = response.payload.data?.widget
      if (widget) dispatch(setWidget(widget))
    })
    return () => {
      dispatch(resetProjectState())
      dispatch(resetPromptState())
    }
  }, [])


  const renderContent = () => { 
    switch (currentTab) {
      case 0:
        if(projectDetail.kind === projectTypes.gpt_form.value) return <PromptBuilder project={projectDetail}/>
        if(projectDetail.kind === projectTypes.chatbot.value) return <Overview project={projectDetail}/>;
        if(projectDetail.kind === projectTypes.image_based_prompt.value) return Fragment
        break;
      case 1:
        if(projectDetail.kind === projectTypes.gpt_form.value) return <AppearancePrompt project={projectDetail}/>
        if(projectDetail.kind === projectTypes.chatbot.value)  return <Appearance project={projectDetail}/>;
        if(projectDetail.kind === projectTypes.image_based_prompt.value) return Fragment
        break;
      case 2:
        if(projectDetail.kind === projectTypes.gpt_form.value) return <PublishPrompt project={projectDetail}/>
        if(projectDetail.kind === projectTypes.chatbot.value)  return <Publish project={projectDetail}/>
        if(projectDetail.kind === projectTypes.image_based_prompt.value) return Fragment
        break;
      default:
        return <Overview />;
    }
  }
  return (
    !projectDetail || Object.keys(projectDetail).length <= 0 
      ? <div className="s-spin-container"><Spin spinning={true} /></div>
      : <DashboardLayout>
          <DashboardNavbar light routeTitle={projectDetail.name}/>
          <Header project={projectDetail}/>
          {renderContent()}
        </DashboardLayout>
  );
}

export default Project;
