import Card from "@mui/material/Card";
import { Input, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useState } from 'react';

import VuiTypography from "components/VuiTypography";
import VuiBox from "components/VuiBox";
import { updateWidget } from "store/widgetSlice";
import { updateData } from "store/projectSlice";

const { TextArea } = Input;
export default function Role() {
    const widget = useSelector((state) => state.widget.widget);
    const projectDetail = useSelector((state) => state.project.detail);
    const dispatch = useDispatch();

    const handleChange = (e) => {
      dispatch(updateWidget({ role: e.target.value }));
    };

    const handleDispatch = async () => {
        await dispatch(
          updateData({
            id: projectDetail.id,    
            kind: projectDetail.kind,
            name: projectDetail.name,
            description: projectDetail.description,
            role: widget.role,
          })
        )
          .then((response) => {
            if (response.error) {
              message.error(response.error.message);
            }
            else if (response.payload.statusCode === 200) {
              message.success("Successfully updated settings");
            }
          })
          .catch((err) => message.error(err))
          
    }

    return (
        <Card className="mb-6">
            <VuiBox>
                <VuiTypography variant="lg" fontWeight="bold" color="white" textTransform="capitalize">
                Role (Optional)
                </VuiTypography>
                <TextArea
                rows={3}
                placeholder="Provide a role for the model"
                value={widget.role}
                onChange={handleChange}
                onBlur={handleDispatch}
                />
            </VuiBox>
        </Card>
    )
}