import MessagesOnTheLeft from "../MessagesOnTheLeft";
import MessagesOnTheRight from "../MessagesOnTheRight";
import { CheckCircleFilled, SyncOutlined } from "@ant-design/icons"
import { v4 as uuidv4 } from "uuid"
const Messages = ({widget, data, hasNextPage}) => {
  const whichColorIsDarker = (color1, color2) => {
    const hexToDec = (hex) => parseInt(hex, 16);
    const decColor1 = hexToDec(color1.substr(1)); 
    const decColor2 = hexToDec(color2.substr(1));
  
    return (decColor1 < decColor2? color1: color2)
  }
  return (
    <>
    <label style={{ "color": whichColorIsDarker(widget.bot_background_color, widget.customer_background_color)}} className="center-an-item w-[100%] h-auto mx-auto">
        {hasNextPage === false ? <><CheckCircleFilled/>&nbsp;You have read all messages</>: <><SyncOutlined spin />&nbsp;Loading new messages</>}
      </label>
      {data !== null? data.map((message) => {
        return message.kind === 1 ? (
          <MessagesOnTheLeft widget={widget} content={message.content} key={uuidv4()}/>
        ) : (
          <MessagesOnTheRight widget={widget} content={message.content} key={uuidv4()}/>
        );
      }): <></>}
    </>
  );
};

export default Messages;
