import React from 'react'
import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import CodeBlock from './CodeBlock';
import { Col, Row, Space, Tabs } from 'antd';
import QuestionTable from './QuestionTable';
import InputCopy from 'layouts/projects/components/Publish/InputCopy';
import { useSelector } from 'react-redux';
export default function IntegrateCode() {
  const api_key = useSelector((state) => state.user.user.api_key);
  const items = [
    {
      key: 'curl',
      label: `cURL`,
      children: <CodeBlock language={"bash"}/>
    },
    {
      key: 'js',
      label: `NodeJs`,
      children: <CodeBlock language={"javascript"}/>,
    },
    {
      key: 'go',
      label: `Go`,
      children: <CodeBlock language={"go"}/>,
    },
    {
      key: 'python',
      label: `Python`,
      children: <CodeBlock language={"python"}/>,
    },
    {
      key: 'ruby',
      label: `Ruby`,
      children: <CodeBlock language={"ruby"}/>,
    },
    {
      key: 'php',
      label: `PHP`,
      children: <CodeBlock language={"php"}/>,
    },
    {
      key: 'rust',
      label: `Rust`,
      children: <CodeBlock language={"rust"}/>,
    },
  ];

  return (
    <Card>
      <VuiBox >
        <VuiTypography variant="lg" fontWeight="bold" color="white" textTransform="capitalize">
          Integrate the API
        </VuiTypography>
        <div>
          <div className="my-2">
            <div className="text-white text-xs">
              MakerGPT API Key
            </div>
          </div>
          <InputCopy link={api_key} type={"API Key"} />
        </div>
        <Row gutter={[12,12]} className='mt-4'>
          <Col xs={24} xl={12}>
            <QuestionTable />
          </Col>
          <Col xs={24} xl={12}>
            <Tabs
              type="card"
              items={items}
              className='[&>.ant-tabs-nav]:mb-0'
            >
            </Tabs>
          </Col>
        </Row>
      </VuiBox>
    </Card>
  )
}
