// @mui material components
import Card from "@mui/material/Card";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

import { Button, Tabs, Empty, Skeleton } from "antd";
import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { fetchDetailData } from "store/projectSlice";
import { ReloadOutlined } from "@ant-design/icons";
import UploadFiles from "../../layouts/projects/components/Overview/UploadFiles";
import UploadUrls from "../../layouts/projects/components/Overview/UploadUrls";
import FAQInputGroup from "../../layouts/projects/components/Overview/FAQInputGroup";
import InputText from "../../layouts/projects/components/Overview/InputText";
import WebsiteUrl from "../../layouts/projects/components/Overview/WebsiteUrl";
import File from "../../layouts/projects/components/Overview/File";

function TrainModelBox({ project, projectId, title }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  project = project || useSelector((state) => state.project.detail)

  useEffect(() => {
    if (!project) dispatch(fetchDetailData(projectId))
  }, []);

  const items = [
    {
      key: "1",
      label: `Files`,
      children: <UploadFiles project={project} />,
    },
    {
      key: "2",
      label: `URLs`,
      children: <UploadUrls project={project} />,
    },
    {
      key: "3",
      label: `FAQ`,
      children: <FAQInputGroup project={project} />,
    },
    {
      key: "4",
      label: `Text`,
      children: <InputText project={project} />,
    },
  ];
 
  const handleReloadClick = () => {
    setIsLoading(true);
    dispatch(fetchDetailData(project.id)).then(() => setIsLoading(false));
  };

  const renderFiles = (files) => {
    if (!files || files.length <= 0) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    const lastestFilesById = [...files].sort((a, b) => b.id - a.id);

    return lastestFilesById.map((file) => {
      if (file.kind === "website_url") {
        return <WebsiteUrl file={file} key={file.id} project={project} />;
      } else {
        return <File file={file} key={file.id} project={project} />;
      }
    });
  };

  return (
    <>
      <Card className="mb-6">
        <VuiBox>
          {title && (
            <VuiTypography variant="lg" fontWeight="bold" color="white" textTransform="capitalize">
              {title}
            </VuiTypography>
          )}
          <Tabs defaultActiveKey="1" items={items} />
        </VuiBox>
      </Card>
      <Card>
        <VuiBox mb="26px">
          <div className="flex justify-between">
            <VuiTypography variant="lg" fontWeight="bold" color="white" textTransform="capitalize">
              Trained With
            </VuiTypography>
            <Button type="primary" icon={<ReloadOutlined />} onClick={handleReloadClick} />
          </div>
          <VuiBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
            {isLoading ? <Skeleton active /> : renderFiles(project.file_embeddings)}
          </VuiBox>
        </VuiBox>
      </Card>
    </>
  );
}

export default TrainModelBox;
