import { classNames, cva } from "helpers/utils";

const TagVariants = cva(
  "transition-colors ease-in-out duration-300 flex justify-center items-center shadow-sm capitalize rounded-full gap-1 text-white/80 active:ring-2 active:ring-white/75 hover:bg-tag-100/50 ",
  {
    variants: {
      variant: {
        default: "bg-tag-500/70 aria-[selected=true]:bg-tag-100/50 aria-[selected=true]:text-white",
        darker: "bg-tag-200/30 rounded-none",
        expand: "bg-tag-100/30 rounded-none",
        blacknwhite: "bg-gray-800/30 border border-gray-500 rounded-none hover:bg-white/40 aria-[selected=true]:bg-white/30",
      },
      sizes: {
        default: "font-medium text-sm py-1 px-4",
        sm: "text-xs py-1 px-2",
      },
    },
    defaultVariants: {
      variant: "default",
      sizes: "default"
    }
  }
)


/**
 * @param {'default' | 'darker' | 'expand' | 'blacknwhite' } props.variant
 * @param {'default' | 'sm'} props.size
 */
function TagWithIcon({
  variant = "default",
  size = "default",
  Icon,
  className,
  onClick,
  isSelected,
  children,
  title,
  ...props
}) {
  const classes = classNames(className, TagVariants({ variant, sizes: size, isSelected }))
  
  return (
    <button
      onClick={onClick}
      title={`Sort by ${title}`}
      className={classes}
      aria-selected={isSelected}
      type="button"
      {...props}
    >
      {Icon && <Icon />}
      {children}
    </button>
  );
}

export default TagWithIcon;
