import darkenColor from "../DarkenColor";
const MessagesOnTheRight = ({ widget, content }) => {
  return (
    <div className="my-2 max-w-[80%] h-auto ml-auto">
      <p
        className="ml-auto p-3 rounded-2xl w-fit font-bold text-left border-none"
        style={{
          color: widget.bot_background_color,
          backgroundColor: widget.customer_background_color,
          boxShadow: `0px 0px 2px ${darkenColor({
            hexColor: widget.customer_background_color,
            darkerNumber: 0.95,
          })}`,
        }}
      >
        {content}
      </p>
    </div>
  );
};

export default MessagesOnTheRight;
