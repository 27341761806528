import axios from "axios";
import Cookies from 'js-cookie'

//config base axios's API
const api_v1 = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api/v1`
});

//Before each request, Axios will add the token into the config
api_v1.interceptors.request.use(
  (config) => {
    const authToken = Cookies.get('authToken');
    if (authToken) {
      config.headers['X-Authentication-Token'] = authToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const no_auth_api = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api/v1`
}) 

export default api_v1;
