import GalleryLayout from "examples/LayoutContainers/GalleryLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ProjectList from "layouts/gallery/components/BodySection/ProjectList";
import HeaderBox from "layouts/gallery/components/HeaderSection/HeaderBox";
import SearchBox from "layouts/gallery/components/SearchSection/SearchBox";
import TagBox from "layouts/gallery/components/TagSection/TagBox";
import Section from "layouts/gallery/components/common/Section";

const SearchSection = Section;
const ProjectListSection = Section;
const TagSection = Section;
const HeaderSection = Section;

export default function Gallery() {

  return (
    <>
      <GalleryLayout>
        <DashboardNavbar />
        <HeaderSection className="!px-0 lg:!px-0 md:px-0 min-h-[84px]">
          <HeaderBox />
        </HeaderSection>

        <SearchSection>
          <SearchBox />
        </SearchSection>

        <TagSection >
          <TagBox />
        </TagSection>

        <ProjectListSection className="px-3 md:px-4 lg:px-6 min-h-[1000px]">
          <ProjectList />
        </ProjectListSection>
      </GalleryLayout>
    </>
  );
}