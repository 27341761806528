import { Select, Space, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updatePoweredForm, updatePromptDetail } from "store/promptSlice";
import "./css/antdSelect.css";
import { getPromptDetail } from "store/promptSlice";
import { useEffect, useState } from "react";
import { fetchTags } from "store/tagSlice";

const { Option } = Select;

export default function CheckboxGroupOptionWithInput({ }) {
  const dispatch = useDispatch();
  const promptDetail = useSelector(getPromptDetail);
  const tagOptions = useSelector((state) => ArrObjectToArrString(state.tag.tags))
  const projectTags = ArrObjectToArrString(promptDetail.tags)
  let enterKeyPressed = false

  useEffect(() => {
    dispatch(fetchTags());
  }, []);

  function ArrObjectToArrString(tags) {
    const FIELD_NAME = "name"
    if (Array.isArray(tags) && tags.every(tag => typeof tag === 'string')) return tags
    else return tags?.map(tag => tag[FIELD_NAME])
  }

  const getAntdTagFormat = (tags) =>
    tags?.map((tag) => ({
      label: tag,
      value: tag,
    }));

  const commaToArrayFormat = (inputValue) => {
    const trimmedValue = inputValue.trim();
    if (trimmedValue) {
      return trimmedValue.split(",").map((tag) => tag.trim());
    }
    return [];
  };


  const handleToggleTag = async (toggledTagList) => {
    if (!enterKeyPressed) { // prevent onSelect to run when user press enter
      const updatedTags = toggledTagList 
      await handleDispatch({ tags: updatedTags });
    } else {
      enterKeyPressed = false; 
    }
  }

  const handleUserPressEnter = async (event) => {
    if (event.key === "Enter") {
      enterKeyPressed = true;
      const userInputTags = commaToArrayFormat(event.target.value);
      const updatedTags = [...projectTags, ...userInputTags];
  
      await handleDispatch({ tags: updatedTags }).finally(() => {
        event.target.value = ""; 
      });
    }
  };

  const handleDispatch = async ({ tags }) => {
    dispatch(
      updatePoweredForm({
        prompt_id: promptDetail.id,
        payload: { ...promptDetail, tags: tags },
      })
    )
      .then((response) => {
        if (response.error) {
          message.error(response.error.message);
        }
        if (response.payload) {
          dispatch(updatePromptDetail({ tags: tags })); // update prompt.tags state in local
          message.success(response.payload.message);
        }
      })
      .catch((err) => message.error(err))
      
  }

  return (
    <Select
      size="large"
      mode="tags"
      placeholder="Choose Tags"
      options={getAntdTagFormat(tagOptions)}
      defaultValue={getAntdTagFormat(projectTags)}
      className="mt-4 xl:w-[85%] w-[75%] mr-auto border-none"
      onChange={handleToggleTag}
      onInputKeyDown={handleUserPressEnter}
    >
      {!!promptDetail &&
        projectTags.map((tag, index) => (
          <Option key={index} value={tag}>
            <Space className="mx-2">{tag}</Space>
          </Option>
        ))}
    </Select>
  );
}
