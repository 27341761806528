import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api_v1 from 'plugins/axios';

// Define initial state
const initialState = {
  data: [],
  loading: false,
  error: null,
  currentTab: 0,
  detail: null,
  listUrl: [],
  paginator: {},
  page: 2,
  searchQuery: '',
};

// Create async thunk for fetching data
export const fetchData = createAsyncThunk('project_store/fetchData', async (payload) => {
  try {
    const response = await api_v1.get('/projects', { params: payload });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

// Create async thunk for fetching data
export const fetchMoreData = createAsyncThunk('project_store/fetchMoreData', async (payload) => {
  try {
    const response = await api_v1.get('/projects', { params: payload });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

// Create async thunk for fetching detail data
export const fetchDetailData = createAsyncThunk('project_store/fetchDetailData', async (id) => {
  try {
    const response = await api_v1.get('/projects/' + id);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

// Create async thunk for creating new data
export const createData = createAsyncThunk('project_store/createData', async (newData) => {
  try {
    const response = await api_v1.post('/projects', newData);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

// Create async thunk for updating data
export const updateData = createAsyncThunk('project_store/updateData', async (updatedData) => {
  try {
    const response = await api_v1.put(`/projects/${updatedData.id}`, updatedData);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

// Create async thunk for deleting data
export const deleteData = createAsyncThunk('project_store/deleteData', async (id) => {
  try {
    await api_v1.delete(`/projects/${id}`);
    return id;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

// Analyze a url
export const analysisUrl = createAsyncThunk('project_store/analysisUrl', async (url) => {
  try {
    const response = await api_v1.get(`/projects/get_list_urls`, {
      params: {url}
    });
    return response.data
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

// Embedding a url
export const embeddingUrls = createAsyncThunk('project_store/embeddingUrls', async ({id, value}) => {
  try {
    const response = await api_v1.post(`/projects/${id}/embedding_urls`, value)
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

// Upload a file
export const uploadFile = createAsyncThunk('project_store/uploadFile', async ({id, formData}) => {
  try {
    const response = await api_v1.post(`/projects/${id}/embedding_file`, formData);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

// Delete a file
export const deleteFile = createAsyncThunk('project_store/deleteFile', async ({file_embedding_id}) => {
  try {
    const response = await api_v1.delete(`/projects/embedding_file/${file_embedding_id}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

// Embedding by FAQ
export const embeddingFAQ = createAsyncThunk('project_store/embeddingFAQ', async ({projectId, question, answer}) => {
  try {
    const requestBody = { question, answer }
    const response = await api_v1.post(`/projects/${projectId}/embedding_faq`, requestBody);
    return response?.data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
});

// Embedding by input text
export const embeddingInputText = createAsyncThunk('project_store/embeddingInputText', async ({ projectId, inputText }) => {
  try {
    const requestBody = { input_text: inputText }
    const response = await api_v1.post(`/projects/${projectId}/embedding_input_text`, requestBody);
    return response?.data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
});

// Get embedding detail
export const embeddingDetail = createAsyncThunk('project_store/embeddingDetail', async (embeddingId) => {
  try {
    const response = await api_v1.get(`/projects/file_embeddings/${embeddingId}`);
    return response?.data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
});

// Create Redux slice
const projectSlice = createSlice({
  name: 'project_store',
  initialState,
  reducers: {
    setCurrentTab: (state, action) => {
      state.currentTab = action.payload;
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    resetProjectState: state => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.paginator = action.payload.paginator
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchMoreData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMoreData.fulfilled, (state, action) => {
        state.paginator = action.payload.paginator
        state.data = [...state.data, ...action.payload.data];
        state.page = state.page + 1;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchMoreData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchDetailData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDetailData.fulfilled, (state, action) => {
        state.detail = action.payload.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchDetailData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createData.fulfilled, (state, action) => {
        state.data.unshift(action.payload.data);
        state.loading = false;
        state.error = null;
      })
      .addCase(createData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateData.fulfilled, (state, action) => {
        const index = state.data.findIndex(item => item.id === action.payload.data.id);
        if (index !== -1) {
          state.detail = action.payload.data;
          state.data[index] = action.payload.data;
        }
        state.loading = false;
        state.error = null;
      })
      .addCase(updateData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteData.fulfilled, (state, action) => {
        state.data = state.data.filter(item => item.id !== action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteFile.fulfilled, (state, action) => {
        const fileId = action.meta.arg.file_embedding_id; // back-end does not return the id back in the response
        state.detail.file_embeddings = state.detail.file_embeddings.filter(item => item.id !== fileId);
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteFile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(analysisUrl.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(analysisUrl.fulfilled, (state, action) => {
        state.listUrl = action.payload.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(analysisUrl.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(embeddingUrls.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(embeddingUrls.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Embedding FAQ
      .addCase(embeddingFAQ.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(embeddingFAQ.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(embeddingFAQ.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      
      // Embedding input text
      .addCase(embeddingInputText.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(embeddingInputText.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(embeddingInputText.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  }
});
export const getData = (state) => state.project.data
export const getCurrentTab = (state) => state.project.currentTab
export const getDetailData = (state) => state.project.detail
export const getListUrl = (state) => state.project.listUrl
export const { setCurrentTab, resetProjectState, setSearchQuery, setPage } = projectSlice.actions;
export default projectSlice.reducer;
