import AppBar from "@mui/material/AppBar";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
// Images
import burceMars from "assets/images/makergpt/maker_gpt_rounded.png";
// Vision UI Dashboard React base styles
import breakpoints from "assets/theme/base/breakpoints";
import VuiAvatar from "components/VuiAvatar";
// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { useEffect, useState } from "react";
import { Row, Col, Descriptions } from 'antd'
import {timestampFormat} from 'helpers/times'
import {planTypes} from 'variables/plans'
function Header({ user }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.lg
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return (
    <VuiBox position="relative">
      <Card
        sx={{
          px: 3,
          mt: 2,
        }}
      >
        <Row gutter={[25, 25]}>
          <Col xxl={2} xl={2} lg={4} md={4} sm={4} xs={24}>
            <div className="w-full center-an-item">
              <img src={burceMars} className="w-[4.625rem] h-[4.625rem]" />
            </div>
          </Col>
          <Col xxl={22} xl={20} lg={20} md={20} sm={20} xs={24}>
            <VuiBox
              height="100%"
              lineHeight={1}
              display="flex"
              flexDirection="column"
              className="center-an-item min-[576px]:items-start"
            >
              <VuiTypography variant="lg" color="white" fontWeight="bold">
                Current Subscription
              </VuiTypography>
              <Descriptions column={2}>
                <Descriptions.Item label="Plan">{user.user.agent.package.kind_text}</Descriptions.Item>
                { user.user.agent.package.kind !== planTypes.free_trail.value &&
                  <>
                    <Descriptions.Item label="Invoice">
                      <a href={user.user.agent.package.invoice_url} target="_blank" rel="noopener noreferrer">
                        View Detail
                      </a>
                    </Descriptions.Item>
                    <Descriptions.Item label="Current period start">
                      {timestampFormat(user.user.agent.package.start_date)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Current period end">
                      {timestampFormat(user.user.agent.package.end_date)}
                    </Descriptions.Item>
                  </>
                }
              </Descriptions>
            </VuiBox>
          </Col>
        </Row>
      </Card>
    </VuiBox>
  );
}

export default Header;
