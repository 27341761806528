import { useState, useEffect } from "react";
// react-router components
import { useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Vision UI Dashboard React context
import {
  useVisionUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

// Images
import team2 from "assets/images/team-2.jpg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";

//antd
import { Space, Button, Popover, Divider, Spin } from "antd";
import { LoginOutlined } from "@ant-design/icons";
import UserCreditStatus from "examples/Navbars/UserCreditStatus";

import {planTypes} from "variables/plans"
function DashboardNavbar({ absolute, light, isMini, routeTitle }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } =
    controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const user = useSelector((state) => state.user.user);
  const history = useHistory();

  //Profile popover
  const [open, setOpen] = useState(false);
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };
  //List of profile popover
  const Content = () => {
    const classes = "my-1 block";
    return (
      <>
        <Button
          className={classes}
          danger
          icon={<LoginOutlined />}
          type="primary"
          onClick={() => history.push("/log-out")}
        >
          Logout
        </Button>
      </>
    );
  };

  let title = routeTitle || route[route.length - 1];
  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */

    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        image={<img src={team2} alt="person" />}
        title={["New message", "from Laur"]}
        date="13 minutes ago"
        onClick={handleCloseMenu}
      />
      <NotificationItem
        image={<img src={logoSpotify} alt="person" />}
        title={["New album", "by Travis Scott"]}
        date="1 day"
        onClick={handleCloseMenu}
      />
      <NotificationItem
        color="text"
        image={
          <Icon
            fontSize="small"
            sx={{ color: ({ palette: { white } }) => white.main }}
          >
            payment
          </Icon>
        }
        title={["", "Payment successfully completed"]}
        date="2 days"
        onClick={handleCloseMenu}
      />
    </Menu>
  );

  return (
    <AppBar
      className="z-50"
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <VuiBox
          color="inherit"
          mb={{ xs: 1, md: 0 }}
          sx={(theme) => navbarRow(theme, { isMini })}
        >
          <Breadcrumbs icon="home" title={title} route={route} light={light} />
        </VuiBox>
        {isMini ? null : (
          user && (
            <VuiBox sx={(theme) => navbarRow(theme, { isMini })}>
              <VuiBox color={light ? "white" : "inherit"}>
                <Space direction="vertical" align="end" size="0" style={{ top: "0.1em", position: 'absolute', right: "11.3em" }}>
                  <VuiTypography
                    variant="button"
                    className="text-slate-300"
                  >
                    {user.agent.package.kind_text}
                  </VuiTypography>
                  { user.agent.package.kind === planTypes.free_trail.value ?                   
                      <VuiTypography 
                        className="text-white text-[0.6rem]">
                        { user.agent.package.day_remaining } <span style={{ marginLeft: "0.2em" }}>Days Remaining</span>
                      </VuiTypography> 
                    : '' 
                  }
                </Space>
                <Divider className="bg-white ml-[8px] mr-0" type="vertical" style={{ top: "0.4em", height: "3em" }} />
                <Popover
                  content={<Content />}
                  trigger="click"
                  open={open}
                  onOpenChange={handleOpenChange}
                  placement="bottom"
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <Space direction="vertical" align="end" className="gap-0">
                      <IconButton sx={navbarIconButton} size="small" className="center-an-item">
                        <Icon
                          sx={({ palette: { dark, white } }) => ({
                            color: light ? white.main : dark.main,
                          })}
                        >
                          account_circle
                        </Icon>
                        <VuiTypography
                          variant="button"
                          fontWeight="medium"
                          color={light ? "white" : "dark"}
                          className="inline-block"
                        >
                          {user.name}
                        </VuiTypography>
                      </IconButton>
                      <VuiTypography 
                        className="text-white text-[0.6rem] px-[6px]">
                        <UserCreditStatus />
                      </VuiTypography>
                    </Space>
                  </a>
                </Popover>

                <IconButton
                  size="small"
                  color="inherit"
                  sx={navbarMobileMenu}
                  onClick={handleMiniSidenav}
                >
                  <Icon className={"text-white"}>
                    {miniSidenav ? "menu_open" : "menu"}
                  </Icon>
                </IconButton>
                {/* <IconButton
                  size="small"
                  color="inherit"
                  sx={navbarIconButton}
                  onClick={handleConfiguratorOpen}
                >
                  <Icon>settings</Icon>
                </IconButton> */}
                {/* <IconButton
                  size="small"
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenMenu}
                >
                  <Icon className={light ? "text-white" : "text-dark"}>
                    notifications
                  </Icon>
                </IconButton> */}
                {renderMenu()}
              </VuiBox>
            </VuiBox>
          )
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
