import { Table } from "antd";
import { IoPulseOutline, IoRemoveOutline } from 'react-icons/io5'
import "../css/index.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchUsages } from "store/usageSlice";
import { TypeUsageEnum } from "variables/usages";
import { TypeUsageEnumTrans } from "variables/usages";
import { PlusOutlined } from "@ant-design/icons";
import { formatAmountWithEvenCheck } from "helpers/utils";
import { timestampFormat } from "helpers/times";

const USAGE_COLUMN = [
  {
    title: "Date",
    dataIndex: "createdAt",
    key: "createdAt",
    sorter: true,
    render: (createdAt) => <>{timestampFormat(createdAt)}</>,
  },
  {
    title: "Project Name",
    dataIndex: "project",
    key: "projectName",
    render: (project) => <>{project?.name}</>,
  },
  {
    title: "Amount",
    key: "amount",
    dataIndex: "amount",
    sorter: true,
    render: (amount, usageRecord) => {
      const type = usageRecord?.type
      return <Amount key={usageRecord.id} isGreen={type === TypeUsageEnum.PAID} amount={amount} />
    }
  },
  {
    title: "Balance",
    key: "balance",
    dataIndex: "balance",
    sorter: true,
    render: (balance) => <>{Number(balance).toFixed(0)}</>,
  },
  {
    title: "Detail",
    key: "detail",
    dataIndex: "detail",
    render: (detail) => <>{detail === "Make a prompt" ? "Generate response" : detail}</>,
  },
  {
    title: "Type",
    key: "type",
    dataIndex: "type",
    className: "capitalize",
    render: (type) => <>{TypeUsageEnumTrans(type)}</>,
  },
];

const UsageTable = () => {
  const usageState = useSelector(state => state.usage);
  let { data: usages, loading, paginator } = usageState

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchUsages(paginator))
  }, []);

  const handleTableChange = (pagination, filters, sorter) => {
    // Transform from antd format to backend format
    paginator = {
      ...filters,
      current: pagination.current,
      pageSize: pagination.pageSize,
      order_by: sorter?.field,
      order: sorter?.order,
    }

    dispatch(fetchUsages(paginator))
  }

  return (
    <Table
      className="creditTable overflow-x-scroll"
      size="large"
      columns={USAGE_COLUMN}
      dataSource={usages}
      pagination={{
        ...paginator,
        showSizeChanger: true,
      }}
      onChange={handleTableChange}
      loading={loading}
      key="usageTable"
    />
  );
};

function Amount({ isGreen = true, amount }) {
  return amount > 0 ? (
    <span className="text-green-500">
      <PlusOutlined className="inline-block h-3 w-3 mx-[0.1rem]" />
      {formatAmountWithEvenCheck(amount)}
    </span>
  ) : (
    <span className="text-red-500">
      <IoRemoveOutline className="inline-block mx-[0.1rem]" />
      {formatAmountWithEvenCheck(-amount)}
    </span>
  );
}

export default UsageTable;
