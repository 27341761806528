import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api_v1 from "plugins/axios";

// Define initial state
const initialState = {
  tags: [],
  isFetched: false,
  error: null,
  paginator: [],
  selectedTags: []
};

// Create async thunk for fetching data
export const fetchTags = createAsyncThunk("tag_store/fetchTags", async () => {
  try {
    const response = await api_v1.get(`/tags?limit=100&order=1&order_by=name`);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

// Create Redux slice
const tagSlice = createSlice({
  name: "tag_store",
  initialState,
  reducers: {
    setSelectedTags: (state, action) => {
      const tagPayload = action.payload;
      const isTagSelected = state.selectedTags.findIndex(tag => tag.id === tagPayload.id);

      if (isTagSelected !== -1) { // seleceted => remove
        state.selectedTags = state.selectedTags.filter((tag) => tag.id !== tagPayload.id);
      } else { // note seleceted => add
        state.selectedTags.push(tagPayload);
      }
    },
    resetSelectedTags: (state, action) => {
      state.selectedTags = initialState.selectedTags;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTags.pending, (state) => {
        state.isFetched = false;
        state.error = null;
      })
      .addCase(fetchTags.fulfilled, (state, action) => {
        state.tags = action.payload.data;
        state.paginator = action.payload.paginator;
        state.isFetched = true;
        state.error = null;
      })
      .addCase(fetchTags.rejected, (state, action) => {
        state.isFetched = true;
        state.error = action.error.message;
      });
  },
});

export const selectPublicTags = (state) => {
  return {
    tags: state.tag.tags,
    paginator: state.tag.paginator,
    isFetched: state.tag.isFetched,
    selectedTags: state.tag.selectedTags,
  }
}
export const { setSelectedTags, resetSelectedTags } = tagSlice.actions;
export default tagSlice.reducer;
