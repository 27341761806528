import { GlobalOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Transfer, message, Row, Col } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  analysisUrl,
  getListUrl,
  embeddingUrls,
  fetchDetailData,
} from "store/projectSlice";
import qs from "qs"
const UploadUrls = ({ project }) => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [targetKeys, setTargetKeys] = useState([]);
  const [button, setButton] = useState(0);
  const dispatch = useDispatch();
  const listUrl = useSelector(getListUrl);

  useEffect(() => {
    forceUpdate({});
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const formatURLs = (data) => {
    let stringUrl = '\['
    if(Array.isArray(data)){
      data.map((key, index) => {
        if(index === data.length - 1){
          stringUrl = stringUrl + `\"${key}\"\]`
        }else{
          stringUrl = stringUrl + `\"${key}\"\,`
        }
      })
    } else {
      stringUrl = stringUrl + `\"${data}\"\]`
    }
    return qs.stringify({
      'urls': stringUrl
    })
  }
  const handleOk = () => {
    setIsModalOpen(false);
    const value = formatURLs(targetKeys)
    dispatch(embeddingUrls({ id: project.id, value })).then((res) => {
      if(res.payload === undefined){
        message.error(res.error.message)
      } else if(res.payload.statusCode === 200){
        message.success("Embedding URL...")
        dispatch(fetchDetailData(project.id));
      } else{
        message.error("Failed To Embedding URL")
      }
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = (values) => {
    if (button === 1) handleScanSite(values);
    else if (button === 2) handleTrain(values);
    form.resetFields()
    setButton(0);
  };

  const handleScanSite = (values) => {
    dispatch(analysisUrl(values.url)).then((res) => {
      if(res.payload === undefined){
        message.error(res.error.message)
      }else if(res.payload.statusCode === 200){
        setIsModalOpen(true);
      }else{
        message.error("Failed To Embedding URL")
      }
    });
  };

  const handleTrain = (values) => {
    const value = formatURLs(values.url)
    dispatch(embeddingUrls({ id: project.id, value })).then((res) => {
      if(res.payload === undefined){
        message.error(res.error.message)
      }else if(res.payload.statusCode === 200){
        message.success("Embedding URL...")
        dispatch(fetchDetailData(project.id));
      }else{
        message.error("Failed To Embedding URL")
      }
    });
  };

  const handleChange = (newTargetKeys, direction, moveKeys) => {
    setTargetKeys(newTargetKeys);
  };

  const urlDecorator = (urls) => {
    if (!urls || !urls.length) return [];
    return urls.map((url, index) => {
      return {
        key: url,
        url: url,
      };
    });
  };

  const renderItem = (item) => {
    const customLabel = <span className="custom-item">{item.url}</span>;
    return {
      label: customLabel,
      value: item.url,
    };
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        name="horizontal_login"
        onFinish={onFinish}
        className="gap-3"
      >
        <Form.Item
          name="url"
          rules={[
            {
              required: true,
              message: "Please input your URL!",
            },
          ]}
          className="w-full"
        >
          <Input
            prefix={<GlobalOutlined className="site-form-item-icon" />}
            placeholder="www.example.com"
            type="url"
          />
        </Form.Item>
        <Row justify="space-between">
          <Col span="11">
            <Form.Item shouldUpdate>
              {() => (
                <>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={
                      !form.isFieldsTouched(true) ||
                      !!form
                        .getFieldsError()
                        .filter(({ errors }) => errors.length).length
                    }
                    className="w-[100%]"
                    onClick={() => setButton(1)}
                  >
                    &nbsp;Scan site
                  </Button>
                </>
              )}
            </Form.Item>
          </Col>
          <Col span="11">
            <Form.Item shouldUpdate>
              {() => (
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={
                    !form.isFieldsTouched(true) ||
                    !!form
                      .getFieldsError()
                      .filter(({ errors }) => errors.length).length
                  }
                  onClick={() => setButton(2)}
                  className="w-[100%]"
                >
                  Train
                </Button>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Modal
        title="Select URLs"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
      >
        <Transfer
          titles={["Source", "Target"]}
          dataSource={urlDecorator(listUrl)}
          listStyle={{
            width: "100%",
            height: "400px",
            maxWidth: "450px",
          }}
          targetKeys={targetKeys}
          onChange={handleChange}
          render={renderItem}
          showSearch
        />
      </Modal>
    </>
  );
};
export default UploadUrls;
