import { classNames } from "helpers/utils";
import React, { useState } from "react";

const ReadMoreText = ({ className, text, limit = 100 }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const truncatedText = text?.slice(0, limit);
  const shouldTruncate = text?.length > limit;

  return (
      <p className={classNames(className, "w-full")}>
        {shouldTruncate && !isExpanded ? truncatedText.trim() + "..." : text}
        <span className="ml-2">
          {shouldTruncate && (
            <button className="text-blue-500 underline" onClick={toggleExpand}>
              Read {isExpanded ? "less" : "more"}
            </button>
          )}
        </span>
      </p>
  );
};

export default ReadMoreText;
