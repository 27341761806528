import CheckboxGroupOptionWithInput from './CheckboxGroupOptionWithInput';
export default function CategoryOptions({ promptDetail }) {
  let categories = promptDetail.category

  try {
    categories = JSON.parse(categories);
  } catch (error) {
    // categories = JSON.stringify(categories); // Handle unExpected old data format
  }

  return (
    <div>
      <CheckboxGroupOptionWithInput 
        keyword='category'
        defaultValue={categories || []}
        promptDetail={promptDetail}
      />
    </div>
  )
}
