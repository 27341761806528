import { EditOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import UpdateProjectSteps from "layouts/memory/components/Steps/UpdateProjectSteps";
import { useState } from "react";

export default function UpdateProjectModal({ iconText = "Update Project", id, title, description, kind }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <span onClick={showModal} className="cursor-pointer hover:text-gray-400">
        <EditOutlined className="mr-2" />
        {iconText}
      </span>
      <Modal
        title="Update project"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
        footer={null}
      >
        <UpdateProjectSteps handleCancel={handleCancel} id={id} title={title} description={description} kind={kind} />
      </Modal>
    </>
  );
}
