// @mui material components
import Card from "@mui/material/Card";
import { Button, Result } from 'antd';

const StripeSuccess = () => (
  <div className='h-screen flex items-center justify-center flex-col'>
    <Card>
      <Result
        status="success"
        title="Congratulations! Your payment has been successfully processed."
        subTitle="If you have any questions or need further assistance, don't hesitate to reach out to our customer support team."
        extra={[
          <a href="/projects">
            <Button type="primary" key="console">
              View Projects
            </Button>
          </a>,

        ]}
      />

    </Card>
  </div>
);
export default StripeSuccess;