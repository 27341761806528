import { Spin } from "antd";
import { useEffect } from "react";
import Cookies from 'js-cookie'
import { useDispatch } from "react-redux";
import { authenticatorLogOut } from "store/authenticatorSlice";
import { useHistory } from "react-router-dom";
import { userLogOut } from "store/userSlice";
const LogOut = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  useEffect(async () => {
    await dispatch(authenticatorLogOut())
    dispatch(userLogOut())
    Cookies.remove('authToken')
    history.push("/sign-in")
  }, [])

  return (
    <div id="loading" style={{ "zIndex": "1500" }} className="w-full h-full fixed top-0 left-0 bg-white flex items-center justify-center">
      <Spin />
    </div>
  );
};

export default LogOut