

// @mui material components
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import { Button } from 'antd';
// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

function Footer() {
  const colorSettings = useSelector((state) => state.prompt.colorSettings);
  const referralLink = useSelector((state) => state.powerForm.formDetail.referral_link);
  const buttonBackgroundColor = () => {
    if(!colorSettings.background.isGradient) return {backgroundColor: colorSettings.background.background_0}
    else return {backgroundImage: `linear-gradient(159.02deg, ${colorSettings.background.background_1}, ${colorSettings.background.background_2}, ${colorSettings.background.background_3})`}
  }

  return (
    <VuiBox
      component="footer"
      py={2}
    >
      <Grid container justifyContent="center">
        <Grid item={true} xs={12} sx={{ textAlign: "center" }}>
          <VuiTypography
              variant="button"
              sx={{ textAlign: "center", fontWeight: "400 !important" }}
              textColor={colorSettings.text}
            >
              Make your own Generative AI tool using <a href="https://www.makergpt.ai">MakerGPT.ai</a> with no-code, in minutes!
          </VuiTypography>
          <a href={referralLink || "#"}>
            <Button type="primary" size='large' htmlType="submit"  
              style={{...buttonBackgroundColor(), 
                borderColor: colorSettings.text, 
                color: colorSettings.text,
                margin: '0 8px',
              }}
            >
              <span style={{color: colorSettings.text}}>🚀 Get Started</span>
            </Button>
          </a>
          <br/>
          <a href="https://www.makergpt.ai">
            <VuiTypography
              variant="button"
              sx={{ textAlign: "center", fontWeight: "400 !important" }}
              textColor={colorSettings.text}
            >
              @ 2023, Made with ❤️ by{" "}
              MakerGPT
            </VuiTypography>
          </a>
        </Grid>
      </Grid>
    </VuiBox>
  );
}

export default Footer;
