import React from 'react'
import FreeTextToolkit from './FreeTextToolkit';
import DateTimeToolkit from './DateTimeToolkit';
import SingleChoiceToolkit from './SingleChoiceToolkit';
import MultipleChoiceToolkit from './MultipleChoiceToolkit';
import { Empty } from 'antd';
import { useSelector } from "react-redux";

export default function FormToolkit({questions}) {
  const colorSettings = useSelector((state) => state.prompt.colorSettings);
  const renderItem = (question) => {
    switch (question.type) {
      case 'text':
        return <FreeTextToolkit question={question} />
      case 'date_time': 
        return <DateTimeToolkit question={question} />
      case 'single_choice':
        return <SingleChoiceToolkit question={question} />
      case 'multiple_choices':
        return <MultipleChoiceToolkit question={question} />
      default:
        return <></>
    }
  }

  if(!questions || questions.length <= 0) 
    return <Empty description={<span style={{color: colorSettings.text}}>No user inputs yet.</span>} /> 

  return questions.map((question, index) => {
    return (
      <div key={index}>
        {renderItem(question)}
      </div>
    )
  })
}
