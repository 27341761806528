// @mui material components
// @mui icons
import Grid from "@mui/material/Grid";
// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import Footer from "examples/Footer";
// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// Overview page components
import Header from "layouts/profile/components/Header";
// Vision UI Dashboard React example components
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import UsageHeader from "./components/UsageHeader";
import Welcome from "./components/Welcome";
import { Row, Col } from "antd";
import { useSelector } from "react-redux";
const Usage = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar light />
      <Row gutter={[25, 25]} align="space-between">
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <UsageHeader />
          </Col>
      </Row>
    </DashboardLayout>
  );
};

export default Usage;
