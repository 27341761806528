import React, { useState } from "react";
import { Button, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import ProjectSteps from "./ProjectSteps";

export default function CreateProjectModal() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const submitCallback = (value) => {
    setIsModalOpen(false);
  }

  return (
    <>
      <Button type="primary" onClick={showModal} icon={<PlusOutlined />}>
        New Project
      </Button>
      <Modal title="Create a new project" 
        open={isModalOpen} 
        onOk={handleOk} 
        onCancel={handleCancel}
        width={800}
        footer={null}
      >
        <ProjectSteps onCallback={submitCallback}/>
      </Modal>
    </>
  );
}
