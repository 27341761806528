import { EyeFilled } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { useState } from "react";
import { FILE_KINDS } from "variables/fileKinds";

function PreviewModal({ file }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button className="mt-1" type="link" primary="true" icon={<EyeFilled />} onClick={showModal}>
        Preview
      </Button>
      <Modal className="capitalize" title={`${file?.kind_text}`} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        {file?.kind === FILE_KINDS.INPUT_TEXT && <p className="normal-case">{file?.content}</p>}
        {file?.kind === FILE_KINDS.FAQ && (
            <>
                <p id="question" className="normal-case">question: {JSON.parse(file?.content || '{}')?.question}</p>
                <p id="answer" className="normal-case">answer: {JSON.parse(file?.content || '{}')?.answer}</p>
            </>
        )}
      </Modal>
    </>
  );
}

export default PreviewModal;
