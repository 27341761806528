export const TypeUsageEnum = {
  FORM: 0,
  CHAT: 1,
  PAID: 2,
  FORM_STREAM: 3,
  EMBEDDING: 4,
}

export function TypeUsageEnumTrans(type = 0) {
  return (
    {
      0: 'GPT-Powered Form',
      1: 'Chatbot',
      2: 'Paid',
      3: 'GPT-Powered Form',
      4: 'Embedding'
    }[type] || ''
  );
}

