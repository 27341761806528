import AppBar from "@mui/material/AppBar";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
// Images
import burceMars from "assets/images/makergpt/maker_gpt_rounded.png";
// Vision UI Dashboard React base styles
import breakpoints from "assets/theme/base/breakpoints";
import VuiAvatar from "components/VuiAvatar";
// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
// Vision UI Dashboard React icons
import { IoCube } from "react-icons/io5";
import { FaRobot } from "react-icons/fa";
import { IoBuild } from "react-icons/io5";
// Vision UI Dashboard React example components
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentTab, setCurrentTab } from "store/projectSlice";
import { resetMessageState } from "store/messageSlice"
import { Row, Col } from "antd"
import ProjectAvatar from "examples/Cards/ProjectCards/CustomProjectCard/ProjectAvatar";
import UpdateProjectModal from "layouts/memory/components/Actions/UpdateProjectModal";
function Header({ project }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const tabValue = useSelector(getCurrentTab);
  const dispatch = useDispatch();

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.lg
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => {
    dispatch(setCurrentTab(newValue));
    dispatch(resetMessageState())
  }

  return (
    <VuiBox position="relative">
      <Card
        sx={{
          px: 3,
          mt: 2,
        }}
      >
        <Row gutter={[25, 25]}>
          <Col xxl={2} xl={2} lg={4} md={4} sm={4} xs={24} className="flex items-center">
            <div className="w-full center-an-item">
              <ProjectAvatar kind={project?.kind} className="w-[4.625rem] h-[4.625rem]" />
            </div>
          </Col>
          <Col xxl={11} xl={11} lg={10} md={10} sm={10} xs={24}>
            <VuiBox
              height="100%"
              lineHeight={1}
              display="flex"
              flexDirection="column"
              className="center-an-item min-[576px]:items-start"
            >
              <VuiTypography variant="lg" color="white" fontWeight="bold">
                {project?.name}
                <span className="ml-2">
                  <UpdateProjectModal iconText="" id={project.id} title={project.name} description={project.description} kind={project.kind} />
                </span>
              </VuiTypography>
              <VuiTypography variant="button" color="text" fontWeight="regular">
                {project?.description}
              </VuiTypography>
            </VuiBox>
          </Col>
          <Col xxl={11} xl={11} lg={10} md={10} sm={10} xs={24} className="flex items-center">
            <AppBar position="static">
              <Tabs
                orientation={tabsOrientation}
                value={tabValue}
                onChange={handleSetTabValue}
                sx={{
                  background: "transparent",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Tab
                  label="OVERVIEW"
                  icon={<IoCube color="white" size="16px" />}
                  className="min-[1024px]:w-1/3 min-[1024px]:min-w-0"
                />
                <Tab
                  label="APPEARANCE"
                  icon={<FaRobot color="white" size="16px" />}
                  className="min-[1024px]:w-1/3 min-[1024px]:min-w-0"
                />
                <Tab
                  label="PUBLISH"
                  icon={<IoBuild color="white" size="16px" />}
                  className="min-[1024px]:w-1/3 min-[1024px]:min-w-0"
                />
              </Tabs>
            </AppBar>
          </Col>
        </Row>
      </Card>
    </VuiBox>
  );
}

export default Header;
