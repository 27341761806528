

import { useEffect } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";

// Vision UI Dashboard React context
import { useVisionUIController, setLayout } from "context";
import { useSelector } from "react-redux";

function PageLayout({ children, readonly }) {
  const [, dispatch] = useVisionUIController();
  const { pathname } = useLocation();
  const colorSettings = useSelector((state) => state.prompt.colorSettings);

  useEffect(() => {
    if(!readonly) setLayout(dispatch, "page");
  }, [pathname]);

  return (
    <VuiBox
      width="100vw"
      maxWidth="100%"
      height="100%"
      minHeight={ readonly ? "100%" : '100vh'}
      sx={({ functions: { tripleLinearGradient }, palette: { gradients } }) => ({
        overflowX: "hidden",
        ...(colorSettings.background.isGradient ? {
          backgroundImage: tripleLinearGradient(
            colorSettings.background.background_1,
            colorSettings.background.background_2,
            colorSettings.background.background_3,
            gradients.cover.deg
          )} : 
          { background: colorSettings.background.background_0 }
        ),
        position: "relative",
      })}
    >
      {children}
    </VuiBox>
  );
}

// Setting default values for the props for PageLayout
PageLayout.defaultProps = {
  background: "default",
};

// Typechecking props for the PageLayout
PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageLayout;
