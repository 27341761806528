import { UndoOutlined } from "@ant-design/icons";
import { Button, Divider, Input, Radio } from "antd";
import VuiTypography from "components/VuiTypography";
import PromptTextArea from "layouts/prompt/components/FormItems/PromptTextArea";
import dumpData from "layouts/prompt/components/PromptBuilder/dumpData";
import { useDispatch, useSelector } from "react-redux";
import { setResponsePrompt } from "store/promptSlice";
import { updatePromptDetail } from "store/promptSlice";

const { TextArea } = Input;

function PromptFrameTab({ promptDetail }) {
  const isReceivingResponse = useSelector((state) => state.prompt.receiveResponse);
  const dispatch = useDispatch();
  const handleChange = (e) => {
    dispatch(updatePromptDetail({ role: e.target.value }));
  };

  const handleRadioChange = (e) => { 
    dispatch(updatePromptDetail({...dumpData[e.target.value], privacy_setting: promptDetail.privacy_setting, model: promptDetail.model }))
  }

  const handleResetPrompt = () => {
    dispatch(updatePromptDetail({...dumpData.initData, model: promptDetail.model}));
    dispatch(setResponsePrompt(dumpData.initData.responsePrompt));
  };

  return (
    <>
      <VuiTypography color="white" variant="xs">
        Create a template for your hidden prompt. Add user inputs where you would like users to
        enter their own text. Users will see the form but not your hidden prompt.
      </VuiTypography>
      <div className="my-2">
        <VuiTypography color="white" fontWeight="bold" variant="xs" mr="8px">
          Examples:
        </VuiTypography>
        <Radio.Group onChange={handleRadioChange}>
          <Radio.Button value="marketing_campaign">Marketing Campaign</Radio.Button>
          <Radio.Button value="travel_planner">Travel Planner</Radio.Button>
          <Radio.Button value="course_creator">Course Creator</Radio.Button>
        </Radio.Group>
        <Button
          type="dashed"
          icon={<UndoOutlined />}
          className="ml-2"
          disabled={isReceivingResponse}
          onClick={handleResetPrompt}
        />
      </div>
      <Divider className="my-2"></Divider>
      <div className="my-2">
        <VuiTypography color="white" fontWeight="bold" variant="xs" mb="4px">
          Role (Optional)
        </VuiTypography>
        <TextArea
          rows={6}
          key={promptDetail?.id}
          placeholder="Provide a role for the model"
          value={promptDetail.role}
          onChange={handleChange}
        />
      </div>
      <PromptTextArea promptHtml={promptDetail.prompt} questions={promptDetail.questions} />
    </>
  );
}

export default PromptFrameTab;
