import React, { useRef } from 'react'
import { Button, Form, Input, Spin, message } from 'antd';
import { useDispatch, useSelector } from "react-redux";

// Authentication layout components
import WebviewLayout from "layouts/authentication/components/WebviewLayout";
import FormToolkit from './components/FormToolkit';
import Histories from './components/Histories';
import { useEffect } from 'react';
import {fetchPowerFormByToken, postQuestions, addLocalHistory, addTextToResponsePrompt} from "store/powerFormSlice";
import {setColorSettings} from "store/promptSlice";
import { useParams } from "react-router-dom";
import io from "socket.io-client";

export default function GptForm({readonly = false, token = null}) {
  const colorSettings = useSelector((state) => state.prompt.colorSettings);
  const formDetail = useSelector((state) => state.powerForm.formDetail);
  const localHistories = useSelector((state) => state.powerForm.localHistories);
  const profile = useSelector((state) => state.user.user)
  const [isLoading, setIsLoading] = React.useState(true);
  const [isSubmit, setIsSubmit] = React.useState(false);
  const dispatch = useDispatch();
  const { id } = useParams()
  const [form] = Form.useForm();
  const socket = useRef(null);
  useEffect(() => {
    let _token = readonly ? token : id
    setIsLoading(true)
    dispatch(fetchPowerFormByToken({token: _token}))
      .then(({error, payload}) => {
        if (error) {
          message.error(error.message)
        } else {
          dispatch(setColorSettings(payload.data))
        }
        setIsLoading(false)
      })

    // resolve the websocket connection when first loaded
    socket.current = io(process.env.REACT_APP_BASE_URL, {
      path: process.env.REACT_APP_SOCKET_PATH,
      transports: ["websocket"],
    });

    // Receiving messages from the server
    socket.current.on("receive_prompt", (data) => {
      if (data === null) return
      dispatch(addTextToResponsePrompt(data))
    });

    // Receiving error from the server
    socket.current.on("exception", (data) => {
      setIsSubmit(false)
      message.error(data.message)
    });
  
    // Receiving end-messages from the server
    socket.current.on("end_prompt", (data) => {
      setIsSubmit(false)
      if (data === null && data.status !== 200) return
    });

    //Disconnect socket when leaving the page
    return () => socket.current.disconnect();
   }, [])
  
  const onFinish = (values) => {
    setIsSubmit(true)
    let questions = formDetail.questions

    questions = questions.map((question, index) => { 
      let answer = values[question.id]

      if (question.type === 'date_time') {
        if(answer) answer = answer.format('YYYY-MM-DD HH:mm')
      }

      if(question.type === 'multiple_choices') {
        if(answer) answer = answer.join(', ')
      }

      return {
        ...question,
        example: answer
      }
    })

    let manager_token = profile ? { manager_token: profile.token} : {}

    dispatch(postQuestions({token: id, payload: {questions, ...manager_token}}))
      .then(({error, payload}) => {
        if(error) {
          setIsSubmit(false)
          message.error(error.message)
        }
        else {
          dispatch(addLocalHistory(payload.data))
          socket.current.emit("stream_prompt", {
            token: id,
            questions,
            id: payload.data.id,
            manager_token: profile ? profile.token : null
          });
        }
      })
  }

  const buttonBackgroundColor = () => {
    if(!colorSettings.background.isGradient) return {backgroundColor: colorSettings.background.background_0}
    else return {backgroundImage: `linear-gradient(159.02deg, ${colorSettings.background.background_1}, ${colorSettings.background.background_2}, ${colorSettings.background.background_3})`}
  }

  if (isLoading) { return <div className="s-spin-container"><Spin tip="Loading..."/></div>}
  return (
    <WebviewLayout
      title={formDetail.title}
      color="white"
      description={formDetail.description}
      banner={colorSettings.avatarUrl}
      readonly={readonly}
    >
      <Form
        layout="vertical"
        form={form}
        className='mt-4'
        onFinish={onFinish}
        disabled={readonly}
      >
        <FormToolkit questions={formDetail.questions}/>

        <Form.Item>
          <Button type="primary" size='large' htmlType="submit" block loading={isSubmit}
            disabled={readonly || formDetail.questions.length <= 0}
            style={{...buttonBackgroundColor(), borderColor: colorSettings.text, color: colorSettings.text}}
          >
            <span style={{color: colorSettings.text}}>🚀 Generate</span>
          </Button>
        </Form.Item>
      </Form>
      <Histories histories={localHistories}/>
    </WebviewLayout>
  )
}
