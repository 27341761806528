export const projectTypes = {
  chatbot: {
    value: 0,
    label: 'Chatbot'
  },
  gpt_form: {
    value: 1,
    label: 'GPT-Powered Forms'
  },
  image_based_prompt: {
    value: 2,
    label: 'Image-based Prompt'
  }
};

export const MAX_PROJECTS_PLACE_HOLDER = 10