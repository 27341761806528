export const MAX_TAGS_SHOULD_SHOW = 5;

export const MAX_CARD_TAGS = 2;

export const TRENDING_QUERY = {
  order_by: "prompt_runs_count",
  order: -1,
  privacy_setting: 0,
};

export const TOP_QUERY = {
  order_by: "visitors_count",
  order: -1,
  privacy_setting: 0,
};

export const LATEST_QUERY = {
  order_by: "createdAt",
  order: -1,
  privacy_setting: 0,
};

export const EMPTY_QUERY = {
  order_by: null,
  order: null,
  privacy_setting: null,
};
