import React from 'react'
import TextAreaOptionWithInput from './TextAreaOptionWithInput'

export default function WordBiasOptions({promptDetail}) {
  return (
    <div>
      <TextAreaOptionWithInput
        keyword='use_words'
        label="Try to use these words"
        defaultValue={promptDetail.use_words}
      />
      <TextAreaOptionWithInput
        keyword='avoid_words'
        label="Try to avoid these words"
        defaultValue={promptDetail.avoid_words}
      />
    </div>
  )
}
