import { Button, Form, Input, message, Row, Col } from "antd";
import React from "react";
import ColorPickerInput from "./ColorPickerInput";
import { useDispatch } from "react-redux";
import { updateData } from "store/widgetSlice";
import { fetchDetailData } from "store/projectSlice"
import ChangeAvatar from "./ChangeAvatar";
const layout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 20,
  },
};
const tailLayout = {
  wrapperCol: {
    span: 24,
  },
};
const InformationForm = ({ project }) => {
  const formRef = React.useRef(null);
  const dispatch = useDispatch();
  const onFinish = async (values) => {
    if(values.logo === project.widget.logo){
      delete values.logo
    }
    const formData = new FormData()
    Object.keys(values).forEach((key) => formData.append(key, values[key]))
    const response = await dispatch(
      updateData({ id: project.widget.id, updatedData: formData })
    );
    if (response.payload === undefined) {
      message.error(response.error.message);
    } else if (response.payload.statusCode === 200) {
      message.success("Successfully updated settings");
      dispatch(fetchDetailData(project.id))
    } else {
      message.error("Failed to update settings");
    }
  };
  const onReset = () => {
    formRef.current?.resetFields();
  };

  return (
    <Form
      {...layout}
      ref={formRef}
      name="control-ref"
      onFinish={onFinish}
      className="my-4"
      initialValues={project.widget}
      layout="vertical"
    >
      <Row gutter={[10, 10]}>
        <Col span={18}>
          <Row>
            <Col span={24}>
              <Form.Item
                name="bot_background_color"
                label="Header colour"
                className="w-full"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <ColorPickerInput
                  defaultColor={project.widget.bot_background_color}
                  formRef={formRef}
                  fileName="bot_background_color"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="customer_background_color"
                label="User color"
                className="w-full"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <ColorPickerInput
                  defaultColor={project.widget.customer_background_color}
                  formRef={formRef}
                  fileName="customer_background_color"
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <Form.Item
            name="logo"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <ChangeAvatar formRef={formRef} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="bot_name"
            label="Header"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="text1"
            label="Text 1"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="text2"
            label="Text 2"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <div className="flex justify-between">
              <Button htmlType="button" onClick={onReset}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </div>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
export default InformationForm;
