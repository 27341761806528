import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api_v1 from "plugins/axios";

// Define initial state
const initialState = {
  message: [],
  page: 1,
  error: null,
  done: true,
  hasNextPage: false
};


export const fetchData = createAsyncThunk(
  "message/fetchData",
  async ({ token, widgetToken, page, limit }) => {
    try {
      const response = await api_v1.get(
        `/messages/conversation?uid=${token}&widget_token=${widgetToken}&page=${page}&limit=${limit}`
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);


export const fetchNextData = createAsyncThunk(
  "message/fetchNextData",
  async ({ token, widgetToken, page, limit }) => {
    try {
      const response = await api_v1.get(
        `/messages/conversation?uid=${token}&widget_token=${widgetToken}&page=${page}&limit=${limit}`
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    addMessage: (state, action) => {
      state.message = [...state.message, action.payload]
    },
    addChatBotMessage: (state, action) => {
      if(state.done === true){
        state.done = false
        state.message = [...state.message, { kind: 1, content: action.payload }]
      }else{
        const message = [...state.message]
        //Remove the last value from the array and assign to "unfinishedMessage" variable
        const unfinishedMessage = message.pop()
        unfinishedMessage.content = unfinishedMessage.content + action.payload
        state.message = [...message, unfinishedMessage]
      }
    },
    doneAddingChatBotMessage: (state) => {
      state.done = true
    },
    resetMessageState: (state) => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.message = action.payload.data.reverse();
        state.page = state.page + 1;
        state.error = null;
        state.hasNextPage = action.payload.paginator.hasNextPage
      })
      .addCase(fetchNextData.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(fetchNextData.fulfilled, (state, action) => {
        state.message = [...action.payload.data.reverse(), ...state.message]
        state.page = state.page + 1;
        state.error = null;
        state.hasNextPage = action.payload.paginator.hasNextPage
      })
  },
});
export const { addMessage, resetMessageState, addChatBotMessage, doneAddingChatBotMessage } = messageSlice.actions;
export default messageSlice.reducer;
