import React from 'react'
import { TbForms } from "react-icons/tb";
import { FaRocketchat } from "react-icons/fa";
import { projectTypes } from "variables/projects";
import { Avatar, Dropdown, Button, Row, Col, Tooltip } from "antd";
export default function ProjectAvatar({kind = 0}) {
  const renderAvatar = () => {
    if (kind === projectTypes.chatbot.value) {
      return (
        <Tooltip title={projectTypes.chatbot.label}>
          <span className="anticon anticon-code">
            <FaRocketchat />
          </span>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title={projectTypes.gpt_form.label}>
          <span className="anticon anticon-code">
            <TbForms />
          </span>
        </Tooltip>
      );
    }
  };


  return (
    <Avatar
    shape="square"
    size={64}
    icon={renderAvatar()}
    className="bg-blue-600 rounded-2xl shrink-0"
  />
  )
}
