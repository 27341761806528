import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api_v1, { no_auth_api } from "plugins/axios";

const initialState = {
 
}
export const authenticatorSignin = createAsyncThunk('authenticator_store/authenticatorSignin', async ({ email, password }) => {
  try{
    const params = { email: email, password: password };
    const response = await no_auth_api.post(`/authentications/sign_in`, params)
    return response.data
  } catch (err){
    return err.response.data
  }
})
export const authenticatorSigninGoogle = createAsyncThunk('authenticator_store/authenticatorSigninGoogle', async ({ code }) => {
  try{
    const params = { code: code };
    const response = await no_auth_api.post(`/authentications/sign_in_google`, params)
    return response.data
  } catch (err){
    return err.response.data
  }
})
export const authenticatorSignup = createAsyncThunk('authenticator_store/authenticatorSignup', async ({ name, email, password }) => {
  try{
    const params = { name: name, email: email, password: password };
    const response = await no_auth_api.post(`/authentications/create`, params)
    return response.data
  } catch (err){
    return err.response.data
  }
})

export const resendVerifyEmail = createAsyncThunk('authenticator_store/resendVerifyEmail', async (email) => {
  try{
    const response = await no_auth_api.post(`/authentications/resend_email`, {email})
    return response.data
  } catch (err){
    return err.response.data
  }
})

export const checkVerifyCode = createAsyncThunk('authenticator_store/checkVerifyCode', async (code) => {
  try{
    const response = await no_auth_api.post(`/authentications/check_verify_code`, {code})
    return response.data
  } catch (err){
    return err.response.data
  }
})

export const recoveryPassword = createAsyncThunk('authenticator_store/recoveryPassword', async (email) => {
  try{
    const response = await no_auth_api.post(`/authentications/forgot_password`, {email})
    return response.data
  } catch (err){
    return err.response.data
  }
})

export const checkRecoveryCode = createAsyncThunk('authenticator_store/checkRecoveryCode', async ({code, password, password_confirmation}) => {
  try{
    const response = await no_auth_api.put(`/authentications/reset_password`, {code, password, password_confirmation})
    return response.data
  } catch (err){
    return err.response.data
  }
})

export const authenticatorLogOut = createAsyncThunk('authenticator_store/authenticatorLogOut', async () => {
  try{
    const response = await api_v1.delete(`/authentications/sign_out`)
    return response.data
  } catch (err) {
    return err.response.data
  }
})
const authenticatorSlice = createSlice({
  name: "authenticator_store",
  initialState,
  reducers: {}
});

export const { } = authenticatorSlice.actions;
export default authenticatorSlice.reducer;