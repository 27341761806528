import './css/index.css'
const TermsAndConditions = () => {
  return (
    <div
    id="termsAndConditions"
      style={{ zIndex: "1500" }}
      className="w-full h-full fixed top-0 left-0 bg-white flex justify-center"
    >
      <div className="w-[80%] h-auto py-5 overflow-y-auto">
        <h1 className="c13" id="h.gjdgxs">
          <span className="c10 c23">Software Service Agreement</span>
        </h1>
        <p className="c4">
          <span className="c5">
            This Customer Agreement (this &ldquo;Agreement&rdquo;) contains the
            terms and conditions that govern your access to and use of the
            Services (as defined below) and is an agreement between{" "}
          </span>
          <span className="c5 c8">MMPS Technologies Pte Ltd</span>
          <span className="c0">
            , a Singapore Private Limited Company (the &ldquo;Provider&rdquo;)
            and you or the entity you represent (&ldquo;you&rdquo; or the
            &ldquo;Customer&rdquo;).
          </span>
        </p>
        <p className="c4">
          <span className="c0">
            This Agreement takes effect when you click an &ldquo;I Accept&rdquo;
            button or check box presented with these terms, or when you use the
            Services (the &ldquo;Effective Date&rdquo;). You represent to us
            that you are lawfully able to enter into contracts (e.g., you are
            not a minor). If you are entering into this Agreement for an entity,
            such as the company you work for, you represent to us that you have
            the legal authority to bind that entity.
          </span>
        </p>
        <p className="c4">
          <span className="c0">Agreement</span>
        </p>
        <ol className="c6 lst-kix_list_1-0 start" start="1">
          <li className="c2 li-bullet-0">
            <span className="c5">Definitions</span>
          </li>
        </ol>
        <ol className="c6 lst-kix_list_1-1 start" start="1">
          <li className="c1 c10 li-bullet-1">
            <span className="c0">In this Agreement,</span>
          </li>
        </ol>
        <p className="c3">
          <span className="c5">&ldquo;</span>
          <span className="c5 c9">Account</span>
          <span className="c0">
            &rdquo; means an account enabling a person to access and use the
            Hosted Services, including both administrator accounts and user
            accounts;
          </span>
        </p>
        <p className="c3">
          <span className="c5">&ldquo;</span>
          <span className="c5 c9">Agreement</span>
          <span className="c0">
            &rdquo; means this agreement including any Schedules and any
            amendments to this Agreement from time to time;
          </span>
        </p>
        <p className="c3">
          <span className="c5">&ldquo;</span>
          <span className="c5 c9">Business Day</span>
          <span className="c0">
            &rdquo; means any weekday other than a bank or public holiday in
            Singapore;
          </span>
        </p>
        <p className="c3">
          <span className="c5">&ldquo;</span>
          <span className="c5 c9">Business Hours</span>
          <span className="c0">
            &rdquo; means the hours of 09:00 to 17:00 on a Business Day;
          </span>
        </p>
        <p className="c3">
          <span className="c5">&ldquo;</span>
          <span className="c5 c9">Charges</span>
          <span className="c0">&rdquo; means the following amounts:</span>
        </p>
        <p className="c3">
          <span className="c0">
            (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the amounts
            specified in the website associated with the relevant Hosted
            Service; and/or
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;such amounts as
            may be agreed in writing by the parties from time to time.
          </span>
        </p>
        <p className="c3">
          <span className="c5">&ldquo;</span>
          <span className="c5 c9">Customer Confidential Information</span>
          <span className="c0">&rdquo; means:</span>
        </p>
        <p className="c3">
          <span className="c0">
            (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;any information
            disclosed in writing by the Customer to the Provider during the Term
            that at the time of disclosure:
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            (i) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;was marked as
            &ldquo;confidential&rdquo;; or
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            (ii)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;should have been
            reasonably understood by the Provider to be confidential; and
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the Customer
            Data;
          </span>
        </p>
        <p className="c3">
          <span className="c5">&ldquo;</span>
          <span className="c5 c9">Customer Data</span>
          <span className="c0">
            &rdquo; means all data, works, and materials: uploaded to or stored
            on the Platform by the Customer; transmitted by the Platform at the
            instigation of the Customer; supplied by the Customer to the
            Provider for uploading to, transmission by, or storage on the
            Platform; or generated by the Platform as a result of the use of the
            Hosted Services by the Customer (but excluding analytics data
            relating to the use of the Platform and server log files);
          </span>
        </p>
        <p className="c3">
          <span className="c5">&ldquo;</span>
          <span className="c5 c9">Customer Personal Data</span>
          <span className="c0">
            &rdquo; means any Personal Data that is processed by the Provider on
            behalf of the Customer in relation to this Agreement;
          </span>
        </p>
        <p className="c3">
          <span className="c5">&ldquo;</span>
          <span className="c5 c9">Data Protection Laws</span>
          <span className="c0">
            &rdquo; means all applicable laws relating to the processing of
            Personal Data;
          </span>
        </p>
        <p className="c3">
          <span className="c5">&ldquo;</span>
          <span className="c5 c9">Effective Date</span>
          <span className="c0">&rdquo; means the date as defined above;</span>
        </p>
        <p className="c3">
          <span className="c5">&ldquo;</span>
          <span className="c5 c9">Force Majeure Event</span>
          <span className="c0">
            &rdquo; means an event, or a series of related events, that is
            outside the reasonable control of the party affected including
            failures of the internet or any public telecommunications network,
            hacker attacks, denial of service attacks, viruses or other
            malicious software attacks or infections, power failures, industrial
            disputes affecting any third party, third party changes to any
            underlying system on which the Hosted Services rely, changes to the
            law, disasters, explosions, fires, floods, riots, terrorist attacks,
            and wars);
          </span>
        </p>
        <p className="c3">
          <span className="c5">&ldquo;</span>
          <span className="c5 c9">Hosted Services</span>
          <span className="c0">&rdquo; means MakerGPT;</span>
        </p>
        <p className="c3">
          <span className="c5">&ldquo;</span>
          <span className="c5 c9">Hosted Services Defect</span>
          <span className="c0">
            &rdquo; means a defect, error, or bug in the Platform having an
            adverse effect on the appearance, operation, functionality, or
            performance of the Hosted Services, but excluding any defect, error,
            or bug caused by or arising as a result of:
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;any act or
            omission of the Customer or any person authorized by the Customer to
            use the Platform or Hosted Services;
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a failure of the
            Customer to perform or observe any of its obligations in this
            Agreement; and/or
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            (c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;an
            incompatibility between the Platform or Hosted Services and any
            other system, network, application, program, hardware, or software;
          </span>
        </p>
        <p className="c3">
          <span className="c5">&ldquo;</span>
          <span className="c5 c9">Intellectual Property Rights</span>
          <span className="c0">
            &rdquo; means all intellectual property rights wherever in the
            world, whether registrable or unregistrable, registered or
            unregistered, including any application or right of application for
            such rights (and these &ldquo;intellectual property rights&rdquo;
            include copyright and related rights, database rights, confidential
            information, trade secrets, know-how, business names, trade names,
            trademarks, service marks, passing off rights, unfair competition
            rights, patents, petty patents, utility models, semi-conductor
            topography rights and rights in designs);
          </span>
        </p>
        <p className="c3">
          <span className="c5">&ldquo;</span>
          <span className="c5 c9">Maintenance Services</span>
          <span className="c0">
            &rdquo; means the general maintenance of the Platform and Hosted
            Services and the application of Updates and Upgrades;
          </span>
        </p>
        <p className="c3">
          <span className="c5">&ldquo;</span>
          <span className="c5 c9">Personal Data</span>
          <span className="c0">
            &rdquo; has the meaning given to it in the Data Protection Laws
            applicable in Singapore;
          </span>
        </p>
        <p className="c3">
          <span className="c5">&ldquo;</span>
          <span className="c5 c9">Platform</span>
          <span className="c0">
            &rdquo; means the platform managed by the Provider and used by the
            Provider to provide the Hosted Services, including the application
            and database software for the Hosted Services, the system and server
            software used to provide the Hosted Services, and the computer
            hardware on which that application, database, system and server
            software is installed;
          </span>
        </p>
        <p className="c3">
          <span className="c5">&ldquo;</span>
          <span className="c5 c9">Schedule</span>
          <span className="c0">
            &rdquo; means any schedule attached to the main body of this
            Agreement;
          </span>
        </p>
        <p className="c3">
          <span className="c5">&ldquo;</span>
          <span className="c5 c9">Services</span>
          <span className="c0">
            &rdquo; means any services that the Provider provides to the
            Customer, or has an obligation to provide to the Customer, under
            this Agreement;
          </span>
        </p>
        <p className="c3">
          <span className="c5">&ldquo;</span>
          <span className="c5 c9">Support Services</span>
          <span className="c0">
            &rdquo; means support in relation to the use of, and the
            identification and resolution of errors in, the Hosted Services, but
            shall not include the provision of training services;
          </span>
        </p>
        <p className="c3">
          <span className="c5">&ldquo;</span>
          <span className="c5 c9">Supported Web Browser</span>
          <span className="c0">
            &rdquo; means the current release from time to time of Microsoft
            Edge, Mozilla Firefox, Google Chrome or Apple Safari, or any other
            web browser that the Provider agrees in writing shall be supported;
          </span>
        </p>
        <p className="c3">
          <span className="c5">&ldquo;</span>
          <span className="c5 c9">Term</span>
          <span className="c0">
            &rdquo; means the term of this Agreement, commencing in accordance
            with Clause 3.1 and ending in accordance with Clause 3.2;
          </span>
        </p>
        <p className="c3">
          <span className="c5">&ldquo;</span>
          <span className="c5 c9">Update</span>
          <span className="c0">
            &rdquo; means a hotfix, patch, or minor version update to any
            Platform software; and
          </span>
        </p>
        <p className="c3">
          <span className="c5">&ldquo;</span>
          <span className="c5 c9">Upgrade</span>
          <span className="c5">
            &rdquo; means a major version upgrade of any Platform software.
          </span>
        </p>
        <ol className="c6 lst-kix_list_1-0" start="2">
          <li className="c2 li-bullet-0">
            <span className="c5">Credit</span>
          </li>
        </ol>
        <ol className="c6 lst-kix_list_1-1 start" start="1">
          <li className="c1 li-bullet-1">
            <span className="c5">
              This document was created using a template from SEQ Legal (
            </span>
            <span className="c22">
              <a
                className="c24"
                href="https://www.google.com/url?q=https://seqlegal.com/&amp;sa=D&amp;source=editors&amp;ust=1683705366931383&amp;usg=AOvVaw3dvAd3KaFDFTjACRp7GIIL"
              >
                https://seqlegal.com
              </a>
            </span>
            <span className="c0">).</span>
          </li>
        </ol>
        <ol className="c6 lst-kix_list_1-0" start="3">
          <li className="c2 li-bullet-0">
            <span className="c0">Term</span>
          </li>
        </ol>
        <ol className="c6 lst-kix_list_1-1 start" start="1">
          <li className="c1 li-bullet-1">
            <span className="c0">
              This Agreement shall come into force upon the Effective Date.
            </span>
          </li>
          <li className="c1 li-bullet-2">
            <span className="c0">
              This Agreement shall continue in force until terminated in
              accordance with Clause 18 or any other provision of this
              Agreement.
            </span>
          </li>
        </ol>
        <ol className="c6 lst-kix_list_1-0" start="4">
          <li className="c2 li-bullet-0">
            <span className="c5">Hosted Services</span>
          </li>
        </ol>
        <ol className="c6 lst-kix_list_1-1 start" start="1">
          <li className="c1 li-bullet-2">
            <span className="c0">
              The Provider hereby grants to the Customer a worldwide,
              non-exclusive license to use the Hosted Services during the Term.
            </span>
          </li>
          <li className="c1 li-bullet-2">
            <span className="c0">
              The license granted by the Provider to the Customer under Clause
              4.1 is subject to the following limitations:
            </span>
          </li>
        </ol>
        <p className="c12">
          <span className="c0">
            (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the Hosted
            Services may only be used by the officers and employees of the
            Customer; and
          </span>
        </p>
        <p className="c12">
          <span className="c0">
            (b) &nbsp; &nbsp;each Account of the Hosted Services must not be
            used at any point in time by more than one user.
          </span>
        </p>
        <ol className="c6 lst-kix_list_1-1" start="3">
          <li className="c1 li-bullet-1">
            <span className="c0">
              Except to the extent expressly permitted in this Agreement or
              required by law on a non-excludable basis, the license granted by
              the Provider to the Customer under Clause 4.1 is subject to the
              following prohibitions:
            </span>
          </li>
        </ol>
        <p className="c12">
          <span className="c0">
            (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the Customer must
            not sub-license its right to access and use the Hosted Services;
          </span>
        </p>
        <p className="c12">
          <span className="c0">
            (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the Customer must
            not permit any unauthorized person to access or use the Hosted
            Services;
          </span>
        </p>
        <p className="c12">
          <span className="c0">
            (c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the Customer must
            not use the Hosted Services to provide services to third parties;
          </span>
        </p>
        <p className="c12">
          <span className="c0">
            (d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the Customer must
            not republish or redistribute any content or material from the
            Hosted Services;
          </span>
        </p>
        <p className="c12">
          <span className="c0">
            (e)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the Customer must
            not make any alteration to the Platform; and
          </span>
        </p>
        <p className="c12">
          <span className="c0">
            (f)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the Customer must
            not conduct or request that any other person conduct any load
            testing or penetration testing on the Platform or Hosted Services
            without the prior written consent of the Provider.
          </span>
        </p>
        <ol className="c6 lst-kix_list_1-1" start="4">
          <li className="c1 li-bullet-1">
            <span className="c0">
              The Customer shall use reasonable endeavors, including reasonable
              security measures relating to administrator Account access
              details, to ensure that no unauthorized person may gain access to
              the Hosted Services using an administrator Account.
            </span>
          </li>
          <li className="c1 li-bullet-1">
            <span className="c0">
              The Provider shall use reasonable endeavors to maintain the
              availability of the Hosted Services to the Customer but does not
              guarantee 100% availability.
            </span>
          </li>
          <li className="c1 li-bullet-1">
            <span className="c0">
              For the avoidance of doubt, downtime caused directly or indirectly
              by any of the following shall not be considered a breach of this
              Agreement:
            </span>
          </li>
        </ol>
        <p className="c12">
          <span className="c0">
            (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a Force Majeure
            Event;
          </span>
        </p>
        <p className="c12">
          <span className="c0">
            (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a fault or
            failure of the internet or any public telecommunications network;
          </span>
        </p>
        <p className="c12">
          <span className="c0">
            (c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a fault or
            failure of the Customer&rsquo;s computer systems or networks;
          </span>
        </p>
        <p className="c12">
          <span className="c0">
            (d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;any breach by the
            Customer of this Agreement; or
          </span>
        </p>
        <p className="c12">
          <span className="c0">
            (e)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;scheduled
            maintenance carried out in accordance with this Agreement.
          </span>
        </p>
        <ol className="c6 lst-kix_list_1-1" start="7">
          <li className="c1 li-bullet-1">
            <span className="c0">
              The Customer must comply with industry-standard acceptable use
              policies and must ensure that all persons using the Hosted
              Services with the authority of the Customer or by means of an
              administrator Account comply with industry-standard acceptable use
              policies.
            </span>
          </li>
          <li className="c1 li-bullet-2">
            <span className="c0">
              The Customer must not use the Hosted Services in any way that
              causes or may cause, damage to the Hosted Services or Platform or
              impairment of the availability or accessibility of the Hosted
              Services.
            </span>
          </li>
          <li className="c1 li-bullet-1">
            <span className="c0">
              The Customer must not use the Hosted Services
            </span>
          </li>
        </ol>
        <p className="c12">
          <span className="c0">
            (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;in any way that
            is unlawful, illegal, fraudulent or harmful; or
          </span>
        </p>
        <p className="c12">
          <span className="c0">
            (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;in connection
            with any unlawful, illegal, fraudulent, or harmful purpose or
            activity.
          </span>
        </p>
        <ol className="c6 lst-kix_list_1-1" start="10">
          <li className="c1 li-bullet-1">
            <span className="c0">
              &nbsp;For the avoidance of doubt, the Customer has no right to
              access the software code (including object code, intermediate
              code, and source code) of the Platform, either during or after the
              Term.
            </span>
          </li>
          <li className="c1 li-bullet-1">
            <span className="c0">
              The Provider may suspend the provision of the Hosted Services if
              any amount due to be paid by the Customer to the Provider under
              this Agreement is overdue by 10 Business Days.
            </span>
          </li>
        </ol>
        <ol className="c6 lst-kix_list_1-0" start="5">
          <li className="c2 li-bullet-0">
            <span className="c0">Maintenance Services</span>
          </li>
        </ol>
        <ol className="c6 lst-kix_list_1-1 start" start="1">
          <li className="c1 li-bullet-1">
            <span className="c0">
              The Provider shall provide the Maintenance Services to the
              Customer during the Term.
            </span>
          </li>
          <li className="c1 li-bullet-1">
            <span className="c0">
              The Provider shall where practicable give to the Customer prior
              written notice of scheduled Maintenance Services that are likely
              to affect the availability of the Hosted Services or are likely to
              have a material negative impact upon the Hosted Services, without
              prejudice to the Provider&rsquo;s other notice obligations under
              this main body of this Agreement.
            </span>
          </li>
          <li className="c1 li-bullet-1">
            <span className="c0">
              The Provider shall give to the Customer prior written notice of
              the application of an Upgrade to the Platform.
            </span>
          </li>
          <li className="c1 li-bullet-1">
            <span className="c0">
              The Provider shall give to the Customer written notice of the
              application of any security Update to the Platform and prior
              written notice of the application of any non-security Update to
              the Platform.
            </span>
          </li>
          <li className="c1 li-bullet-2">
            <span className="c0">
              The Provider shall provide the Maintenance Services in accordance
              with the standards of skill and care reasonably expected from a
              service provider in the Provider&rsquo;s industry.
            </span>
          </li>
          <li className="c1 li-bullet-1">
            <span className="c0">
              The Provider may suspend the provision of the Maintenance Services
              if any amount due to be paid by the Customer to the Provider under
              this Agreement is overdue.
            </span>
          </li>
        </ol>
        <ol className="c6 lst-kix_list_1-0" start="6">
          <li className="c2 li-bullet-0">
            <span className="c5">Support Services</span>
          </li>
        </ol>
        <ol className="c6 lst-kix_list_1-1 start" start="1">
          <li className="c1 li-bullet-1">
            <span className="c0">
              The Provider shall provide Support Services to the Customer during
              the Term.
            </span>
          </li>
          <li className="c1 li-bullet-1">
            <span className="c0">
              The Provider shall make available to the Customer a helpdesk in
              accordance with the provisions of this main body of this
              Agreement.
            </span>
          </li>
          <li className="c1 li-bullet-1">
            <span className="c0">
              The Provider shall provide the Support Services in accordance with
              the standards of skill and care reasonably expected from a service
              provider in the Provider&rsquo;s industry.
            </span>
          </li>
          <li className="c1 li-bullet-1">
            <span className="c0">
              The Customer may use the helpdesk for the purposes of requesting
              and, where applicable, receiving Support Services.
            </span>
          </li>
          <li className="c1 li-bullet-1">
            <span className="c0">
              Where provided for in the subscription plan, the Provider shall
              respond promptly to all requests for Support Services made by the
              Customer through the helpdesk. The Provider maintains the
              following Initial Response Time Targets.
            </span>
          </li>
        </ol>
        <a id="t.4c29ddc89fa924c39a2d7fd4e84995ce44e014b5"></a>
        <a id="t.0"></a>
        <table className="c19 mx-auto my-3 w-full">
          <thead>
            <tr className="c14">
              <td className="c17" colSpan="1" rowSpan="1">
                <p className="c18">
                  <span className="c0">Category</span>
                </p>
              </td>
              <td className="c15" colSpan="1" rowSpan="1">
                <p className="c18">
                  <span className="c0">Description</span>
                </p>
              </td>
              <td className="c20" colSpan="1" rowSpan="1">
                <p className="c18">
                  <span className="c0">Initial Response Time Target</span>
                </p>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr className="c21">
              <td className="c17" colSpan="1" rowSpan="1">
                <p className="c18">
                  <span className="c0">Time-sensitive issue</span>
                </p>
              </td>
              <td className="c15" colSpan="1" rowSpan="1">
                <p className="c18">
                  <span className="c5">
                    Service outage, performance issue, or another outage.{" "}
                  </span>
                  <span className="c5 c8">
                    Example: The admin portal goes offline due to an unknown
                    reason
                  </span>
                </p>
              </td>
              <td className="c20" colSpan="1" rowSpan="1">
                <p className="c18">
                  <span className="c0">2 Business Hours</span>
                </p>
              </td>
            </tr>
            <tr className="c21">
              <td className="c17" colSpan="1" rowSpan="1">
                <p className="c18">
                  <span className="c0">General Support Question</span>
                </p>
              </td>
              <td className="c15" colSpan="1" rowSpan="1">
                <p className="c18">
                  <span className="c5">General queries on system usage.</span>
                  <span className="c5 c8">
                    &nbsp;Example: Customer needs assistance on how to create a
                    new checklist
                  </span>
                </p>
              </td>
              <td className="c20" colSpan="1" rowSpan="1">
                <p className="c18">
                  <span className="c0">1 Business Day</span>
                </p>
              </td>
            </tr>
            <tr className="c21">
              <td className="c17" colSpan="1" rowSpan="1">
                <p className="c18">
                  <span className="c0">Added functionality requests</span>
                </p>
              </td>
              <td className="c15" colSpan="1" rowSpan="1">
                <p className="c18">
                  <span className="c5">Adding system functionality. </span>
                  <span className="c5 c8">
                    Example: Customer requests for a new filter to be added to a
                    table
                  </span>
                </p>
              </td>
              <td className="c20" colSpan="1" rowSpan="1">
                <p className="c18">
                  <span className="c5">3 Business Days. </span>
                  <span className="c5 c8">
                    Note that there may be a need for calls to clarify the
                    functionality and the Provider may not accede to the new
                    request
                  </span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p className="c4 c16">
          <span className="c0"></span>
        </p>
        <ol className="c6 lst-kix_list_1-1" start="6">
          <li className="c1 c10 li-bullet-1">
            <span className="c0">
              The Provider has an annual 99.9% uptime track record and maintains
              this as a target.
            </span>
          </li>
          <li className="c1 c10 li-bullet-1">
            <span className="c0">
              The Provider may suspend the provision of the Support Services if
              any amount due to be paid by the Customer to the Provider under
              this Agreement is overdue.
            </span>
          </li>
        </ol>
        <ol className="c6 lst-kix_list_1-0" start="7">
          <li className="c2 li-bullet-0">
            <span className="c5">Customer Data</span>
          </li>
        </ol>
        <ol className="c6 lst-kix_list_1-1 start" start="1">
          <li className="c1 li-bullet-1">
            <span className="c0">
              The Customer hereby grants to the Provider a non-exclusive license
              to copy, reproduce, store, distribute, publish, export, adapt,
              edit and translate the Customer Data to the extent reasonably
              required for the performance of the Provider&rsquo;s obligations
              and the exercise of the Provider&rsquo;s rights under this
              Agreement. The Customer also grants to the Provider the right to
              sub-license these rights to its hosting, connectivity, and
              telecommunications service providers, subject to any express
              restrictions elsewhere in this Agreement.
            </span>
          </li>
          <li className="c1 li-bullet-1">
            <span className="c0">
              The Customer warrants to the Provider that the Customer Data when
              used by the Provider in accordance with this Agreement will not
              infringe the Intellectual Property Rights or other legal rights of
              any person, and will not breach the provisions of any law,
              statute, or regulation, in any jurisdiction and under any
              applicable law.
            </span>
          </li>
        </ol>
        <ol className="c6 lst-kix_list_1-0" start="8">
          <li className="c2 li-bullet-0">
            <span className="c0">
              No assignment of Intellectual Property Rights
            </span>
          </li>
        </ol>
        <ol className="c6 lst-kix_list_1-1 start" start="1">
          <li className="c1 li-bullet-1">
            <span className="c0">
              Nothing in this Agreement shall operate to assign or transfer any
              Intellectual Property Rights from the Provider to the Customer, or
              from the Customer to the Provider.
            </span>
          </li>
        </ol>
        <ol className="c6 lst-kix_list_1-0" start="9">
          <li className="c2 li-bullet-0">
            <span className="c5">Charges</span>
          </li>
        </ol>
        <ol className="c6 lst-kix_list_1-1 start" start="1">
          <li className="c1 li-bullet-1">
            <span className="c0">
              The Customer shall pay the Charges to the Provider in accordance
              with this Agreement.
            </span>
          </li>
          <li className="c1 li-bullet-1">
            <span className="c0">
              If the Charges are based in whole or part upon the time spent by
              the Provider performing the Services, the Provider must obtain the
              Customer&rsquo;s written consent before performing Services that
              result in any estimate of time-based Charges given to the Customer
              being exceeded or any budget for time-based Charges agreed by the
              parties being exceeded; and unless the Customer agrees otherwise
              in writing, the Customer shall not be liable to pay to the
              Provider any Charges in respect of Services performed in breach of
              this Clause 10.2.
            </span>
          </li>
          <li className="c1 li-bullet-1">
            <span className="c0">
              All amounts stated in or in relation to this Agreement are, unless
              the context requires otherwise, stated inclusive of any applicable
              goods and service taxes.
            </span>
          </li>
          <li className="c1 li-bullet-1">
            <span className="c0">
              The Provider may elect to vary any element of the Charges by
              giving to the Customer not less than 10 Business Days written
              notice of the variation.
            </span>
          </li>
        </ol>
        <ol className="c6 lst-kix_list_1-0" start="10">
          <li className="c2 li-bullet-0">
            <span className="c0">Payment</span>
          </li>
        </ol>
        <ol className="c6 lst-kix_list_1-1 start" start="1">
          <li className="c1 li-bullet-1">
            <span className="c0">
              The Customer may pay through a credit card subscription or may be
              invoiced by the Provider in advance of the period to which the
              payments relate.
            </span>
          </li>
          <li className="c1 li-bullet-2">
            <span className="c0">
              &nbsp;Where an invoice is issued, the Customer must pay the
              Charges to the Provider within the period of 10 Business Days
              following the issue of an invoice in accordance with this Clause
              10.
            </span>
          </li>
          <li className="c1 li-bullet-1">
            <span className="c0">
              The Customer must pay the Charges by using such payment details as
              are notified by the Provider to the Customer in the invoice.
            </span>
          </li>
          <li className="c1 li-bullet-2">
            <span className="c0">
              &nbsp;If the Customer does not pay any amount properly due to the
              Provider under this Agreement, the Provider may charge the
              Customer interest on the overdue amount at the rate of 2% per
              month or the maximum interest rate permitted by law, whichever is
              lower.
            </span>
          </li>
        </ol>
        <ol className="c6 lst-kix_list_1-0" start="11">
          <li className="c2 li-bullet-0">
            <span className="c5">
              Provider&rsquo;s confidentiality obligations
            </span>
          </li>
        </ol>
        <ol className="c6 lst-kix_list_1-1 start" start="1">
          <li className="c1 li-bullet-1">
            <span className="c0">The Provider must:</span>
          </li>
        </ol>
        <p className="c3">
          <span className="c0">
            (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;keep the Customer
            Confidential Information strictly confidential;
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;not disclose the
            Customer Confidential Information to any person without the
            Customer&rsquo;s prior written consent; and
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            (c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;use the same
            degree of care to protect the confidentiality of the Customer
            Confidential Information as the Provider uses to protect the
            Provider&rsquo;s own confidential information of a similar nature,
            being at least a reasonable degree of care.
          </span>
        </p>
        <ol className="c6 lst-kix_list_1-1" start="2">
          <li className="c1 c10 li-bullet-1">
            <span className="c0">
              Notwithstanding Clause 11.1, the Provider may disclose the
              Customer Confidential Information to the Provider&rsquo;s
              officers, employees, professional advisers, insurers, agents, and
              subcontractors who have a need to access the Customer Confidential
              Information for the performance of their work with respect to this
              Agreement.
            </span>
          </li>
          <li className="c1 c10 li-bullet-1">
            <span className="c0">
              This Clause 11 imposes no obligations upon the Provider with
              respect to Customer Confidential Information that:
            </span>
          </li>
        </ol>
        <p className="c3">
          <span className="c0">
            (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;is known to the
            Provider before disclosure under this Agreement and is not subject
            to any other obligation of confidentiality;
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;is or becomes
            publicly known through no act or default of the Provider; or
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            (c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;is obtained by
            the Provider from a third party in circumstances where the Provider
            has no reason to believe that there has been a breach of an
            obligation of confidentiality.
          </span>
        </p>
        <ol className="c6 lst-kix_list_1-1" start="4">
          <li className="c1 c10 li-bullet-1">
            <span className="c0">
              The restrictions in this Clause 11 do not apply to the extent that
              any Customer Confidential Information is required to be disclosed
              by any law or regulation, by any judicial or governmental order or
              request, or pursuant to disclosure requirements relating to the
              listing of the stock of the Provider on any recognized stock
              exchange.
            </span>
          </li>
          <li className="c1 c10 li-bullet-1">
            <span className="c0">
              The provisions of this Clause 11 shall continue in force for a
              period of 1 year following the termination of this Agreement, at
              the end of which period they will cease to have an effect.
            </span>
          </li>
        </ol>
        <ol className="c6 lst-kix_list_1-0" start="12">
          <li className="c2 li-bullet-0">
            <span className="c5">Data protection</span>
          </li>
        </ol>
        <ol className="c6 lst-kix_list_1-1 start" start="1">
          <li className="c1 li-bullet-2">
            <span className="c0">
              &nbsp;Each party shall comply with the Data Protection Laws with
              respect to the processing of the Customer&rsquo;s Personal Data.
            </span>
          </li>
          <li className="c1 li-bullet-1">
            <span className="c0">
              &nbsp;The Customer warrants to the Provider that it has the legal
              right to disclose all Personal Data that it does in fact disclose
              to the Provider under or in connection with this Agreement.
            </span>
          </li>
          <li className="c1 li-bullet-2">
            <span className="c0">
              &nbsp;If any changes or prospective changes to the Data Protection
              Laws result or will result in one or both parties not complying
              with the Data Protection Laws in relation to the processing of
              Personal Data carried out under this Agreement, then the parties
              shall use their best endeavors promptly to agree on such
              variations to this Agreement as may be necessary to remedy such
              non-compliance.
            </span>
          </li>
        </ol>
        <ol className="c6 lst-kix_list_1-0" start="13">
          <li className="c11 li-bullet-1">
            <span className="c5">Warranties</span>
          </li>
        </ol>
        <ol className="c6 lst-kix_list_1-1 start" start="1">
          <li className="c1 li-bullet-1">
            <span className="c0">
              The Provider warrants to the Customer that:
            </span>
          </li>
        </ol>
        <p className="c3">
          <span className="c0">
            (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the Provider has
            the legal right and authority to enter into this Agreement and to
            perform its obligations under this Agreement; and
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the Provider will
            comply with all applicable legal and regulatory requirements
            applying to the exercise of the Provider&rsquo;s rights and the
            fulfillment of the Provider&rsquo;s obligations under this
            Agreement.
          </span>
        </p>
        <ol className="c6 lst-kix_list_1-1" start="2">
          <li className="c1 c10 li-bullet-1">
            <span className="c0">
              If the Provider reasonably determines, or any third party alleges,
              that the use of the Hosted Services by the Customer in accordance
              with this Agreement infringes any person&rsquo;s Intellectual
              Property Rights, the Provider may at its own cost and expense:
            </span>
          </li>
        </ol>
        <p className="c3">
          <span className="c0">
            (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;modify the Hosted
            Services in such a way that they no longer infringe the relevant
            Intellectual Property Rights; or
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;procure for the
            Customer the right to use the Hosted Services in accordance with
            this Agreement.
          </span>
        </p>
        <ol className="c6 lst-kix_list_1-1" start="3">
          <li className="c1 c10 li-bullet-2">
            <span className="c0">
              The Customer warrants to the Provider that it has the legal right
              and authority to enter into this Agreement and to perform its
              obligations under this Agreement.
            </span>
          </li>
          <li className="c1 c10 li-bullet-1">
            <span className="c0">
              All of the parties&rsquo; warranties and representations in
              respect of the subject matter of this Agreement are expressly set
              out in this Agreement. To the maximum extent permitted by
              applicable law, no other warranties or representations concerning
              the subject matter of this Agreement will be implied into this
              Agreement or any related contract.
            </span>
          </li>
        </ol>
        <ol className="c6 lst-kix_list_1-0" start="14">
          <li className="c11 li-bullet-1">
            <span className="c5">Acknowledgments and warranty limitations</span>
          </li>
        </ol>
        <ol className="c6 lst-kix_list_1-1 start" start="1">
          <li className="c1 li-bullet-1">
            <span className="c0">
              The Customer acknowledges that complex software is never wholly
              free from defects, errors, and bugs; and subject to the other
              provisions of this Agreement, the Provider gives no warranty or
              representation that the Hosted Services will be wholly free from
              defects, errors, and bugs.
            </span>
          </li>
          <li className="c1 li-bullet-1">
            <span className="c0">
              The Customer acknowledges that complex software is never entirely
              free from security vulnerabilities; and subject to the other
              provisions of this Agreement, the Provider gives no warranty or
              representation that the Hosted Services will be entirely secure.
            </span>
          </li>
          <li className="c1 li-bullet-1">
            <span className="c0">
              The Customer acknowledges that the Hosted Services are designed to
              be compatible only with that software and those systems as
              specified in this Agreement, and the Provider does not warrant or
              represent that the Hosted Services will be compatible with any
              other software or systems.
            </span>
          </li>
          <li className="c1 li-bullet-1">
            <span className="c0">
              &nbsp;The Customer acknowledges that the Provider will not provide
              any legal, financial, accountancy, or taxation advice under this
              Agreement or in relation to the Hosted Services; and, except to
              the extent expressly provided otherwise in this Agreement, the
              Provider does not warrant or represent that the Hosted Services or
              the use of the Hosted Services by the Customer will not give rise
              to any legal liability on the part of the Customer or any other
              person.
            </span>
          </li>
        </ol>
        <ol className="c6 lst-kix_list_1-0" start="15">
          <li className="c11 li-bullet-1">
            <span className="c5">Limitations and exclusions of liability</span>
          </li>
        </ol>
        <ol className="c6 lst-kix_list_1-1 start" start="1">
          <li className="c1 li-bullet-1">
            <span className="c0">&nbsp;Nothing in this Agreement will:</span>
          </li>
        </ol>
        <p className="c3">
          <span className="c0">
            (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;limit or exclude
            any liability for death or personal injury resulting from
            negligence;
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;limit or exclude
            any liability for fraud or fraudulent misrepresentation;
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            (c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;limit any
            liabilities in any way that is not permitted under applicable law;
            or
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            (d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;exclude any
            liabilities that may not be excluded under applicable law.
          </span>
        </p>
        <ol className="c6 lst-kix_list_1-1" start="2">
          <li className="c1 c10 li-bullet-1">
            <span className="c0">
              The limitations and exclusions of liability set out in this Clause
              16 and elsewhere in this Agreement:
            </span>
          </li>
        </ol>
        <p className="c3">
          <span className="c0">
            (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;are subject to
            Clause 16.1; and
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;govern all
            liabilities arising under this Agreement or relating to the subject
            matter of this Agreement, including liabilities arising in contract,
            in tort (including negligence), and for breach of statutory duty,
            except to the extent expressly provided otherwise in this Agreement.
          </span>
        </p>
        <ol className="c6 lst-kix_list_1-1" start="3">
          <li className="c1 c10 li-bullet-1">
            <span className="c0">
              The Provider shall not be liable to the Customer in respect of any
              losses arising out of a Force Majeure Event.
            </span>
          </li>
          <li className="c1 c10 li-bullet-1">
            <span className="c0">
              The Provider shall not be liable to the Customer in respect of any
              loss of profits or anticipated savings.
            </span>
          </li>
          <li className="c1 c10 li-bullet-1">
            <span className="c0">
              The Provider shall not be liable to the Customer in respect of any
              loss of revenue or income.
            </span>
          </li>
          <li className="c1 c10 li-bullet-1">
            <span className="c0">
              The Provider shall not be liable to the Customer in respect of any
              loss of use or production.
            </span>
          </li>
          <li className="c1 c10 li-bullet-1">
            <span className="c0">
              The Provider shall not be liable to the Customer in respect of any
              loss of business, contracts, or opportunities.
            </span>
          </li>
          <li className="c1 c10 li-bullet-1">
            <span className="c0">
              The Provider shall not be liable to the Customer in respect of any
              loss or corruption of any data, database, or software.
            </span>
          </li>
          <li className="c1 c10 li-bullet-1">
            <span className="c0">
              The Provider shall not be liable to the Customer in respect of any
              special, indirect, or consequential loss or damage.
            </span>
          </li>
          <li className="c1 c10 li-bullet-1">
            <span className="c0">
              The aggregate liability of the Provider to the Customer under this
              Agreement shall not exceed the lower of:
            </span>
          </li>
        </ol>
        <p className="c3">
          <span className="c5">(a) &nbsp; &nbsp;</span>
          <span className="c5 c8">
            SGD 100/- (One Hundred Singapore Dollars)
          </span>
          <span className="c0">; and</span>
        </p>
        <p className="c3">
          <span className="c0">
            (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the total amount
            paid by the Customer to the Provider under this Agreement.
          </span>
        </p>
        <ol className="c6 lst-kix_list_1-0" start="16">
          <li className="c11 li-bullet-1">
            <span className="c5">Force Majeure Event</span>
          </li>
        </ol>
        <ol className="c6 lst-kix_list_1-1 start" start="1">
          <li className="c1 li-bullet-1">
            <span className="c0">
              If a Force Majeure Event gives rise to a failure or delay in
              either party performing any obligation under this Agreement, other
              than any obligation to make a payment, that obligation will be
              suspended for the duration of the Force Majeure Event.
            </span>
          </li>
        </ol>
        <ol className="c6 lst-kix_list_1-0" start="17">
          <li className="c11 li-bullet-1">
            <span className="c5">Termination</span>
          </li>
        </ol>
        <ol className="c6 lst-kix_list_1-1 start" start="1">
          <li className="c1 li-bullet-1">
            <span className="c0">
              The Provider may terminate this Agreement by giving the Customer
              10 Business Days written notice of termination. The Customer may
              terminate this Agreement by giving the Provider 30 Business Days
              written notice of termination or by the termination of the online
              subscription.
            </span>
          </li>
          <li className="c1 c10 li-bullet-1">
            <span className="c0">
              Either party may terminate this Agreement immediately by giving
              written notice of termination to the other party if the other
              party commits a material breach of this Agreement.
            </span>
          </li>
          <li className="c1 c10 li-bullet-1">
            <span className="c0">
              Either party may terminate this Agreement immediately by giving
              written notice of termination to the other party if:
            </span>
          </li>
        </ol>
        <p className="c3">
          <span className="c0">
            (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the other party:
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            (i) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;is dissolved;
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            (ii)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ceases to
            conduct all (or substantially all) of its business;
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            (iii)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;is or becomes
            unable to pay its debts as they fall due;
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            (iv) &nbsp; is or becomes insolvent or is declared insolvent; or
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            (v)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;convenes a
            meeting or makes or proposes to make any arrangement or composition
            with its creditors;
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;an administrator,
            administrative receiver, liquidator, receiver, trustee, manager, or
            similar is appointed over any of the assets of the other party;
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            (c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;an order is made
            for the winding up of the other party, or the other party passes a
            resolution for its winding up; or
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            (d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;if that other
            party is an individual:
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            (i) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;that the other
            party dies;
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            (ii)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;as a result of
            illness or incapacity, that other party becomes incapable of
            managing his or her own affairs; or
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            (iii)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;that another
            party is the subject of a bankruptcy petition or order.
          </span>
        </p>
        <ol className="c6 lst-kix_list_1-0" start="18">
          <li className="c11 li-bullet-1">
            <span className="c5">Effects of termination</span>
          </li>
        </ol>
        <ol className="c6 lst-kix_list_1-1 start" start="1">
          <li className="c1 li-bullet-1">
            <span className="c0">
              Upon the termination of this Agreement, all of the provisions of
              this Agreement shall cease to have an effect, save for the
              provisions of this Agreement intended to survive and continue to
              have an effect.
            </span>
          </li>
          <li className="c1 c10 li-bullet-1">
            <span className="c0">
              Except to the extent that this Agreement expressly provides
              otherwise, the termination of this Agreement shall not affect the
              accrued rights of either party.
            </span>
          </li>
          <li className="c1 c10 li-bullet-1">
            <span className="c0">
              &nbsp;Within 10 Business Days following the termination of this
              Agreement for any reason, the Customer must pay to the Provider
              any Charges in respect of Services provided to the Customer before
              the termination of this Agreement.
            </span>
          </li>
        </ol>
        <ol className="c6 lst-kix_list_1-0" start="19">
          <li className="c11 li-bullet-1">
            <span className="c5">Notices</span>
          </li>
        </ol>
        <ol className="c6 lst-kix_list_1-1 start" start="1">
          <li className="c1 li-bullet-1">
            <span className="c0">
              &nbsp;Any notice from one party to the other party under this
              Agreement must be given by one of the following methods (using the
              relevant contact details set out in Clause 19.2, provided that, if
              the stated time of deemed receipt is not within Business Hours,
              then the time of deemed receipt shall be when Business Hours next
              begin after the stated time.
            </span>
          </li>
          <li className="c1 c10 li-bullet-1">
            <span className="c5">
              The Provider&rsquo;s contact details for notices under Clause 20
              are as follows:{" "}
            </span>
            <span className="c5 c8 c25">admin@makergpt.ai</span>
          </li>
        </ol>
        <ol className="c6 lst-kix_list_1-0" start="20">
          <li className="c11 li-bullet-2">
            <span className="c5">General</span>
          </li>
        </ol>
        <ol className="c6 lst-kix_list_1-1 start" start="1">
          <li className="c1 li-bullet-2">
            <span className="c0">
              No breach of any provision of this Agreement shall be waived
              except with the express written consent of the party not in
              breach.
            </span>
          </li>
          <li className="c1 c10 li-bullet-1">
            <span className="c0">
              If any provision of this Agreement is determined by any court or
              other competent authority to be unlawful and/or unenforceable, the
              other provisions of this Agreement will continue in effect. If any
              unlawful and/or unenforceable provision would be lawful or
              enforceable if part of it were deleted, that part will be deemed
              to be deleted, and the rest of the provision will continue in
              effect (unless that would contradict the clear intention of the
              parties, in which case the entirety of the relevant provision will
              be deemed to be deleted).
            </span>
          </li>
          <li className="c1 c10 li-bullet-1">
            <span className="c0">
              This Agreement may not be varied except by a written document
              signed by or on behalf of each of the parties.
            </span>
          </li>
          <li className="c1 c10 li-bullet-1">
            <span className="c0">
              Neither party may without the prior written consent of the other
              party assign, transfer, charge, license, or otherwise deal in or
              dispose of any contractual rights or obligations under this
              Agreement.
            </span>
          </li>
          <li className="c1 c10 li-bullet-1">
            <span className="c0">
              This Agreement is made for the benefit of the parties and is not
              intended to benefit any third party or be enforceable by any third
              party. The rights of the parties to terminate, rescind, or agree
              to any amendment, waiver, variation, or settlement under or
              relating to this Agreement are not subject to the consent of any
              third party.
            </span>
          </li>
          <li className="c1 c10 li-bullet-1">
            <span className="c0">
              Subject to Clause 15.1, this Agreement shall constitute the entire
              agreement between the parties in relation to the subject matter of
              this Agreement, and shall supersede all previous agreements,
              arrangements, and understandings between the parties in respect of
              that subject matter.
            </span>
          </li>
          <li className="c1 c10 li-bullet-2">
            <span className="c0">
              This Agreement shall be governed by and construed in accordance
              with Singapore Law.
            </span>
          </li>
          <li className="c1 c10 li-bullet-1">
            <span className="c5">
              The courts of Singapore shall have exclusive jurisdiction to
              adjudicate any dispute arising under or in connection with this
              Agreement.
            </span>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default TermsAndConditions;
