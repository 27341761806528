import React from 'react'
import { Space, Form, Input, Radio, ConfigProvider } from 'antd';
import { useSelector } from "react-redux";

export default function SingleChoiceToolkit({question}) {
  const colorSettings = useSelector((state) => state.prompt.colorSettings);

  const renderOptions = () => {
    return JSON.parse(question.secondary).map((option, index) => {
      return (
        <Radio value={option.label} key={option.value}>
          <span style={{color: colorSettings.text}}>{option.label}</span>
        </Radio>
      )
    })
  }

  return (
    <Form.Item name={question.id}
      label={
        <span style={{color: colorSettings.text}}>
          {question.main}
        </span>
      } 
    >
      <Radio.Group>
        <ConfigProvider
          theme={{
            token: {
              "colorPrimary": colorSettings.text,
              "colorBgContainer": "transparent",
            },
          }}
        >
          <Space direction="vertical">
            {renderOptions()}
          </Space>
        </ConfigProvider>
      </Radio.Group>
    </Form.Item>
  )
}
