import { Skeleton } from "antd";
import useOnUpdateEffect from "hooks/useOnUpdateEffect";
import TagWithIcon from "layouts/gallery/components/common/TagWithIcon";
import { useDispatch } from "react-redux";
import { resetGalleryPaginator, setQueryParams } from "store/gallerySlice";
import { setSelectedTags } from "store/tagSlice";
import { MAX_TAGS_PLACE_HOLDER } from "variables/tags";

function TagList({ tags, isFetched, selectedTags, Icon, variant, children }) {
  const dispatch = useDispatch();

  const handleToggleTag = (tag) => {
    dispatch(setSelectedTags(tag));
  };

  const mapTagId = (tags) => tags.map(tag => tag.id)

  useOnUpdateEffect(() => {
    const strIds = selectedTags?.length > 0 ? '[' + mapTagId(selectedTags).join(', ') + ']' : "";
    dispatch(setQueryParams({ tagIds: strIds }));
    dispatch(resetGalleryPaginator())
  }, [selectedTags]);

  return (
    <ul className="flex flex-row flex-wrap gap-3 mb-4">
      {tags?.length > 0 && tags.map((tag) => (
        <TagWithIcon
            Icon={Icon}
            key={tag.id}
            onClick={() => handleToggleTag(tag)}
            text={tag.name}
            isSelected={selectedTags.findIndex(selectedTag => selectedTag.id === tag.id) !== -1}
            variant={variant}
          >
            {tag.name}
          </TagWithIcon>
      ))}

      {!isFetched && Array(MAX_TAGS_PLACE_HOLDER).fill(0).map((_, index) => (
        <Skeleton className="flex w-16" key={index} avatar={{shape: "square", className: "rounded-md", style: {width: "70px", height: "35px"}}} active loading={true} paragraph={false} title={false}></Skeleton>
      ))}
      {children}
    </ul>
  )
}

export default TagList;
