

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import logo from 'assets/images/makergpt/maker_gpt_rounded.png'
function MakerGPTLogo({ size, className }) {
  return (
    <img className={className} src={logo} width={size} height={size}></img>
  );
}
// Setting default values for the props of MakerGPTLogo
MakerGPTLogo.defaultProps = {
  color: "dark",
  size: "16px",
};

// Typechecking props for the MakerGPTLogo
MakerGPTLogo.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
    "white",
  ]),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default MakerGPTLogo;
