

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard React example components
import DefaultNavbar from "./WebviewNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout/GptPageLayout";

// Authentication layout components
import Footer from "layouts/authentication/components/Footer/GptFormFooter";
import { useSelector } from "react-redux";

function WebviewLayout({ title, description, image, children, banner, readonly }) {
  const colorSettings = useSelector((state) => state.prompt.colorSettings);
  const powerForm = useSelector((state) => state.powerForm.formDetail)

  const renderHeader = () => {
    if(readonly) return;
    if(!powerForm.is_no_branding) return ( <DefaultNavbar transparent light /> )
  }

  const renderFooter = () => {
    if(readonly) return;
    if(!powerForm.is_no_branding) return ( <Footer /> )
  }

  return (
    <PageLayout readonly={readonly} style={{color: '#fff'}}>
      { renderHeader() }
      <VuiBox
        width="calc(100% - 2rem)"
        minHeight="50vh"
        borderRadius="lg"
        mx={2}
        my={11}
        pt={6}
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            image &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Grid container spacing={3} justifyContent="center" sx={{ textAlign: "center" }} className="p-0" style={{maxWidth: '912px', margin: '0 auto'}}>
          { banner && banner.length > 0 && 
            <Grid>
              <img src={banner} className="rounded-xl web-logo-image"/>
            </Grid>
          }
          <Grid item>
            <VuiBox my={2}>
              <VuiTypography variant="h1" textColor={colorSettings.text} fontWeight="bold">
                {title}
              </VuiTypography>
            </VuiBox>
            <VuiBox mb={2}>
              <VuiTypography variant="body2" textColor={colorSettings.text} fontWeight="regular">
                {description}
              </VuiTypography>
            </VuiBox>
          </Grid>
        </Grid>
        <div style={{maxWidth: '912px', margin: '0 auto'}}>
          {children}
        </div>
      </VuiBox>
      { renderFooter() }
    </PageLayout>
  );
}

// Setting default values for the props of WebviewLayout
WebviewLayout.defaultProps = {
  title: "",
  description: "",
};

// Typechecking props for the WebviewLayout
WebviewLayout.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default WebviewLayout;
