import VuiBox from "components/VuiBox";
import Footer from "examples/Footer";
// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// Overview page components
import Header from "layouts/profile/components/Header";
import Welcome from "../profile/components/Welcome/index";
import EditInformations from "./components/EditInformations";
// Vision UI Dashboard React example components
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { message, Row, Col } from "antd";
import { useSelector } from "react-redux";
function Overview() {
  const user = useSelector((state) => state.user);
  return (
    <DashboardLayout>
      <DashboardNavbar light />
      <Header user={user} />
      <VuiBox mt={5} mb={3}>
        <Row gutter={[25, 25]} align="space-between">
          <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
            <Welcome user={user} />
          </Col>
          <Col xxl={16} xl={16} lg={16} md={24} sm={24} xs={24}>
            <EditInformations message={message} />
          </Col>
        </Row>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
