import { Select, Space, Tooltip, message } from 'antd';
import Gpt from 'examples/Icons/Gpt';
import { useSelector, useDispatch } from 'react-redux';
import { updateWidget } from 'store/widgetSlice';
import { updateData } from "store/projectSlice";
export default function ModelSelect () {
    const widget = useSelector((state) => state.widget.widget);
    const plan = useSelector(state => state.user.user.agent.package.kind)
    const projectDetail = useSelector((state) => state.project.detail);
    const dispatch = useDispatch()
    const handleModelGptChange = async (value) => {
        dispatch(updateWidget({ model: value }));
        await dispatch(
            updateData({
              id: projectDetail.id,    
              kind: projectDetail.kind,
              name: projectDetail.name,
              description: projectDetail.description,
              model: value,
            })
          )
            .then((response) => {
              if (response.error) {
                message.error(response.error.message);
              }
              else if (response.payload.statusCode === 200) {
                message.success("Successfully updated settings");
              }
            })
            .catch((err) => message.error(err))
    }
    
    return (
        <Select
          style={{
            width: 200,
            marginTop: 16
          }}
          placeholder="Take it from here..."
          value={widget.model || 'gpt-3.5-turbo'}
          optionLabelProp="label"
          onChange={handleModelGptChange}
        >
          <Select.Option value="gpt-3.5-turbo" label="GPT-3.5-turbo">
            <Tooltip placement="leftBottom" title="ChatGPT 3.5 Turbo (Cloud, Fastest)">
              <Space>
                <span role="img" aria-label="ChatGPT">
                  <Gpt />
                </span>
                GPT-3.5-turbo
              </Space>
            </Tooltip>
          </Select.Option>
          <Select.Option value="makergpt" label="MakerGPT" disabled={plan == 1}>
            <Tooltip placement="leftBottom" title="MakerGPT (Local, data not shared externally)">
              <Space>
                <span role="img" aria-label="ChatGPT">
                  <Gpt />
                </span>
                MakerGPT
              </Space>
            </Tooltip>
          </Select.Option>
          <Select.Option value="gpt-4" label="GPT-4" disabled>
            <Space>
              <span role="img" aria-label="ChatGPT">
                <Gpt />
              </span>
              GPT-4
            </Space>
          </Select.Option>
        </Select>
    )
}