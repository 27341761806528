import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { mapPaginationFormat } from "helpers/utils";
import api_v1 from "plugins/axios";

const initialState = {
  projects: [],
  isDataFetched: false,
  error: null,
  paginator: {},
  queryParams: {},
  page: 2,
};

export const fetchGalleryProjects = createAsyncThunk("gallery_store/fetchGalleryProjects", async (payload) => {
  const params = mapPaginationFormat({ paginator: payload });
  try {
    const response = await api_v1.get(`/public/projects`, { params: params }); // should rename url to projects
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

export const fetchNextGalleryProjects = createAsyncThunk("gallery_store/fetchNextGalleryProjects", async (payload) => {
  const params = mapPaginationFormat({ paginator: payload });
  try {
    const response = await api_v1.get(`/public/projects`, { params: params }); // should rename url to projects
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

// Create Redux slice
const gallerySlice = createSlice({
  name: "gallery_store",
  initialState,
  reducers: {
    setQueryParams: (state, action) => {
      state.queryParams = {
        ...state.queryParams,
        ...action.payload
      }
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    resetGalleryPaginator: (state, action) => {
      state.paginator = initialState.paginator;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGalleryProjects.pending, (state) => {
        state.isDataFetched = false;
        state.error = null;
      })
      .addCase(fetchGalleryProjects.fulfilled, (state, action) => {
        state.projects = action.payload.data;
        state.paginator = action.payload.paginator;
        state.isDataFetched = true;
        state.error = null;
      })
      .addCase(fetchGalleryProjects.rejected, (state, action) => {
        state.isDataFetched = true;
        state.error = action.error.message;
      })
      
      .addCase(fetchNextGalleryProjects.pending, (state) => {
        state.isDataFetched = false;
        state.error = null;
      })
      .addCase(fetchNextGalleryProjects.fulfilled, (state, action) => {
        const newData = action.payload.data
        state.projects = [...state.projects, ...newData]
        state.paginator = action.payload.paginator;
        state.page = state.page + 1
        state.isDataFetched = true;
        state.error = null;
      })
      .addCase(fetchNextGalleryProjects.rejected, (state, action) => {
        state.isDataFetched = true;
        state.error = action.error.message;
      });
  },
});

export const selectGalleryProjects = (state) => {
  return {
    projects: state.gallery.projects,
    paginator: state.gallery.paginator,
    isDataFetched: state.gallery.isDataFetched,
    queryParams: state.gallery.queryParams,
    page: state.gallery.page,
  }
}

export const { setQueryParams, setPage, resetGalleryPaginator } = gallerySlice.actions;
export default gallerySlice.reducer;
