import { Card, Stack } from "@mui/material";
import VuiTypography from "components/VuiTypography";
import Grid from "@mui/material/Grid";
import VuiBox from "components/VuiBox";
import UsageTable from "../UsageTable";

const UsageHeader = () => {
  return (
    <Card style={{ height: "100%" }}>
      <VuiBox display="flex" flexDirection="column">
        <VuiTypography variant="lg" fontWeight="bold" mb="24px" color="white">
          MakerGPT Credit Usage
        </VuiTypography>
        <Grid item xs={12} xl={12} xxl={12} className="min-h-[17rem]">
          <UsageTable />
        </Grid>
      </VuiBox>
    </Card>
  );
};

export default UsageHeader;
