import { Link } from "react-router-dom/cjs/react-router-dom.min";

function CustomLink({ children, link }) {
  if (link) {
    return <Link to={link}>{children}</Link>;
  }
  return children;
}

export default CustomLink;
