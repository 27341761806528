import React, { useState } from 'react';
import { Input , Form, Modal, message } from 'antd';
import { recoveryPassword, checkRecoveryCode } from 'store/authenticatorSlice';
import { useDispatch } from 'react-redux';
const ForgotPassword = () => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setConfirmLoading(true);
    form.submit();
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const onFinish = (values) => {
    dispatch(recoveryPassword(values.email))
      .then((res) => {
        setConfirmLoading(false);
        if(res.payload.statusCode === 200) {
          form.resetFields();
          setOpen(false);
          message.info(res.payload.message);
        } else {
          message.info(res.payload.message);
        }
      })

  }

  return (
    <>
      <a onClick={showModal}>
        Forgot Password
      </a>
      <Modal
        title="Password Recovery"
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okText="Send Email"
      >
        <Form onFinish={onFinish} form={form} layout="vertical" 
          onFinishFailed={() => setConfirmLoading(false)}>
          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please enter your email!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default ForgotPassword;