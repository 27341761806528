import Card from "@mui/material/Card";
import React, { Fragment } from "react";
import {
  CodeFilled,
  EllipsisOutlined,
  ShareAltOutlined,
  SnippetsOutlined,
  DeleteOutlined,
  StarOutlined,
  MessageOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Avatar, Dropdown, Button, Row, Col, Tooltip, Tag, Badge, Popover, Skeleton } from "antd";
import Divider from "@mui/material/Divider";
import VuiButton from "components/VuiButton";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { TbForms } from "react-icons/tb";
import { FaRocketchat } from "react-icons/fa";
import DeleteProject from "layouts/memory/components/Actions/DeleteProject";
import ProjectAvatar from "./ProjectAvatar";
import ReadMoreText from "examples/Buttons/ReadMoreText";
import { MAX_CARD_TAGS, MAX_TAGS_SHOULD_SHOW } from "variables/public";
import CustomLink from "examples/Link/CustomLink";
import UpdateProjectModal from "layouts/memory/components/Actions/UpdateProjectModal";
import TagWithIcon from "layouts/gallery/components/common/TagWithIcon";



export default function CustomProjectCard({ className, id, label, title, description, action, extra_action, authors, created_at, kind, kind_text, visitorsCount, promptRunsCount, shouldShowMenuAction = true, madeBy, tags, link, isLoading }) {
  const DEFAULT_MENU_ACTIONS = [
    // {
    //   key: 'favorite',
    //   label: (
    //     <span><StarOutlined className="mr-2"/>Favorite</span>
    //   ),
    // },
    // {
    //   key: 'share',
    //   label: (
    //     <span><ShareAltOutlined className="mr-2"/>Share Project</span>
    //   ),
    // },
    // {
    //   key: "clone",
    //   label: (
    //     <span>
    //       <SnippetsOutlined className="mr-2" />
    //       Clone Project
    //     </span>
    //   ),
    // },
    {
      key: "update",
      label: (
        <UpdateProjectModal id={id} title={title} description={description} kind={kind} />
      ),
    },
    {
      key: "delete",
      label: (
        <DeleteProject id={id}/>
      ),
    },
  ];
  
  const visibleTags = tags?.length > 0 ? tags.slice(0, MAX_CARD_TAGS + 1) : []
  const hiddenTags = tags?.length > 0 ? tags.slice(MAX_CARD_TAGS) : []
  
  const renderHiddenTags = () => (
    hiddenTags.map(hiddenTag => (
      <TagWithIcon className="my-2 mx-2" key={hiddenTag.id} variant="darker">{hiddenTag.name}</TagWithIcon>
    ))
  )

  const renderBottomTagSection = () => (
    <div className="space-x-2 flex">
      {visibleTags.length > 0 && visibleTags.map((tag) => (
        <TagWithIcon variant="darker" size="sm" key={tag.id} color="blue">{tag.name}</TagWithIcon>
      ))}
      {hiddenTags.length > 0 && (
        <Popover overlayClassName="max-w-md" content={renderHiddenTags} trigger="hover" >
          <TagWithIcon variant="expand" size="sm" className="cursor-pointer">{hiddenTags.length}+</TagWithIcon>
        </Popover>
      )}
    </div>
  )

  return (
    <Card className={className}>
      <CustomLink link={link}>
        <div className="text-white flex justify-between">
          <div className="flex w-full gap-2 items-center">
            <Skeleton
              className="flex-1"
              avatar={{ shape: "square" }}
              paragraph={false}
              title={false}
              loading={isLoading}
              active
            >
              <div className="flex-shrink-0">
                <ProjectAvatar kind={kind} />
              </div>
            </Skeleton>
            <Skeleton title={{ width: "100%" }} loading={isLoading} paragraph={{rows: 2}} active>
              <Tooltip title={title}><h2 className="text-base xl:text-sm font-bold px-4 line-clamp-3">{title}</h2></Tooltip>
            </Skeleton>
          </div>
          {!isLoading && shouldShowMenuAction && (
            <Dropdown
              menu={{
                items: DEFAULT_MENU_ACTIONS,
              }}
              placement="left"
              arrow
            >
              <a>
                <EllipsisOutlined className="text-white" rotate={90} />
              </a>
            </Dropdown>
          )}
        </div>
        <div className="text-sm text-gray-400 my-2">
          {!isLoading && <p className="line-clamp-3">{description}</p>}
        </div>
        <Divider light />
        <Row gutter={5} justify="space-between">
          <Col flex="auto">
            <Row justify="space-between">
              <Skeleton paragraph={false} title={{ width: "95%" }} loading={isLoading} active>
                <Col span={madeBy === undefined ? 10 : 8}>
                  <div className="text-xs flex flex-nowrap flex-col">
                    <span className=" text-gray-400">Visitors:</span>
                    <span className=" text-white font-bold truncate ...">{visitorsCount}</span>
                  </div>
                </Col>
                <Col span={madeBy === undefined ? 14 : 8}>
                  <div className="text-xs flex flex-nowrap flex-col">
                    <span className=" text-gray-400">Prompt Runs:</span>
                    <span className=" text-white font-bold truncate ...">{promptRunsCount}</span>
                  </div>
                </Col>
                {madeBy !== undefined && (
                  <Col span={8}>
                    <div className="text-xs flex flex-nowrap flex-col">
                      <span className=" text-gray-400">Made by:</span>
                      <span className=" text-white font-bold truncate ...">{madeBy}</span>
                    </div>
                  </Col>
                )}
              </Skeleton>
            </Row>
          </Col>
          <Col>
            {action && (
              <Link to={action.route}>
                <Button type="primary">{action.label}</Button>
              </Link>
            )}
          </Col>
        </Row>
        <Row className="mt-4">{renderBottomTagSection()}</Row>
      </CustomLink>
    </Card>
  );
}
