import Spinner from "examples/Spinner";
import { classNames } from "helpers/utils";
import useOnUpdateEffect from "hooks/useOnUpdateEffect";
import { memo, useState } from "react";
import { useDispatch } from "react-redux";
import { resetGalleryPaginator, setQueryParams } from "store/gallerySlice";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { fetchTags, selectPublicTags } from "store/tagSlice";
import FilterTagButton from "layouts/gallery/components/TagSection/FilterTagButton";
import FilterTagModal from "layouts/gallery/components/TagSection/FilterTagModal";

function SearchBox() {
  const dispatch = useDispatch()
  const [inputValue, setInputValue] = useState('')
  const [isFetching, setIsFetching] = useState(false)

  useOnUpdateEffect(() => {
    setIsFetching(true)
    const debounceTimer = setTimeout(() => {
      dispatch(setQueryParams({ q: inputValue }));
      setIsFetching(false)
      dispatch(resetGalleryPaginator())
    }, 1000);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [inputValue]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value)
  }

  const { selectedTags } = useSelector(selectPublicTags);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div
      id="searchBlock"
      className="flex top-0 gap-2 mb-8 w-full h-auto lg:mb-4 z-40 max-sm:max-h-11"
    >
      <div className="flex gap-2 lg:py-4 w-full transition-all text-base">
        <div className="group relative flex w-full shrink rounded-[2rem] font-normal">
          <div className={classNames(isFetching ? "opacity-100" : "opacity-50", "transition-all duration-500 focus-within:opacity-100 relative flex w-full rounded-[1.5rem] border-2 bg-darkBlue-500 p-0.5 text-base font-normal text-white outline-slate-200/40 active:outline-2 max-sm:text-sm md:p-1")}>
            <div className="relative ml-4 flex w-full items-center gap-2">
              <input
                autoComplete="off"
                placeholder="Search projects"
                name="search"
                title="search"
                value={inputValue}
                type="text"
                className="w-full focus:outline-none bg-transparent"
                onChange={handleInputChange}
              />
            </div>
            <button className="flex w-auto justify-center items-center p-2 aspect-square text-base font-medium text-white hover:bg-uiBlue-500/50 transition-colors rounded-full focus:outline-none active:ring-2 active:bg-darkBlue-500/20 active:ring-red active:ring-offset-gray-100 shadow-sm hover:bg-blend-darken">
              <svg
                size="22"
                className="inline-block "
                color="inherit"
                width="22"
                height="22"
                viewBox="0 0 24 24"
                fill="currentColor"
                stroke="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Search">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 11.8487 17.3729 13.551 16.3199 14.9056L21.7071 20.2929L20.2929 21.7071L14.9056 16.3199C13.551 17.3729 11.8487 18 10 18ZM16 10C16 13.3137 13.3137 16 10 16C6.68629 16 4 13.3137 4 10C4 6.68629 6.68629 4 10 4C13.3137 4 16 6.68629 16 10Z"
                  ></path>
                </g>
              </svg>
            </button>
          </div>
        </div>
        <div className="aspect-square relative flex items-center">
          <Spinner title="Refresh" isDisabled={isFetching} shouldSpin={isFetching} onClick={() => setInputValue("")} />
        </div>
        <div className="aspect-square relative flex items-center">
          <FilterTagButton key="More tag Btn" onClick={showModal} isFiltered={selectedTags.length > 0} />
          <FilterTagModal onClose={handleCancel} shouldShowModal={isModalOpen} />
        </div>
      </div>
    </div>
  );
}

export default memo(SearchBox)