import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";

// Vision UI Dashboard React Context Provider
import { VisionUIControllerProvider } from "context";

// Redux Provider
import store from './store/store'
import { Provider } from 'react-redux'

ReactDOM.render(
  <BrowserRouter>
    <VisionUIControllerProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </VisionUIControllerProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
