import React, { useState } from "react";
import {
  Input,
  Typography,
  Popover,
} from "antd";
import { ChromePicker } from "react-color";

const { Text } = Typography;

function ColorPickerInput({ defaultColor, formRef, fileName }) {
  const [color, setColor] = useState(defaultColor || "#ffffff");
  const [showPicker, setShowPicker] = useState(false);

  const handleColorChange = (newColor) => {
    setColor(newColor.hex);
    formRef.current?.setFieldsValue({
      [fileName]: newColor.hex,
    });
  };

  const handlePickerClose = () => {
    setShowPicker(false);
  };

  const handlePickerOpen = () => {
    setShowPicker(true);
  };

  const handleInputChange = (event) => {
    setColor(event.target.value);
    formRef.current?.setFieldsValue({
      [fileName]: event.target.value,
    });
  };

  const handleInputBlur = (newColor) => {
    if(newColor.hex === undefined) return
    setColor(newColor.hex);
    formRef.current?.setFieldsValue({
      [fileName]: newColor.hex,
    });
  };

  return (
    <Input
      value={color}
      onChange={handleInputChange}
      onBlur={handleInputBlur}
      addonAfter={
        <Popover
          content={<ChromePicker color={color} onChange={handleColorChange} />}
          placement="left"
          trigger="click"
        >
          <div
            onClick={handlePickerOpen}
            style={{
              backgroundColor: color,
              width: "24px",
              height: "24px",
              borderRadius: "2px",
              cursor: "pointer",
            }}
          />
        </Popover>
      }
    />
  );
}

export default ColorPickerInput;
