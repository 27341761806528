import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Modal, Input, message, Result, Button, Spin, Form, Row, Col } from "antd";

// @mui material components
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import rgba from "assets/theme/functions/rgba";
import Card from "@mui/material/Card";
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";
import { CloseOutlined, CheckOutlined, GoogleOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";

// slice
import { checkRecoveryCode } from "store/authenticatorSlice";
import { userSuccessfullyValidated } from "store/userSlice";

// react-router-dom components
import { Link } from "react-router-dom";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
// Authentication layout components
import CoverLayout from "layouts/authentication/components/WebviewLayout";

// Images
import bgSignIn from "assets/images/signInImage.png";
import { useGoogleLogin } from "@react-oauth/google";
import { useLocation } from "react-router-dom"
function Recovery() {
  const location = useLocation()
  const history = useHistory();
  const params = new URLSearchParams(location.search)
  const code = params.get('code')
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if(!code || !code.length){
      history.push('/sign-in')
    } else {
      
    }
  }, [])

  const onFinish = (values) => {
    setIsLoading(true)
    dispatch(checkRecoveryCode({
      code,
      password: values.password,
      password_confirmation: values.password_confirmation
    })).then((res) => {
      setIsLoading(false)
      if(res.payload.statusCode === 200) {
        message.success("Password changed successfully");
        history.push('/sign-in')
      } else {
        message.error(res.payload.message);
      }
    })
  }

  return (
    <CoverLayout
      title="Password Recovery "
      color="white"
      premotto="INSPIRED BY THE FUTURE:"
      motto="THE MAKERGPT PLATFORM"
    >
      <Card >
        <Form
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            label="New Password"
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="password_confirmation"
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: 'Please input your confirm password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The new password that you entered do not match!'));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block
              loading={isLoading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </CoverLayout>
  );
}

export default Recovery;