import FilterTagButton from "layouts/gallery/components/TagSection/FilterTagButton";
import FilterTagModal from "layouts/gallery/components/TagSection/FilterTagModal";

import TagList from "layouts/gallery/components/TagSection/TagList";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTags, selectPublicTags } from "store/tagSlice";

function TagBox() {
  

  const { selectedTags } = useSelector(selectPublicTags);

  return (
    <>
      <div className="flex space-x-2">
        <TagList tags={selectedTags} selectedTags={selectedTags} isFetched={true}>
        </TagList>
      </div>
    </>
  );
}

export default TagBox;
