import { Button, message, Popconfirm, Modal } from 'antd';
import { ProfileFilled } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { embeddingDetail } from 'store/projectSlice';
import { useState } from 'react';

const TrainedContent = ({file}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileContent, setFileContent] = useState("");
  const dispatch = useDispatch();
  const handleCloseModal = (e) => {
    e.stopPropagation();
    setIsModalOpen(false);
  }
  const handleClickView = () => {
    dispatch(embeddingDetail(file.id))
    .then((res) => {
      if(res.payload.statusCode === 200){
        setFileContent(res.payload.data);
      } else {
        message.error(res.payload.message);
      }
    })
    setIsModalOpen(true)
  }
  return (
    <>
      <Button className='text-[#1677ff] hover:text-[#91caff]' icon={<ProfileFilled />} type="link" primary="true" onClick={() => handleClickView()}>
        Trained Content
      </Button>
      <Modal
        open={isModalOpen}
        onOk={(e) => handleCloseModal(e)}
        onCancel={(e) => handleCloseModal(e)}
        title="Content"
        width={1000}
        zIndex={10000}
      >
        {fileContent}
      </Modal>
    </>


  )
};
export default TrainedContent;