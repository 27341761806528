import React from 'react'
import SliderWithInput from './SliderWithInput'

export default function OutputOptions({promptDetail}) {
  return (
    <div>
      <SliderWithInput 
        label="Maximum output length"
        max={2048}
        min={1}
        keyword="max_length"
        defaultValue={promptDetail.max_length}
      />
    </div>
  )
}
