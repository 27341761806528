// @mui material components
import Card from "@mui/material/Card";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";

import Grid from "@mui/material/Grid";

import TrainModelBox from "../../../../examples/TrainModelBox";
import Widget from "../Widget";
import Role from "./Role";
import ModelSelect from "./ModelSelect";

function Project({project}) {

  return (
    <VuiBox py={3}>
      <VuiBox mb={3}>
        <Grid container spacing={3} >
          <Grid item xs={12} lg={7} height="100%">
            <Card>
              <Widget widget={project.widget}/>
              <ModelSelect />
            </Card>
          </Grid>
          
          <Grid item xs={12} lg={5}>
            <Role />
            <TrainModelBox project={project} title="Train Model" />
          </Grid>
        </Grid>

      </VuiBox>
    </VuiBox>
  );
}

export default Project;
