import { Empty } from "antd";
import CustomProjectCard from "examples/Cards/ProjectCards/CustomProjectCard";
import { getPlaceHolderItemsLength } from "helpers/utils";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import useOnUpdateEffect from "hooks/useOnUpdateEffect";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchGalleryProjects,
  fetchNextGalleryProjects,
  setPage
} from "store/gallerySlice";

function ProjectList() {
  const dispatch = useDispatch();

  const projectState = useSelector((state) => ({
    data: state.gallery.projects,
    paginator: state.gallery.paginator,
    page: state.gallery.page,
    searchQuery: null,
    queryParams: state.gallery.queryParams,
    isDataFetched: state.gallery.isDataFetched
  }));

  const projects = projectState.data

  const { setLoadingNextMessage } = useInfiniteScroll({ paginatorData: projectState, fetchDataAction: fetchGalleryProjects, fetchMoreDataAction: fetchNextGalleryProjects, setPageAction: setPage });

  useOnUpdateEffect(() => {
    dispatch(fetchGalleryProjects(projectState.queryParams));
    // Reset page for infinite scroll
    dispatch(setPage(2)) 
    setLoadingNextMessage(false)
  }, [projectState.queryParams])

  if (projectState.isDataFetched && projects?.length <= 0) {
    return (
      <div className="col-start-1 col-span-4">
          <Empty
            className="text-white"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No Result Found!!"
          />
        </div>
    )
  }

  return (
    <ul className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
      {projects.map((project, index) => (
        <CustomProjectCard
          className="hover:bg-tag-100 transition-all ease-in-out duration-500 animate-fade animate-ease-out animate-duration-[1000ms]"
          key={project.id}
          label={`Project #${index + 1}`}
          title={project.name}
          description={project.description}
          created_at={project.created_at}
          kind={project.kind}
          kind_text={project.kind_text}
          id={project.id}
          visitorsCount={project.visitorsCount}
          promptRunsCount={project.promptRunsCount}
          madeBy={project.manager.name}
          tags={project.tags}
          shouldShowMenuAction={false}
          link={"/gpt_form/" + project.prompt_template.token}
        />
      ))}

      {/* Loading skeleton placeholder when fetching projects*/}
      {!projectState.isDataFetched && (
        Array(getPlaceHolderItemsLength({ paginator: projectState.paginator })).fill(0).map((_, index) => (
          <CustomProjectCard
            className="animate-fade animate-duration-[1000ms] animate-ease-out"
            key={index}
            isLoading={!projectState.isDataFetched}
          />
        ))
      )}
    </ul>
  );
}

export default memo(ProjectList)
