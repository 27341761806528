export default ({hexColor, darkerNumber}) => {
    if (!/^#([0-9A-F]{3}){1,2}$/i.test(hexColor)) {
      throw new Error("Invalid hex color format");
    }
    const color = hexColor.replace("#", "");
    const hexValue = parseInt(color, 16);
  
    const r = (hexValue >> 16) & 255;
    const g = (hexValue >> 8) & 255;
    const b = hexValue & 255;
  
    const darkerR = Math.floor(r * darkerNumber);
    const darkerG = Math.floor(g * darkerNumber);
    const darkerB = Math.floor(b * darkerNumber);
  
    const darkerHex = `#${((darkerR << 16) + (darkerG << 8) + darkerB).toString(16).padStart(6, "0")}`;
  
    return darkerHex;
}