import { MAX_PROJECTS_PLACE_HOLDER } from "variables/projects";

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    if (!file) resolve(null);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const snakeToCamel = (str) => {
  if (!str) return

  if (typeof str !== 'string') {
    throw new Error('Input must be a string');
  }

  return str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
};

// Convert to frontend and backend format
export const mapPaginationFormat = ({ paginator }) => {
  if (!paginator) return;
  const { current, pageSize, order, ...rest } = paginator;
  const page = current || rest.page;
  const limit = pageSize || rest.limit;
  
  return {
    ...rest,
    page,
    limit,
    order: order === undefined || order === 'descend' ? -1 : order,
    order_by: snakeToCamel(paginator.order_by),
    current: page,
    pageSize: limit,
  };
};

export function formatAmountWithEvenCheck(amount) {
  const formattedAmount = Number(amount);
  return (formattedAmount % 2 === 0) ? formattedAmount.toFixed(0) : formattedAmount.toFixed(2);
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const isObjectEmpty = (objectName) => {
  return Object.keys(objectName).length === 0
}

export function getPlaceHolderItemsLength ({ paginator }) {
  if (isObjectEmpty(paginator)) return MAX_PROJECTS_PLACE_HOLDER

  const { currentPage, limit: limitPerPage, total: totalItems } = paginator

  // Calculate the total number of projects already loaded
  const loadedItems = currentPage  * limitPerPage;

  // Calculate the remaining projects needed to reach the total count
  const remainingItems = totalItems - loadedItems;

  // Calculate the number of projects to show for the loading skeleton
  let itemsToShow = remainingItems > limitPerPage ? limitPerPage : remainingItems;
  
  if (totalItems === 0) return MAX_PROJECTS_PLACE_HOLDER
  
  return Math.max(itemsToShow, 0);
} 

export function cva(baseClassName, config) {
  return function (variants) {
    const classNames = [];

    for (const variantName in variants) {
      if (variants.hasOwnProperty(variantName)) {
        const variantValue = variants[variantName];
        const classConfig = config.variants[variantName];

        if (classConfig) {
          const classVariant = classConfig[variantValue] || config.defaultVariants[variantName];
          classNames.push(classVariant);
        }
      }
    }


    return [baseClassName, ...classNames].filter(Boolean).join(" ");
  };
}
