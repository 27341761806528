// @mui material components
import Card from "@mui/material/Card";
import { Icon } from "@mui/material";
// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Grid from "@mui/material/Grid";
import React from 'react'
import InformationForm from "./InformationForm";
import Widget from "../Widget"
export default function Appearance({project}) {
  return (
    <VuiBox py={3}>
      <VuiBox mb={3}>
        <Grid container spacing={3} >
          <Grid item xs={12} xl={6} height="100%">
            <Card>
              <VuiBox >
                <VuiTypography variant="lg" fontWeight="bold" color="white" textTransform="capitalize">
                  Settings
                </VuiTypography>
                <InformationForm project={project}/>
              </VuiBox>
            </Card>
          </Grid>
          <Grid item xs={12} xl={6} height="100%">
            <Card>
              <VuiBox >
                <VuiTypography variant="lg" fontWeight="bold" color="white" textTransform="capitalize">
                  Preview
                </VuiTypography>
              </VuiBox>
              <Widget widget={project.widget}/>
            </Card>
          </Grid>
        </Grid>
      </VuiBox>
    </VuiBox>
  )
}
