import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Spin } from "antd";

const UserCreditStatus = () => {
  const user = useSelector((state) => state.user.user);
  if (!user) return <></>
  const [shouldShowLoading, setShouldShowLoading] = useState(false);
  const [currentBalance, setCurrentBalance] = useState(user.agent.balance_credit);
  
  useEffect(() => {
    if(currentBalance !== user.agent.balance_credit) {
      setShouldShowLoading(true)
      setCurrentBalance(user.agent.balance_credit);
      // Simulating an API request delay with setTimeout
      setTimeout(() => {
        setShouldShowLoading(false);
      }, 300);
    }

  }, [user.agent.balance_credit])


  return (
    <>
      {shouldShowLoading ? <><Spin size="small" className="mr-2" /></> : Number(user.agent?.balance_credit).toFixed(2)} MakerGPT Credits
    </>
  )
};

export default UserCreditStatus;
