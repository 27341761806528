import defaultMessageLogo from "assets/images/makergpt/logo_rounded.png";
import darkenColor from "../DarkenColor";
const MessagesOnTheLeft = ({ widget, content }) => {
  return (
    <div className="my-2 w-[80%] h-auto mr-auto">
      <div className="sm:w-[10%] w-[15%] align-top inline-block">
        <img src={widget?.logo || defaultMessageLogo} className="w-[2rem] h-[2rem] rounded-2xl" />
      </div>
      <div className="sm:w-[90%] w-[85%] inline-block">
        <p
          className="rounded-2xl p-3 w-fit font-bold text-start border-none"
          style={{
            color: widget.customer_background_color,
            backgroundColor: widget.bot_background_color,
            boxShadow: `0px 0px 2px ${darkenColor({
              hexColor: widget.bot_background_color,
              darkerNumber: 0.95,
            })}`,
          }}
        >
          {content}
        </p>
      </div>
    </div>
  );
};

export default MessagesOnTheLeft;
