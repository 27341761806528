import { Upload, message, Image, Col, Row, Button } from "antd";
import { CloseCircleFilled, EyeFilled } from "@ant-design/icons";
import { useState } from "react";
import "./css/index.css";
const ChangeAvatar = ({ formRef }) => {
  const [avatar, setAvatar] = useState("");
  const [preview, setPreview] = useState(false);
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const handleChange = async (fileList) => {
    const image = await getBase64(fileList.file.originFileObj);
    formRef.current?.setFieldsValue({
      ['logo']: fileList.file.originFileObj,
    });
    setAvatar(image);
  };
  return (
    <Row gutter={[15, 15]} className="w-full h-full m-0">
      <Col span={24} className="uploadAvatarClass center-an-item">
        {avatar === "" ? (
          <Upload
            disabled={avatar === "" ? false : true}
            name="avatar"
            onChange={handleChange}
            showUploadList={false}
            className="center-an-item w-full h-full"
            listType="picture-card"
            accept="image/png, image/gif, image/jpeg"
          >Upload Avatar</Upload>
        ) : (
          <Image
            className="w-[125px] h-[125px]"
            preview={
              preview === false
                ? false
                : {
                    visible: preview,
                    onVisibleChange: () => setPreview(!preview),
                  }
            }
            src={avatar}
          />
        )}
      </Col>
      <Col span={12} className="center-an-item">
        <Button
          onClick={() => setPreview(true)}
          disabled={avatar === "" ? true : false}
          type="primary"
          className="w-full"
          icon={<EyeFilled />}
        ></Button>
      </Col>
      <Col span={12} className="center-an-item">
        <Button
          onClick={() => setAvatar("")}
          disabled={avatar === "" ? true : false}
          type="primary"
          className="w-full"
          icon={<CloseCircleFilled />}
        ></Button>
      </Col>
    </Row>
  );
};

export default ChangeAvatar;
