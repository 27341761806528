import React from 'react'
import { Button, Form, Input, DatePicker } from 'antd';
import { useSelector } from "react-redux";

export default function DateTimeToolkit({question}) {
  const colorSettings = useSelector((state) => state.prompt.colorSettings);
  
  const buttonBackgroundColor = () => {
    if(!colorSettings.background.isGradient) return {backgroundColor: colorSettings.background.background_0}
    else return {backgroundImage: `linear-gradient(159.02deg, ${colorSettings.background.background_1}, ${colorSettings.background.background_2}, ${colorSettings.background.background_3})`}
  }

  return (
    <Form.Item name={question.id}
      label={
        <span style={{color: colorSettings.text}}>
          {question.main}
        </span>
      } 
    >
      <DatePicker className="w-full" 
        showTime={{
          format: 'HH:mm',
        }}
        format="YYYY-MM-DD HH:mm"
        size='large'
        style={{...buttonBackgroundColor(), color: colorSettings.text, borderColor: colorSettings.text}}
      />
    </Form.Item>
  )
}
