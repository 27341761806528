import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api_v1 from 'plugins/axios';

// Get one prompt_template 
export const fetchPackages = createAsyncThunk('order_store/fetchPackages', async () => {
  try {
    const response = await api_v1.get(`/packages`);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

export const createCheckoutSession = createAsyncThunk('order_store/createCheckoutSession', async (package_id) => {
  try {
    const response = await api_v1.post(`/orders/create_checkout_session`, {package_id});
    return response;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

export const cancelSubscription = createAsyncThunk('order_store/cancelSubscription', async () => {
  try {
    const response = await api_v1.post(`/orders/cancel_subscription`);
    return response;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

export const orderSlice = createSlice({
  name: 'order',
  initialState: {
    loading: false,
    error: null,
    packages: [],
    publicKeyStripe: '',
  },
  reducers: {
    addLocalHistory: (state, action) => { 
      state.localHistories = [...state.localHistories, {...action.payload}]
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPackages.pending, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchPackages.fulfilled, (state, action) => {
        state.packages = action.payload.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchPackages.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createCheckoutSession.pending, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(createCheckoutSession.fulfilled, (state, action) => {
        state.publicKeyStripe = action.payload.data.client_secret;
        state.loading = false;
        state.error = null;
      })
      .addCase(createCheckoutSession.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(cancelSubscription.pending, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(cancelSubscription.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(cancelSubscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  }
})

export const { addLocalHistory } = orderSlice.actions


export default orderSlice.reducer