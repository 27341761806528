// @mui material components
import Card from "@mui/material/Card";
import { Icon } from "@mui/material";
// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Grid from "@mui/material/Grid";
import React, {useEffect, useState} from 'react'
import InformationForm from "./InformationForm";
import { useSelector, useDispatch } from "react-redux";
import { fetchPromptTemplateById, updateColorSettings } from "store/promptSlice";
import GptForm from 'layouts/gptForm'
import { Spin } from "antd";
export default function AppearancePrompt({project}) {
  const [isLoading, setIsLoading] = useState(true)
  const dispatch = useDispatch()
  useEffect(() => { 
    setIsLoading(true)
    dispatch(fetchPromptTemplateById({project_id: project.prompt_template.id}))
      .then(({error, payload}) => { 
        if(!error) {
          let settings = payload.data
          dispatch(updateColorSettings({
            background: {
              isGradient: settings.is_gradient,
              'background_0': settings.bg_color0,
              'background_1': settings.bg_color1,
              'background_2': settings.bg_color2,
              'background_3': settings.bg_color3,
            },
            text: settings.textColor,
            avatarUrl: settings.logo,
          }))
        }
        setIsLoading(false) 
      })
  }, [])

  if (isLoading) { return <div className="s-spin-container"><Spin tip="Loading..."/></div>}
  return (
    <VuiBox py={3}>
      <VuiBox mb={3}>
        <Grid container spacing={3} >
          <Grid item xs={12} xl={6} height="100%">
            <Card>
              <VuiBox >
                <VuiTypography variant="lg" fontWeight="bold" color="white" textTransform="capitalize">
                  Settings
                </VuiTypography>
                <InformationForm project={project}/>
              </VuiBox>
            </Card>
          </Grid>
          <Grid item xs={12} xl={6} height="100%">
            <Card>
              <VuiBox >
                <VuiTypography variant="lg" fontWeight="bold" color="white" textTransform="capitalize">
                  Preview
                </VuiTypography>
              </VuiBox>
              <div>
                <GptForm readonly={true} token={project.prompt_template.token}></GptForm>
              </div>
            </Card>
          </Grid>
        </Grid>
      </VuiBox>
    </VuiBox>
  )
}
