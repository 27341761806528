import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api_v1 from "plugins/axios";
const initialState = {
  userValidated: false,
  user: null,
  error: null,
};

export const validateAuthTokenMiddleware = createAsyncThunk(
  "user_store/validateAuthTokenMiddleware",
  async (data) => {
    try {
      const response = await api_v1.get("/managers/profile");
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const fetchUserProfile = createAsyncThunk(
  "user_store/fetchUserProfile",
  async () => {
    try {
      const response = await api_v1.get("/managers/profile");
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const changeAccountUsername = createAsyncThunk(
  "user_store/changeAccountUsername",
  async (data) => {
    try {
      const params = {
        name: data.username
      }
      const response = await api_v1.put("/managers/profile", params)
      return response.data
    } catch (error) {
      return error.response.data;
    }
  }
);
export const changeAccountPassword = createAsyncThunk(
  "user_store/changeAccountPassword",
  async (data) => {
    try {
      const params = {
        current_password: data.currentPassword,
        new_password: data.newPassword,
        password_confirmation: data.newConfirmPassword
      }
      const response = await api_v1.put("/managers/profile/update_password", params);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const changeAccountReferralLink = createAsyncThunk(
  "user_store/changeAccountReferralLink",
  async (data) => {
    try {
      const params = {
        referral_link: data.referralLink
      }
      const response = await api_v1.put("/managers/profile/update_referral_link", params)
      return response.data
    } catch (error) {
      return error.response.data;
    }
  }
);

const userSlice = createSlice({
  name: "user_store",
  initialState,
  reducers: {
    userSuccessfullyValidated: (state, action) => {
      state.userValidated = true;
      state.user = action.payload;
      state.error = null;
    },
    userFailureValidated: (state, action) => {
      state.userValidated = false;
      state.user = null;
      state.error = action.payload;
    },
    userLogOut: (state) => {
      state.userValidated = false;
      state.user = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserProfile.pending, (state, action) => {
        state.error = null;
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.user = action.payload.data;
        state.error = null;
      })
      .addCase(fetchUserProfile.rejected, (state, action) => {
        state.error = action.error.message;
      })
  },
});

export const { userSuccessfullyValidated, userFailureValidated, userLogOut } =
  userSlice.actions;
export default userSlice.reducer;
