import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api_v1 from 'plugins/axios';
import dumpData from 'layouts/prompt/components/PromptBuilder/dumpData';
// Define initial state

const initialState = {
  value: 0,
  highlightPrompt: '',
  promptDetail: {},
  colorSettings: {
    background: {
      isGradient: false,
      'background_0': '#0f123b',
      'background_1': '#0f123b',
      'background_2': '#090d2e',
      'background_3': '#020515',
    },
    text: '#fff',
    avatarUrl: ''
  },
  promptTemplate: {},
  loading: false,
  receiveResponse: false,
  error: null,
  responsePrompt: dumpData.initData.responsePrompt,
}

// Create async thunk for updating data
export const updatePoweredForm = createAsyncThunk('prompt_store/updatePoweredForm', async ({prompt_id, payload}) => {
  try {
    const response = await api_v1.put(`prompt_templates/${prompt_id}/powered_form`, payload);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

// Test Prompt Action
export const testPrompt = createAsyncThunk('prompt_store/testPrompt', async ({prompt_id, payload}) => {
  try {
    const response = await api_v1.post(`prompt_templates/${prompt_id}/test_prompt`, payload);
    return response;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

// Update Prompt Information Action
export const updateColorSettingForm = createAsyncThunk('prompt_store/updateColorSettingForm', async ({prompt_id, payload}) => {
  try {
    const response = await api_v1.put(`prompt_templates/${prompt_id}/prompt_bg_color`, payload);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  } 
})

// Get one prompt_template 
export const fetchPromptTemplateById = createAsyncThunk('prompt_store/fetchPromptTemplateById', async ({project_id, payload}) => {
  try {
    const response = await api_v1.get(`/prompt_templates/${project_id}`, { params: payload });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

export const promptSlice = createSlice({
  name: 'prompt',
  initialState,
  reducers: {
    resetPromptState: state => initialState,
    updatePromptDetail: (state, action) => {
      state.promptDetail = {...state.promptDetail, ...action.payload}
    },
    updateQuestionById: (state, action) => { 
      const {id, question} = action.payload
      const index = state.promptDetail.questions.findIndex(q => q.id === id)
      // if(!question.example || !question.example.length) { question.example = 'User Input' }
      state.promptDetail.questions[index] = {...state.promptDetail.questions[index], ...question}
    },
    addQuestion: (state, action) => { 
      state.promptDetail.questions = [...state.promptDetail.questions, {...action.payload}]
    },
    sortQuestionByIdOrder: (state, action) => {
      state.promptDetail.questions = action.payload.map(id => state.promptDetail.questions.find(item => item.id === id)).filter(e => e);
    },
    setHighlightPrompt: (state, action) => {
      let question = action.payload
      if(!question) { return }
      if(!question.example || !question.example.length) { question.example = 'User Input' }
      state.highlightPrompt = question
    },
    updateColorSettings: (state, action) => {
      state.colorSettings = {...state.colorSettings, ...action.payload}
    },
    setColorSettings: (state, {payload}) => {
      const settings = {
        background: {
          isGradient: payload.is_gradient,
          'background_0': payload.bg_color0,
          'background_1': payload.bg_color1,
          'background_2': payload.bg_color2,
          'background_3': payload.bg_color3,
        },
        text: payload.textColor,
        avatarUrl: payload.logo,
      }

      state.colorSettings = { ...state.colorSettings, ...settings}
    },
    setPromptDetail: (state, action) => {
      state.promptDetail = action.payload
    },
    setResponsePrompt: (state, action) => {
      state.responsePrompt = action.payload
    },
    addTextToResponsePrompt: (state, action) => {
      state.responsePrompt = state.responsePrompt + action.payload
    },
    setReceiveResponse: (state, action) => {
      state.receiveResponse = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(updatePoweredForm.pending, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(updatePoweredForm.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(updatePoweredForm.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(testPrompt.pending, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(testPrompt.fulfilled, (state, action) => {
        state.responsePrompt = action.payload?.data?.data?.answer;
        state.loading = false;
        state.error = null;
      })
      .addCase(testPrompt.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateColorSettingForm.pending, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(updateColorSettingForm.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(updateColorSettingForm.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchPromptTemplateById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPromptTemplateById.fulfilled, (state, action) => {
        state.promptTemplate = action.payload.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchPromptTemplateById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  }
})

export const { addTextToResponsePrompt, updatePromptDetail, updateQuestionById, addQuestion, sortQuestionByIdOrder, setHighlightPrompt, updateColorSettings, setPromptDetail, resetPromptState, setColorSettings, setResponsePrompt, setReceiveResponse } = promptSlice.actions

export const getPromptDetail = (state) => state.prompt.promptDetail

export default promptSlice.reducer