import React from 'react';
import { Space, Table, Tag } from 'antd';
import { useSelector } from 'react-redux';


export default function QuestionTable() {
  const questions = useSelector((state) => state.project.detail.prompt_template.questions);
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Question',
      dataIndex: 'main',
      key: 'main',
    },
    {
      title: 'Example',
      dataIndex: 'example',
      key: 'example',
    },
  ];

  return (<Table columns={columns} dataSource={questions} pagination={false}/>)
}
