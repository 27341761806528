import { Button, message, Popconfirm } from 'antd';
import { EyeFilled } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { FILE_KINDS } from 'variables/fileKinds';
import PreviewModal from 'examples/Modal';
import { NOT_UPLOAD_TYPES } from 'variables/fileKinds';

const PreviewFile = ({file}) => {
  const dispatch = useDispatch();

  // If is type INPUT_TEXT or FAQ 
  if (NOT_UPLOAD_TYPES.includes(file.kind)) {
    return null
  }

  // Other types
  return (
    <a href={file.file_url || file.meta_data.url} target="_blank" rel="nofollow noopener">
      <Button className='text-white/80 hover:text-white' type="link" primary="true" icon={<EyeFilled />}>
        View
      </Button>
    </a>
  )
};
export default PreviewFile;