

/** 
  All of the routes for the Vision UI Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Vision UI Dashboard React layouts
import Dashboard from "layouts/dashboard";
import Chat from "layouts/chat";
import Memory from "layouts/memory";
import Project from "layouts/projects";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import Profile from "layouts/profile";
import Prompt from "layouts/prompt";
import GptForm from "layouts/gptForm";
import SignIn from "layouts/authentication/sign-in";
import Verify from "layouts/authentication/verify";
import Recovery from "layouts/authentication/recovery";
import SignUp from "layouts/authentication/sign-up";
import Widget from "layouts/widgets";
import LogOut from "layouts/authentication/log-out";
import PrivacyPolicy from "layouts/terms/privacyPolicy";
import TermsAndConditions from "layouts/terms/termsAndConditions";
import Upgrade from "layouts/upgrade"
import Usage from "layouts/usage"
import StripeSuccess from "layouts/payments/stripe/StripeSuccess"
import StripeFail from "layouts/payments/stripe/StripeFail"
// Vision UI Dashboard React icons
import { IoImageOutline, IoImages, IoRocketSharp } from "react-icons/io5";
import { IoIosDocument } from "react-icons/io";
import { BsFillPersonFill } from "react-icons/bs";
import { IoBuild } from "react-icons/io5";
import { BsCreditCardFill } from "react-icons/bs";
import { IoStatsChart } from "react-icons/io5";
import { IoHome } from "react-icons/io5";
import { IoChatboxSharp, IoRocket, IoBarChart } from "react-icons/io5";
import {GoSignOut} from "react-icons/go";
import {ImTerminal} from "react-icons/im";
import { Fragment } from "react";
import Gallery from "layouts/gallery";
import { QuestionCircleOutlined, UserOutlined } from "@ant-design/icons";

const routes = [
  {
    type: "collapse",
    name: "Projects",
    key: "projects",
    route: "/projects",
    icon: <IoRocket size="15px" color="inherit" />,
    component: Memory,
    noCollapse: true,
    private: true,
  },
  {
    type: "collapse",
    name: "Project",
    key: "projects",
    route: "/projects/:id",
    icon: <IoRocket size="15px" color="inherit" />,
    component: Project,
    noCollapse: true,
    isHide: true,
    private: true,
  },
  {
    type: "collapse",
    name: "Gallery",
    key: "gallery",
    route: "/gallery",
    icon: <IoImages size="15px" color="inherit" />,
    component: Gallery,
    isListed: true,
    isHide: false,
    shouldShowInGallery: true
  },
  {
    type: "collapse",
    name: "Prompt Frame",
    key: "prompt",
    route: "/prompt",
    icon: <ImTerminal size="15px" />,
    component: Prompt,
    noCollapse: true,
    private: true,
    isHide: true,
  },
  { type: "title", title: "Account", key: "account-pages" },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    route: "/profile",
    icon: <BsFillPersonFill size="15px" color="inherit" />,
    component: Profile,
    noCollapse: true,
    private: true,
  },
  {
    type: "collapse",
    name: "Upgrade",
    key: "upgrade",
    route: "/upgrade",
    icon: <BsFillPersonFill size="15px" color="inherit" />,
    isHide: true,
    component: Upgrade,
    noCollapse: true,
    private: true,
  },
  {
    type: "collapse",
    name: "Usage",
    key: "usage",
    route: "/usage",
    icon: <IoBarChart size="15px" color="inherit" />,
    component: Usage,
    noCollapse: true,
    private: true,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    route: "/sign-in",
    icon: <UserOutlined size="15px" color="inherit" />,
    component: SignIn,
    noCollapse: true,
    private: false,
    isHide: true,
    isBottom: true,
    shouldShowInGallery: true
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    route: "/sign-up",
    icon: <IoRocketSharp size="15px" color="inherit" />,
    component: SignUp,
    noCollapse: true,
    private: false,
    isHide: true,
  },
  {
    type: "collapse",
    name: "Verify Email",
    key: "verify",
    route: "/verify",
    icon: <IoIosDocument size="15px" color="inherit" />,
    component: Verify,
    noCollapse: true,
    private: false,
    isHide: true,
  },
  {
    type: "collapse",
    name: "Password Recovery ",
    key: "recovery",
    route: "/recovery",
    icon: <IoIosDocument size="15px" color="inherit" />,
    component: Recovery,
    noCollapse: true,
    private: false,
    isHide: true,
  },
  {
    type: "collapse",
    name: "Log Out",
    key: "log-out",
    route: "/log-out",
    icon: <GoSignOut size="15px" color="inherit" />,
    component: LogOut,
    noCollapse: true,
    private: true,
  },
  {
    type: "collapse",
    name: "Privacy Policy",
    key: "privacy-policy",
    route: "/privacy-policy",
    icon: <IoRocketSharp size="15px" color="inherit" />,
    component: PrivacyPolicy,
    noCollapse: true,
    isHide: true,
    private: false,
  },
  {
    type: "collapse",
    name: "Terms",
    key: "terms-and-conditions",
    route: "/terms-and-conditions",
    icon: <QuestionCircleOutlined size="15px" color="inherit" />,
    component: TermsAndConditions,
    noCollapse: true,
    isHide: true,
    private: false,
  },
  {
    type: "collapse",
    name: "Webchat",
    key: "webchat",
    route: "/webchat/:widgetToken",
    component: Widget,
    noCollapse: true,
    isListed: true,
    isHide: true,
    private: true,
  },
  {
    type: "collapse",
    name: "GPT Powered Form",
    key: "gpt_form",
    route: "/gpt_form/:id",
    component: GptForm,
    noCollapse: true,
    isListed: true,
    isHide: true,
    private: true,
  },
  {
    type: "collapse",
    name: "Stripe Payment",
    key: "stripe_success",
    route: "/payments/stripe/success",
    component: StripeSuccess,
    noCollapse: true,
    private: true,
    isHide: true,
  },
  {
    type: "collapse",
    name: "Stripe Payment",
    key: "stripe_fail",
    route: "/payments/stripe/fail",
    component: StripeFail,
    noCollapse: true,
    private: true,
    isHide: true,
  },
];

export default routes;