export const FILE_KINDS = {
  TXT: 0,
  DOCX: 1,
  PDF: 2,
  CSV: 3,
  EXCEL: 4,
  WEBSITE_URL: 5,
  INPUT_TEXT: 6,
  FAQ: 7,
};

// Show contents if file type are INPUT_TEXT or FAQ
export const NOT_UPLOAD_TYPES = [FILE_KINDS.INPUT_TEXT, FILE_KINDS.FAQ]
export const UPLOAD_TYPES = [FILE_KINDS.TXT, FILE_KINDS.DOCX, FILE_KINDS.PDF, FILE_KINDS.CSV, FILE_KINDS.EXCEL];
export const URL_TYPES = [FILE_KINDS.WEBSITE_URL];
