import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
// react-router components
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import Cookies from "js-cookie";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";

// Vision UI Dashboard React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Vision UI Dashboard React themes
import themeVision from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { GoogleOAuthProvider } from "@react-oauth/google";
// Vision UI Dashboard React routes
import routes, { galleryRoutes } from "routes";

// Vision UI Dashboard React contexts
import {
  useVisionUIController,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
// Global CSS
import "./index.css";

import { StyleProvider } from "@ant-design/cssinjs";
import { ConfigProvider, theme } from "antd";
import { validateAuthTokenMiddleware } from "store/userSlice";
import {
  userSuccessfullyValidated,
  userFailureValidated,
} from "store/userSlice";
import GptForm from "layouts/gptForm";
import GallerySideNav from "examples/Sidenav/GallerySideNav";
import {Helmet} from "react-helmet";

export default function App() {
  const [middlewareRun, setMiddlewareRun] = useState(false);
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } =
    controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const dispatchHook = useDispatch();
  const user = useSelector((state) => state.user);
  const [pageTitle, setPageTitle] = useState("");

  useEffect(async () => {
    //Token validation middleware
    setMiddlewareRun(false)
    if (Cookies.get("authToken") !== undefined) {
      const res = await dispatchHook(validateAuthTokenMiddleware());
      if (res.payload.statusCode === 200) {
        dispatchHook(userSuccessfullyValidated(res.payload.data));
        setMiddlewareRun(true);
      } else {
        Cookies.remove("authToken");
        dispatchHook(userFailureValidated(res.payload.message));
        setMiddlewareRun(true);
      }
    } else {
      setMiddlewareRun(true);
    }
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;

    setPageTitle(findTitle(pathname))
  }, [pathname]);

  function findTitle(pathname) {
    const route = routes.find(route => {
      return route?.route?.includes(pathname)
    })
    if (route) {
      return route.name + ' - MakerGPT'
    }
    return "MakerGPT"
  }

  const getRoutes = (allRoutes) => {
    if (user.userValidated === true) {
      return getPrivateRoute(allRoutes);
    } else {
      return getPublicRoute(allRoutes);
    }
  };

  const getPrivateRoute = (allRoutes) => {
    return allRoutes.filter(route => !route.isListed).map((route) => {
      if (route.private) {
        if (route.route) {
          return (
            <Route
              exact
              path={route.route}
              component={route.component}
              key={route.key}
            />
          );
        }
      } else {
        if (route.route) {
          return <Redirect from={route.route} to="/projects" key={route.key} />;
        }
      }

      return null;
    });
  };

  const getPublicRoute = (allRoutes) => {
    return allRoutes.filter(route => !route.isListed).map((route) => {
      if (!route.private) {
        if (route.route) {
          return (
            <Route
              exact
              path={route.route}
              component={route.component}
              key={route.key}
            />
          );
        }
      } else {
        if (route.route) {
          return <Redirect from={route.route} to="/sign-in" key={route.key} />;
        }
      }

      return null;
    });
  };

  const getListedRoutes = (allRoutes) => { 
    return allRoutes.filter(route => route.isListed)
      .map((route) => {
        return <Route exact path={route.route} component={route.component} key={route.key} />
      })
  }

  const redirectHomePage = () => {
    if (user.userValidated === true) {
      return <Redirect from="/" to="/projects" />;
    } else {
      return <Redirect from="/" to="/sign-in" />;
    }
  }

  const layoutsToShowSideBar = ["gallery", "dashboard"]

  const renderSideBar = () => {
    if (layout === "gallery") return (
      <GallerySideNav
        color={sidenavColor}
        brand=""
        brandName="MAKERGPT"
        routes={routes.filter(route => {
          if (user.userValidated === true) {
            return route && !route.isHide
          } else {
            return route.shouldShowInGallery
          }
        })}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      />
    )
    return (
      <Sidenav
        color={sidenavColor}
        brand=""
        brandName="MAKERGPT"
        routes={routes}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      />
    );
  };

  return (
    <ThemeProvider theme={themeVision}>
      <Helmet>
        <title>{ pageTitle }</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <ConfigProvider theme={{ algorithm: theme.darkAlgorithm }}>
        <StyleProvider hashPriority="high">
          <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
            <CssBaseline />
            { middlewareRun && layoutsToShowSideBar.includes(layout) && renderSideBar()}
            <Switch>
              { getListedRoutes(routes) }
              { middlewareRun && getRoutes(routes) }
              { middlewareRun && redirectHomePage() }
            </Switch>
          </GoogleOAuthProvider>
        </StyleProvider>
      </ConfigProvider>
    </ThemeProvider>
  );
}
