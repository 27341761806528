import React from "react";
import { Card, Button, Tooltip, Typography } from "antd";
import { CopyOutlined } from '@ant-design/icons';
import CopyResponseButton from "./CopyResponseButton";
import { useSelector } from "react-redux";
import './History.css';
import { timestampFormat } from "helpers/times";
export default function History({history}) {
  const colorSettings = useSelector((state) => state.prompt.colorSettings);
  const renderQuestions = (questions) => {
    return questions.map((question, index) => { 
      return (
        <>
          <span>{question.main}</span> <br />
          <span className="font-normal italic text-sm">{question.example}</span><br />
        </>)
    })
  }

  const renderTitle = () => {
    return (
      <div className="py-4 whitespace-pre-wrap">
        { renderQuestions(history.questions) }
      </div>
    )
  }

  const buttonBackgroundColor = () => {
    if(!colorSettings.background.isGradient) return {backgroundColor: colorSettings.background.background_0}
    else return {backgroundImage: `linear-gradient(159.02deg, ${colorSettings.background.background_1}, ${colorSettings.background.background_2}, ${colorSettings.background.background_3})`}
  }

  return (
    <Card
      title={renderTitle()}
      className="mb-4 history-card"
      actions={[
        <span style={{color: colorSettings.text}}>{timestampFormat(history.created_at)}</span>
      ]}
      style={{...buttonBackgroundColor(), borderColor: colorSettings.text, color: colorSettings.text}}
    >  
      <div className="relative">
        <div className="absolute" style={{right: '-2px', top: '-44px'}}>
          <CopyResponseButton response={history.answer}/>
        </div>
        <span className="whitespace-pre-wrap">{history.answer}</span>
      </div>
    </Card>
  );
}
