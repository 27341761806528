// @mui material components
import Card from "@mui/material/Card";
import { Icon } from "@mui/material";
// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Grid from "@mui/material/Grid";
import React from 'react'
import InputCopy from "./InputCopy";
import welcome from "assets/images/welcome-profile.png";
import InputSize from "./InputSize";
import VisibilityRadioGroup from "../AdvancedOptions/VisibilityRadioGroup";
import { useSelector } from "react-redux";
import { getPromptDetail } from "store/promptSlice";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Tooltip } from "antd";
import CategoryOptions from "layouts/prompt/components/AdvancedOptions/CategoryOptions";
import IntegrateCode from "./IntegrateCode";
import LimitGuestUsage from "../AdvancedOptions/LimitGuestUsage";

export default function PublishPrompt({project}) {
  const promptDetail = useSelector(getPromptDetail)
  const link = window.location.origin + "/gpt_form/" + project?.prompt_template?.token
  const [widthSize, setWidthSize] = React.useState('100%')
  const [heightSize, setHeightSize] = React.useState('600px')
  
  const handleSizeChange = (value) => {
    if(value.label === "Width") setWidthSize(value.value + value.unit)
    else setHeightSize(value.value + value.unit)
  }
  return (
    <VuiBox py={3}>
      <VuiBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} xl={12} height="100%">
            <Card>
              <div>
                <div className="flex justify-between">
                  <div className="my-4">
                    <div className="text-white text-base">Embed the Form</div>
                    <div className="text-white text-xs">
                      Copy the JavaScript below and insert it into the HEAD or BODY tags of your HTML page to embed the Form.
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <InputSize
                      label="Width"
                      onSizeChange={handleSizeChange}
                      defaultUnit={"%"}
                      defaultValue={100}
                    />
                    <InputSize
                      label="Height"
                      onSizeChange={handleSizeChange}
                      defaultUnit={"px"}
                      defaultValue={600}
                    />
                  </div>
                </div>
                <InputCopy
                  link={`<iframe src="${link}" width="${widthSize}" height="${heightSize}"/>`}
                />
              </div>
              <div>
                <div className="my-4">
                  <div className="text-white text-base">Share the Form</div>
                  <div className="text-white text-xs">
                    Navigate to the URL below to use the Form
                  </div>
                </div>
                <InputCopy link={link} type={"URL"} />
              </div>
              <div>
                <div className="mt-4 mb-2 flex items-center space-x-1">
                  <div className="text-white text-base">Visibility</div>
                  <Tooltip title="Public forms will be listed in the MakerGPT Gallery. Private forms can only be seen using the sharable URL or embedded in websites">
                    <ErrorOutlineIcon className="text-white w-4 h-4 mt-[2px]" />
                  </Tooltip>
                </div>
                <VisibilityRadioGroup promptDetail={promptDetail} />
              </div>
              <div>
                <div className="mt-4 mb-2 space-x-1 flex items-center">
                  <div className="text-white text-base">Tags</div>
                  <Tooltip title="Tag your form with appropriate categories to make it easier to find in the MakerGPT Public Gallery">
                    <ErrorOutlineIcon className="text-white w-4 h-4 mt-[2px]" />
                  </Tooltip>
                </div>
                <CategoryOptions promptDetail={promptDetail} />
              </div>
              <div>
                <div className="mt-4 mb-2 flex items-center space-x-1">
                  <div className="text-white text-base">Limit Guest Usage</div>
                  <Tooltip title="Set the limit for the number of times guests can use your shared form and your MakerGPT credits before they need to Sign Up to MakerGPT and use their own MakerGPT credits">
                    <ErrorOutlineIcon className="text-white w-4 h-4 mt-[2px]" />
                  </Tooltip>
                </div>
                <LimitGuestUsage promptDetail={promptDetail} />
              </div>
            </Card>
          </Grid>
          <Grid item xs={12} xl={12} height="100%">
            <IntegrateCode />
          </Grid>
        </Grid>
      </VuiBox>
    </VuiBox>
  );
}
