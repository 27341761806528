import React from 'react'
import SliderWithInput from './SliderWithInput'

export default function OutputOptions({promptDetail}) {
  return (
    <div>
      <SliderWithInput 
        label="Temperature"
        max={2}
        min={0}
        step={0.1}
        keyword="temperature"
        defaultValue={promptDetail.temperature}
      />
      <SliderWithInput 
        label="Frequency penalty"
        max={2}
        min={0}
        step={0.1}
        keyword="freq_penalty"
        defaultValue={promptDetail.freq_penalty}
      />
      <SliderWithInput 
        label="Presence penalty"
        max={2}
        min={0}
        step={0.1}
        keyword="presence_penalty"
        defaultValue={promptDetail.presence_penalty}
      />
    </div>
  )
}
