import { Input, Radio, Space, Button, Typography } from 'antd';
import { useState } from 'react';
import { v4 as uuidv4 } from "uuid";
import {EditOutlined, DeleteOutlined }  from '@ant-design/icons';
import Card from "@mui/material/Card";
import { useDispatch, useSelector } from 'react-redux';
import { updateQuestionById, setHighlightPrompt } from "store/promptSlice";
import { useEffect } from 'react';
const { Paragraph } = Typography;

const SingleChoiceAnswer = ({question}) => {
  const [value, setValue] = useState(1);
  const [options, setOptions] = useState([])
  const [editableStr, setEditableStr] = useState('This is an editable text.');
  const dispatch = useDispatch()

  useEffect(() => {
    if(question.secondary && question.secondary.length > 0) {
      const _questions = JSON.parse(question.secondary)
      setOptions(_questions)
      setValue(_questions[0].value)
    } else {
      setOptions([
        { label: 'Option 1', value: uuidv4() },
        { label: 'Option 2', value: uuidv4() },
      ])
    }
  }, [])

  useEffect(() => {
    let payload = {
      id: question.id, 
      question: {secondary: JSON.stringify(options)}
    }
    dispatch(updateQuestionById(payload))
  }, [options])

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onRadioChange = (e, option) => { 
    let payload = {
      id: question.id, 
      question: {example: option.label}
    }
    dispatch(updateQuestionById(payload))
    dispatch(setHighlightPrompt({...question, example: option.label}))
  }
  
  const handleAddClick = () => {
    setOptions([...options, { label: `Option ${options.length + 1}`, value: uuidv4() }])
  }
  
  const handleDeleteClick = (id) => {
    return () => {
      setOptions(options.filter(option => option.value !== id))
    }
  }

  const handleUpdateClick = (id, value) => { 
    setOptions(options.map(option => {
      if (option.value === id) {
        return { ...option, label: value }
      }
      return option
    }))
  }

  const renderOptions = () => {
    return options.map((option, index) => {
      return (
        <Radio value={option.value} key={option.value} onChange={(e) => onRadioChange(e, option)}>
          <div className='flex gap-2'>
            <Paragraph
              editable={{
                onChange: (str) => handleUpdateClick(option.value, str),
                icon: <EditOutlined className='ml-3 text-white' />
              }}
              className='mb-0 ml-2'
            >
              {option.label}
            </Paragraph>
            <a className='ml-1 text-rose-600' onClick={handleDeleteClick(option.value)}><DeleteOutlined/></a>
          </div>
        </Radio>
      )
    })
  }
  return (
    <Card>
      <div className='flex flex-col'>
        <Radio.Group onChange={onChange} value={value}>
          <Space direction="vertical">
          {renderOptions()}
          </Space>
        </Radio.Group>
        <a className='mt-3 text-white/80 hover:text-white' onClick={handleAddClick}>+ Add Option</a>
      </div>
    </Card>
  );
};
export default SingleChoiceAnswer;