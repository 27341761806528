import React, { useEffect, useRef } from "react";
import { Input, DatePicker } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import { updateQuestionById, setHighlightPrompt } from "store/promptSlice";
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)
const { TextArea } = Input;

export default function ShortAnswer({ question }) {
  const inputRef = useRef(null);
  const dispatch = useDispatch()
  const highlightPrompt = useSelector(state => state.prompt.highlightPrompt)
  const timeFormat = 'YYYY-MM-DD'
  useEffect(() => {
    inputRef.current.focus({cursor: 'end',});
  }, []);

  useEffect(() => {
    if(question.id == highlightPrompt?.id) {
      inputRef.current.focus({cursor: 'end',});
    }
  }, [highlightPrompt]);

  const handleChange = (date, dateString) => {
    let payload = {
      id: question.id, 
      question: {example: dateString}
    }
    dispatch(updateQuestionById(payload))
    dispatch(setHighlightPrompt({...question, example: dateString}))
  }
  return (
    <DatePicker onChange={handleChange} className="w-full" 
      value={
        dayjs(question.example).isValid() ? 
        dayjs(question.example) :
        null
      }
      showTime={{
        format: 'HH:mm',
      }}
      ref={inputRef}
    />
  );
}
