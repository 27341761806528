import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Modal, Input, message, Result, Button, Spin, Form, Row, Col } from "antd";

// @mui material components
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import rgba from "assets/theme/functions/rgba";
import Card from "@mui/material/Card";
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";
import { CloseOutlined, CheckOutlined, GoogleOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";

// slice
import { checkVerifyCode, authenticatorSigninGoogle, resendVerifyEmail } from "store/authenticatorSlice";
import { userSuccessfullyValidated } from "store/userSlice";

// react-router-dom components
import { Link } from "react-router-dom";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
// Authentication layout components
import CoverLayout from "layouts/authentication/components/WebviewLayout";

// Images
import bgSignIn from "assets/images/signInImage.png";
import { useGoogleLogin } from "@react-oauth/google";
import { useLocation } from "react-router-dom"
function Verify() {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const code = params.get('confirm_code')
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
    },
  };

  useEffect(() => {
    setIsLoading(true)
    if(code && code.length){
      dispatch(checkVerifyCode(code))
        .then((res) => {
          setIsLoading(false)
          if(res.payload.statusCode === 200) {
            setIsSuccess(true)
          } else {
            setIsSuccess(false)
          }
        })
    } else {
      setIsSuccess(false)
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    if(isSuccess) {
      setTimeout(() => {
        window.location.href = "/sign-in"
      }, 5000)
    }
  }, [isSuccess])

  const handleResendEmail = ({email}) => {
    setConfirmLoading(true);
    dispatch(resendVerifyEmail(email))
      .then((res) => {
        setConfirmLoading(false);
        if(res.payload.statusCode === 200) {
          message.success("Resend email successfully");
        } else {
          message.error(res.payload.message);
        }
      })
  }

  const renderSuccessResult = () => {
    return (
      <Card>
        <Result
          status="success"
          title="Congratulations! Your email has been successfully verified."
          subTitle=" You are now one step closer to accessing our platform. Please wait while we redirect you to the sign-in page.
            If you are not automatically redirected in 5 seconds, please click here to proceed to the sign-in page."
          extra={[
            <a href="/sign-in">
              <Button type="primary" key="console">
                Redirect to Sign In
              </Button>
            </a>,
          ]}
        />
      </Card>
    )
  }

  const renderFailResult = () => {
    return (
      <Card>
        <Result
          status="error"
          title="We're sorry, but it seems that the verification process for your email has not been successful."
          subTitle="To resend the verification email, please enter your email address in the field below and click on the 'Resend' button. Make sure to check your email inbox and spam folder for the new verification email."
        >
          <Form
            onFinish={handleResendEmail}
            validateMessages={validateMessages}
          >
            <Row gutter={[4]}>
              <Col span={20}>
                <Form.Item
                  name={'email'}
                  label="Email"
                  rules={[
                    {
                      type: 'email',
                      required: true,
                    },
                  ]}
                >
                  <Input/>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Button type="primary" htmlType="submit" block loading={confirmLoading}>
                  Resend
                </Button>
              </Col>
            </Row>
          </Form>
        </Result>
      </Card>
    )
  }

  return (
    <CoverLayout
      title="Verify Email"
      color="white"
      premotto="INSPIRED BY THE FUTURE:"
      motto="THE MAKERGPT PLATFORM"
    >
      { isLoading ? 
        <div className="s-spin-container"><Spin tip="Verifying..."/></div> :
        isSuccess ? renderSuccessResult() : renderFailResult()
      }

    </CoverLayout>
  );
}

export default Verify;