import { Select, message } from "antd";
import { useDispatch } from "react-redux";
import { updatePoweredForm, updatePromptDetail } from "store/promptSlice";

const LIMIT_OPTIONS = [
  {
    label: "Unlimited",
    value: 999999,
  },
  {
    label: "0",
    value: 0,
  },
  {
    label: "5",
    value: 5,
  },
  {
    label: "10",
    value: 10,
  },
  {
    label: "15",
    value: 15,
  },
  {
    label: "20",
    value: 20,
  },
  {
    label: "25",
    value: 25,
  },
  {
    label: "30",
    value: 30,
  },
  {
    label: "35",
    value: 35,
  },
  {
    label: "40",
    value: 40,
  },
  {
    label: "45",
    value: 45,
  },
  {
    label: "50",
    value: 50,
  },
];

export default function LimitGuestUsage({ promptDetail }) {
  const dispatch = useDispatch();

  const handleChange = (value) => {
    dispatch(updatePromptDetail({ limit_guest_usage: value }));
    dispatch(
      updatePoweredForm({
        prompt_id: promptDetail.id,
        payload: { limit_guest_usage: value },
      })
    )
      .then((response) => {
        if (response.error) {
          message.error(response.error.message);
        }
        if (response.payload) {
          message.success(response.payload.message);
        }
      })
      .catch((err) => message.error(err));
  };

  return (
    <Select
      size="large"
      value={promptDetail.limit_guest_usage}
      className="capitalize mt-4 xl:w-[85%] w-[75%] mr-auto border-none"
      onChange={handleChange}
      options={LIMIT_OPTIONS}
    />
  );
}
