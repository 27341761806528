import './css/index.css'
const PrivacyPolicy = () => {
  return (
    <div
      id="privacyPolicy"
      style={{ zIndex: "1500" }}
      className="w-full h-full fixed top-0 left-0 bg-white flex justify-center"
    >
      <div className="w-[80%] h-auto py-5 overflow-y-auto">
      <h1 className="c13" id="h.gjdgxs"><span className="c15">Privacy policy</span></h1>
    <p className="c4">
      <span className="c5 c17">DATA PROTECTION NOTICE FOR CUSTOMERS</span>
    </p>
    <p className="c4">
      <span className="c6">This Data Protection Notice (&ldquo;</span
      ><span className="c5">Notice</span
      ><span className="c6">&rdquo;) sets out the basis by which </span
      ><span className="c6 c19">MMPS Technologies Pte. Ltd.</span
      ><span className="c6">&nbsp;(&ldquo;</span><span className="c5">we</span
      ><span className="c6">&rdquo;, &ldquo;</span><span className="c5">us</span
      ><span className="c6">&rdquo;, or &ldquo;</span><span className="c5">our</span
      ><span className="c6"
        >&rdquo;) may collect, use, disclose or otherwise process personal data
        of our customers in accordance with the Personal Data Protection Act
        (&ldquo;</span
      ><span className="c5">PDPA</span
      ><span className="c1"
        >&rdquo;). This Notice applies to personal data in our possession or
        under our control, including personal data in the possession of
        organizations which we have engaged to collect, use, disclose or process
        personal data for our purposes.</span
      >
    </p>
    <p className="c4"><span className="c8 c5">PERSONAL DATA </span></p>
    <ol className="c0 lst-kix_rtlnb0ew4h94-0 start" start="1">
      <li className="c12 c11 li-bullet-0">
        <span className="c6">As used in this Notice:</span>
      </li>
    </ol>
    <p className="c4 c9">
      <span className="c6">&ldquo;</span><span className="c5">customer</span
      ><span className="c1"
        >&rdquo; means an individual who (a) has contacted us through any means
        to find out more about any goods or services we provide, or (b) may, or
        has, entered into a contract with us for the supply of any goods or
        services by us; and</span
      >
    </p>
    <p className="c4 c9">
      <span className="c6">&ldquo;</span><span className="c5">personal data</span
      ><span className="c1"
        >&rdquo; means data, whether true or not, about a customer who can be
        identified: (a) from that data; or (b) from that data and other
        information to which we have or are likely to have access.</span
      >
    </p>
    <ol className="c0 lst-kix_list_10-0 start" start="2">
      <li className="c2 li-bullet-0">
        <span className="c6"
          >Depending on the nature of your interaction with us, some examples of
          personal data which we may collect from you include name, gender, and
          messaging platform id.</span
        >
      </li>
      <li className="c2 li-bullet-0">
        <span className="c1"
          >Other terms used in this Notice shall have the meanings given to them
          in the PDPA (where the context so permits).</span
        >
      </li>
    </ol>
    <p className="c14"><span className="c1"></span></p>
    <p className="c4">
      <span className="c8 c5"
        >COLLECTION, USE, AND DISCLOSURE OF PERSONAL DATA</span
      >
    </p>
    <ol className="c0 lst-kix_list_10-0" start="4">
      <li className="c4 c11 li-bullet-0">
        <span className="c6"
          >We generally do not collect your personal data unless (a) it is
          provided to us voluntarily by you directly or via a third party who
          has been duly authorized by you to disclose your personal data to us
          (your &ldquo;</span
        ><span className="c5">authorized representative</span
        ><span className="c6"
          >&rdquo;) after (i) you (or your authorized representative) have been
          notified of the purposes for which the data is collected, and (ii) you
          (or your authorized representative) have provided written consent to
          the collection and usage of your personal data for those purposes, or
          (b) collection and use of personal data without consent is permitted
          or required by the PDPA or other laws. We shall seek your consent
          before collecting any additional personal data and before using your
          personal data for a purpose that has not been notified to you (except
          where permitted or authorized by law).</span
        >
      </li>
      <li className="c4 c11 li-bullet-0">
        <span className="c6"
          >We may collect and use your personal data for any or all of the
          following purposes:</span
        >
      </li>
    </ol>
    <ul className="c0 lst-kix_list_12-0 start">
      <li className="c7 li-bullet-1">
        <span className="c6"
          >performing obligations in the course of or in connection with our
          provision of the goods and/or services requested by you;</span
        >
      </li>
      <li className="c7 li-bullet-1">
        <span className="c6"
          >responding to, handling, and processing queries, requests,
          applications, complaints, and feedback from you;</span
        >
      </li>
      <li className="c7 li-bullet-2">
        <span className="c6"
          >any other purposes for which you have provided the information;</span
        >
      </li>
      <li className="c7 li-bullet-2">
        <span className="c6"
          >transmitting to any unaffiliated third parties including our
          third-party service providers and agents, and relevant governmental
          and/or regulatory authorities, whether in Singapore or abroad, for the
          aforementioned purposes; and</span
        >
      </li>
      <li className="c7 li-bullet-2">
        <span className="c6"
          >any other incidental business purposes related to or in connection
          with the above.</span
        >
      </li>
    </ul>
    <ol className="c0 lst-kix_list_10-0" start="6">
      <li className="c2 li-bullet-0">
        <span className="c6">We may disclose your personal data:</span>
      </li>
    </ol>
    <ul className="c0 lst-kix_list_16-0 start">
      <li className="c20 li-bullet-3">
        <span className="c6"
          >where such disclosure is required for performing obligations in the
          course of or in connection with our provision of the goods and
          services requested by you.</span
        >
      </li>
    </ul>
    <ol className="c0 lst-kix_list_10-0" start="7">
      <li className="c12 c11 li-bullet-0">
        <span className="c6"
          >The purposes listed in the above clauses may continue to apply even
          in situations where your relationship with us (for example, pursuant
          to your employment contract should you be hired) has been terminated
          or altered in any way, for a reasonable period thereafter (including,
          where applicable, a period to enable us to enforce our rights under a
          contract with you).</span
        >
      </li>
    </ol>
    <p className="c4"><span className="c8 c5">WITHDRAWING YOUR CONSENT</span></p>
    <ol className="c0 lst-kix_list_10-0" start="8">
      <li className="c2 li-bullet-0">
        <span className="c6"
          >The consent that you provide for the collection, use, and disclosure
          of your personal data will remain valid until such time it is
          withdrawn by you in writing. You may withdraw consent and request us
          to stop collecting, using, and/or disclosing your personal data for
          any or all of the purposes listed above by submitting your request in
          writing or via email to our Data Protection Officer at the contact
          details provided below.</span
        >
      </li>
      <li className="c2 li-bullet-0">
        <span className="c6"
          >Upon receipt of your written request to withdraw your consent, we may
          require reasonable time (depending on the complexity of the request
          and its impact on our relationship with you) for your request to be
          processed and for us to notify you of the consequences of us acceding
          to the same, including any legal consequences which may affect your
          rights and liabilities to us. In general, we shall seek to process
          your request within ten (10) business days of receiving it.</span
        >
      </li>
      <li className="c2 li-bullet-0">
        <span className="c6"
          >Whilst we respect your decision to withdraw your consent, please note
          that depending on the nature and scope of your request, we may not be
          in a position to continue providing our goods or services to you and
          we shall, in such circumstances, notify you before completing the
          processing of your request. Should you decide to cancel your
          withdrawal of consent, please inform us in writing in the manner
          described in clause 8</span
        >
      </li>
      <li className="c12 c11 li-bullet-0">
        <span className="c6"
          >Please note that withdrawing consent does not affect our right to
          continue to collect, use and disclose personal data where such
          collection, use, and disclosure without consent is permitted or
          required under applicable laws.</span
        >
      </li>
    </ol>
    <p className="c4">
      <span className="c5 c8">ACCESS TO AND CORRECTION OF PERSONAL DATA</span>
    </p>
    <ol className="c0 lst-kix_list_10-0" start="12">
      <li className="c2 li-bullet-0">
        <span className="c6"
          >If you wish to make (a) an access request for access to a copy of the
          personal data which we hold about you or information about the ways in
          which we use or disclose your personal data, or (b) a correction
          request to correct or update any of your personal data which we hold
          about you, you may submit your request in writing or via email to our
          Data Protection Officer at the contact details provided below.</span
        >
      </li>
      <li className="c2 li-bullet-0">
        <span className="c6"
          >Please note that a reasonable fee may be charged for an access
          request. If so, we will inform you of the fee before processing your
          request.</span
        >
      </li>
      <li className="c11 c12 li-bullet-0">
        <span className="c6"
          >We will respond to your request as soon as reasonably possible. In
          general, our response will be within thirty (30) business days. Should
          we not be able to respond to your request within thirty (30) days
          after receiving your request, we will inform you in writing within
          thirty (30) days of the time by which we will be able to respond to
          your request. If we are unable to provide you with any personal data
          or to make a correction requested by you, we shall generally inform
          you of the reasons why we are unable to do so (except where we are not
          required to do so under the PDPA).</span
        >
      </li>
    </ol>
    <p className="c4"><span className="c8 c5">PROTECTION OF PERSONAL DATA</span></p>
    <ol className="c0 lst-kix_list_10-0" start="15">
      <li className="c12 c11 li-bullet-0">
        <span className="c6"
          >To safeguard your personal data from unauthorized access, collection,
          use, disclosure, copying, modification, disposal, or similar risks, we
          have introduced appropriate administrative, physical, and technical
          measures such as encryption.</span
        >
      </li>
      <li className="c12 c11 li-bullet-0">
        <span className="c6"
          >You should be aware, however, that no method of transmission over the
          Internet or method of electronic storage is completely secure. While
          security cannot be guaranteed, we strive to protect the security of
          your information and are constantly reviewing and enhancing our
          information security measures.</span
        >
      </li>
    </ol>
    <p className="c4"><span className="c5 c18">&nbsp;</span></p>
    <p className="c4"><span className="c8 c5">ACCURACY OF PERSONAL DATA</span></p>
    <ol className="c0 lst-kix_list_15-0 start" start="17">
      <li className="c12 c11 li-bullet-0">
        <span className="c6"
          >We generally rely on personal data provided by you (or your
          authorized representative). In order to ensure that your personal data
          is current, complete, and accurate, please update us if there are
          changes to your personal data by informing our Data Protection Officer
          in writing or via email at the contact details provided below.</span
        >
      </li>
    </ol>
    <p className="c4"><span className="c17 c5">&nbsp;</span></p>
    <p className="c4"><span className="c8 c5">RETENTION OF PERSONAL DATA</span></p>
    <ol className="c0 lst-kix_list_14-0 start" start="18">
      <li className="c2 li-bullet-0">
        <span className="c6"
          >We may retain your personal data for as long as it is necessary to
          fulfill the purpose for which it was collected, or as required or
          permitted by applicable laws.</span
        >
      </li>
    </ol>
    <ol className="c0 lst-kix_list_11-0 start" start="19">
      <li className="c12 c11 li-bullet-0">
        <span className="c6"
          >We will cease to retain your personal data or remove the means by
          which the data can be associated with you, as soon as it is reasonable
          to assume that such retention no longer serves the purpose for which
          the personal data was collected, and is no longer necessary for legal
          or business purposes.</span
        >
      </li>
    </ol>
    <p className="c4">
      <span className="c8 c5">TRANSFERS OF PERSONAL DATA OUTSIDE OF SINGAPORE</span>
    </p>
    <ol className="c0 lst-kix_list_7-0 start" start="20">
      <li className="c12 c11 li-bullet-0">
        <span className="c6"
          >We generally do not transfer your personal data to countries outside
          of Singapore. However, if we do so, we will obtain your consent for
          the transfer to be made and we will take steps to ensure that your
          personal data continues to receive a standard of protection that is at
          least comparable to that provided under the PDPA.</span
        >
      </li>
    </ol>
    <p className="c4">
      <span className="c8 c5">REQUEST FOR DELETION OF PERSONAL DATA</span>
    </p>
    <ol className="c0 lst-kix_list_7-0" start="21">
      <li className="c4 c10 li-bullet-0">
        <span className="c1"
          >An individual has the right to erasure, also known as &ldquo;the
          right to be forgotten&rdquo;. The principle underpinning this right is
          to enable an individual to request the deletion or removal of personal
          data where there is no compelling reason for its continued processing.
          To request deletion of your personal data, pls send an email to
          sales@facilitybot.co.</span
        >
      </li>
    </ol>
    <p className="c4"><span className="c8 c5">DATA PROTECTION OFFICER</span></p>
    <ol className="c0 lst-kix_list_7-0" start="22">
      <li className="c4 c11 li-bullet-0">
        <span className="c1"
          >You may contact our Data Protection Officer if you have any inquiries
          or feedback on our personal data protection policies and procedures,
          or if you wish to make any request, in the following manner:</span
        >
      </li>
    </ol>
    <p className="c4">
      <span className="c1">&nbsp; &nbsp; &nbsp; &nbsp;Email Address: admin@</span>
    </p>
    <p className="c4">
      <span className="c8 c5">EFFECT OF NOTICE AND CHANGES TO NOTICE</span>
    </p>
    <ol className="c0 lst-kix_list_7-0" start="23">
      <li className="c4 c10 li-bullet-0">
        <span className="c1"
          >This Notice applies in conjunction with any other notices,
          contractual clauses, and consent clauses that apply in relation to the
          collection, use, and disclosure of your personal data by us.</span
        >
      </li>
      <li className="c4 c10 li-bullet-0">
        <span className="c1"
          >We may revise this Notice from time to time without any prior notice.
          You may determine if any such revision has taken place by referring to
          the date on which this Notice was last updated. Your continued use of
          our services constitutes your acknowledgment and acceptance of such
          changes.</span
        >
      </li>
    </ol>
    <p className="c14"><span className="c3"></span></p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
