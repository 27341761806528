import { Button, Select, Form, Input, message, Tooltip, Space } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { createData, fetchData } from '../../../../store/projectSlice';
import { ProjectTypeEnumTrans, projectTypes } from 'variables/projects';

const ProjectSteps = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const plan = useSelector(state => state.user.user.agent.package.kind)

  const onFinish = (values) => {
    dispatch(createData(values)).then(({error}) => {
      if(error) {
        messageApi.open({
          type: 'error',
          content: error.message,
        });
      } else {
        form.resetFields();
        dispatch(fetchData);
        props.onCallback(values);
      }
    })
  };

  const onFinishFailed = (errorInfo) => {
  };

  return (
    <>
      {contextHolder}
      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 600,
        }}
        initialValues={{
          type: 'chatbot',
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className='my-4'
        form={form}
      >
        <Form.Item
          label="Type"
          name="kind"
          rules={[
            {
              required: true,
              message: 'Please select your project type!',
            },
          ]}
        >
          <Select
            placeholder="Select Project type"
          >
            <Select.Option value={projectTypes.chatbot.value}>{projectTypes.chatbot.label}</Select.Option>
            <Select.Option value={projectTypes.gpt_form.value}>{projectTypes.gpt_form.label}</Select.Option>
            {/* <Select.Option value={projectTypes.image_based_prompt.value}>{projectTypes.image_based_prompt.label}</Select.Option> */}
          </Select>
        </Form.Item>
        <Form.Item
          label="Title"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please input your title!',
            },
          ]}
        >
          <Input placeholder="Title..."/>
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
              message: 'Please input your description!',
            },
          ]}
        >
          <Input.TextArea 
            showCount
            maxLength={255}
            placeholder="Description..."
          />
        </Form.Item>

        <Form.Item
          label="Model"
          name="model"
          rules={[
            {
              required: true,
              message: 'Please select model!',
            },
          ]}
        >
          <Select
            placeholder="Select model"
          >
            <Select.Option value="gpt-3.5-turbo" label="GPT-3.5-turbo">
              <Tooltip placement="leftBottom" title="ChatGPT 3.5 Turbo (Cloud, Fastest)">
                <Space>
                  GPT-3.5-turbo
                </Space>
              </Tooltip>
            </Select.Option>
            <Select.Option value="makergpt" label="MakerGPT" disabled = {plan == 1}>
              <Tooltip placement="leftBottom" title="MakerGPT (Local, data not shared externally)">
                <Space>
                  MakerGPT
                </Space>
              </Tooltip>
            </Select.Option>
            <Select.Option value="gpt-4" label="GPT-4" disabled>
              <Space>
                GPT-4
              </Space>
            </Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit" block>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  )
};
export default ProjectSteps;