import { cardActionsClasses } from "@mui/material";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api_v1 from "plugins/axios";

// Define initial state
const initialState = {
  widget: {},
  loading: false,
  error: null,
};

// Create async thunk for updating data
export const updateData = createAsyncThunk(
  "widgets/updateData",
  async ({id, updatedData}) => {
    try {
      const response = await api_v1.put(
        `/widgets/${id}`,
        updatedData
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message)
    }
  }
);

// Create async thunk for fetching data
export const fetchData = createAsyncThunk("widgets/fetchData", async (token) => {
  try {
    const response = await api_v1.get(`/webchat/${token}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message)
  }
});

const widgetSlice = createSlice({
  name: "widget",
  initialState,
  reducers: {
    updateWidget: (state, action) => {
      state.widget = {...state.widget, ...action.payload}
    },
    setWidget: (state, action) => {
      state.widget = action.payload
    },
  }
});

export const getWidget = (state) => state.widget.widget;

export const { updateWidget, setWidget } = widgetSlice.actions;

export default widgetSlice.reducer;
