import React from "react";
import { SettingOutlined } from "@ant-design/icons";
import { Cascader, InputNumber, Select, Space } from "antd";
const { Option } = Select;

export default function InputSize({
  label,
  defaultUnit = "%",
  defaultValue = 100,
  onSizeChange
}) {
  const [valueSize, setValue] = React.useState(defaultValue);
  const [unit, setUnit] = React.useState(defaultUnit);

  const handleChange = (value) => {
    setValue(value);
    onSizeChange({label, unit, value});
  };

  const handleUnitChange = (value) => {
    setUnit(value);
    onSizeChange({label, unit: value, value: valueSize});
  }

  const selectAfter = (
    <Select value={unit} onChange={handleUnitChange}>
      <Option value="%">%</Option>
      <Option value="px">px</Option>
    </Select>
  );

  return (
    <InputNumber
      addonBefore={label}
      addonAfter={selectAfter}
      value={valueSize}
      className="w-48"
      onChange={handleChange}
    />
  );
}
