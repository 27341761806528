import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse, theme } from 'antd';
import OutputOptions from './OutputOptions';
import VariabilityOptions from './VariabilityOptions';
import WordBiasOptions from './WordBiasOptions';
import CategoryOptions from './CategoryOptions';
import SettingsSwitchGroup from './SettingsSwitchGroup';
import VisibilityRadioGroup from './VisibilityRadioGroup';

const { Panel } = Collapse;
const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;
const AdvancedOptions = ({promptDetail}) => {
  const { token } = theme.useToken();

  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };

  return (
    <Collapse
      bordered={false}
      defaultActiveKey={['output', 'variability', 'word_bias']}
      expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
    >
      {/* <Panel header="Visibility" key="visibility" style={panelStyle}>
        <VisibilityRadioGroup promptDetail={promptDetail}/>
        <SettingsSwitchGroup promptDetail={promptDetail}/>
      </Panel> */}
      {/* <Panel header="Category" key="category" style={panelStyle}>
        <CategoryOptions promptDetail={promptDetail}/>
      </Panel> */}
      <Panel header="Output" key="output" style={panelStyle}>
        <OutputOptions promptDetail={promptDetail}/>
      </Panel>
      <Panel header="Variability" key="variability" style={panelStyle}>
        <VariabilityOptions promptDetail={promptDetail}/>
      </Panel>
      <Panel header="Word bias" key="word_bias" style={panelStyle}>
        <WordBiasOptions promptDetail={promptDetail}/>
      </Panel>
    </Collapse>
  );
};
export default AdvancedOptions;