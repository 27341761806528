import React, { useState, useEffect } from 'react'
import { CopyBlock, arta } from "react-code-blocks";
import { useSelector } from 'react-redux';

export default function CodeBlock({block, language = 'bash'}) {
  const [code, setCode] = useState('')

  const prompt_template = useSelector((state) => state.project.detail.prompt_template);
  const questions = prompt_template.questions.map(obj => ({id: obj.id, input: obj.example}));
  const prompt_token = prompt_template.token;
  const api_key = useSelector((state) => state.user.user.api_key);
  const endpoint = process.env.REACT_APP_BASE_URL;

  const cURLCode = (block) => {
    return `curl --location '${endpoint}/api/v1/public/power_forms' \
--header 'X-Api-Key: ${api_key}' \
--header 'Content-Type: application/json' \
--data '{
    "prompt_token": "${prompt_token}",
    "questions": ${JSON.stringify(questions, null, 4)}
}'`
  }

  const jsCode = (block) => { 
    return `var request = require('request');
var options = {
  'method': 'POST',
  'url': '${endpoint}/api/v1/public/power_forms',
  'headers': {
    'X-Api-Key': '${api_key}',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    "prompt_token": "${prompt_token}",
    "questions": ${JSON.stringify(questions, null, 4)}
  })

};
request(options, function (error, response) {
  if (error) throw new Error(error);
  console.log(response.body);
});`
  }

  const goCode = (block) => {
    return `package main

import (
  "fmt"
  "strings"
  "net/http"
  "io/ioutil"
)

func main() {

  url := "${endpoint}/api/v1/public/power_forms"
  method := "POST"

  payload := strings.NewReader(${'`'}{
    "prompt_token": "${prompt_token}",
    "questions": ${JSON.stringify(questions, null, 4)}
}${'`'})

  client := &http.Client {
  }
  req, err := http.NewRequest(method, url, payload)

  if err != nil {
    fmt.Println(err)
    return
  }
  req.Header.Add("X-Api-Key", "${api_key}")
  req.Header.Add("Content-Type", "application/json")

  res, err := client.Do(req)
  if err != nil {
    fmt.Println(err)
    return
  }
  defer res.Body.Close()

  body, err := ioutil.ReadAll(res.Body)
  if err != nil {
    fmt.Println(err)
    return
  }
  fmt.Println(string(body))
}
`
  }

  const pythonCode = (block) => {
    return `import http.client
import json

conn = http.client.HTTPSConnection("api.makergpt.ai")
payload = json.dumps({
  "prompt_token": "${prompt_token}",
  "questions": ${JSON.stringify(questions, null, 4)}
})
headers = {
  'X-Api-Key': '${api_key}',
  'Content-Type': 'application/json'
}
conn.request("POST", "/api/v1/public/power_forms", payload, headers)
res = conn.getresponse()
data = res.read()
print(data.decode("utf-8"))`
  }

  const rubyCode = (block) => {
    return `require "uri"
require "json"
require "net/http"

url = URI("${endpoint}/api/v1/public/power_forms")

https = Net::HTTP.new(url.host, url.port)
https.use_ssl = true

request = Net::HTTP::Post.new(url)
request["X-Api-Key"] = "${api_key}"
request["Content-Type"] = "application/json"
request.body = JSON.dump({
  "prompt_token": "${prompt_token}",
  "questions": ${JSON.stringify(questions, null, 4)}
})

response = https.request(request)
puts response.read_body`
  }

  const phpCode = (block) => {
    return `<?php
$curl = curl_init();

curl_setopt_array($curl, array(
  CURLOPT_URL => '${endpoint}/api/v1/public/power_forms',
  CURLOPT_RETURNTRANSFER => true,
  CURLOPT_ENCODING => '',
  CURLOPT_MAXREDIRS => 10,
  CURLOPT_TIMEOUT => 0,
  CURLOPT_FOLLOWLOCATION => true,
  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
  CURLOPT_CUSTOMREQUEST => 'POST',
  CURLOPT_POSTFIELDS =>'{
    "prompt_token": "${prompt_token}",
    "questions": ${JSON.stringify(questions, null, 4)}
}',
  CURLOPT_HTTPHEADER => array(
    'X-Api-Key: ${api_key}',
    'Content-Type: application/json'
  ),
));

$response = curl_exec($curl);

curl_close($curl);
echo $response;`
  }

  const rustCode = (block) => {
    return `#[tokio::main]
async fn main() -> Result<(), Box<dyn std::error::Error>> {
    let client = reqwest::Client::builder()
        .build()?;

    let mut headers = reqwest::header::HeaderMap::new();
    headers.insert("X-Api-Key", "${api_key}".parse()?);
    headers.insert("Content-Type", "application/json".parse()?);

    let data = r#"{
    "prompt_token": "${prompt_token}",
    "questions": ${JSON.stringify(questions, null, 4)}
}"#;

    let json: serde_json::Value = serde_json::from_str(&data)?;

    let request = client.request(reqwest::Method::POST, "${endpoint}/api/v1/public/power_forms")
        .headers(headers)
        .json(&json);

    let response = request.send().await?;
    let body = response.text().await?;

    println!("{}", body);

    Ok(())
    }`
  }

  useEffect(() => {
    switch (language) {
      case 'bash':
        setCode(cURLCode(block))
        break;
      case 'javascript':
        setCode(jsCode(block))
        break;
      case 'go':
        setCode(goCode(block))
        break;
      case 'python':
        setCode(pythonCode(block))
        break;
      case 'ruby':
        setCode(rubyCode(block))
        break;
      case 'php':
        setCode(phpCode(block))
        break;
      case 'rust':
        setCode(rustCode(block))
        break;
      default:
        break;
    }
  }, [])

  return (
    <div className='copy-block'>
      <CopyBlock
        text={code}
        startingLineNumber={1}
        showLineNumbers={true}
        theme={arta}
        customStyle={{
          overflowY: 'auto',
          borderRadius: '5px',
          borderTopLeftRadius: '0px',
          fontSize: '0.85rem',
          border: '1px solid #303030'
        }}
        language={language}
      />
    </div>
  )
}
