import React from 'react'
import { Card, Button, Tooltip, Typography } from "antd";
import { CopyOutlined, CheckOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
export default function CopyResponseButton({response}) {
  const [copied, setCopied] = React.useState(false);
  const colorSettings = useSelector((state) => state.prompt.colorSettings);

  const handleClick = () => {
    navigator.clipboard.writeText(response);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  }

  const buttonBackgroundColor = () => {
    if(!colorSettings.background.isGradient) return {backgroundColor: colorSettings.background.background_0}
    else return {backgroundImage: `linear-gradient(159.02deg, ${colorSettings.background.background_1}, ${colorSettings.background.background_2}, ${colorSettings.background.background_3})`}
  }

  return (
    <Tooltip title="Copy response to Clipboard">
      <Button size='large' className="" onClick={handleClick}
         icon={ copied ? <CheckOutlined /> : <CopyOutlined />}
        style={{...buttonBackgroundColor(), borderColor: colorSettings.text, color: colorSettings.text}}
      />
    </Tooltip>
  )
}
