import React, { useEffect, useRef, useState } from "react";
import { Input } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import { updateQuestionById, setHighlightPrompt } from "store/promptSlice";
const { TextArea } = Input;

export default function ShortAnswer({ question }) {
  const inputRef = useRef(null);
  const dispatch = useDispatch()
  const highlightPrompt = useSelector(state => state.prompt.highlightPrompt)
  const [currentHighlightPromptId, setCurrentHighlightPromptId] = useState(null)
  useEffect(() => {
    inputRef.current.focus({cursor: 'end',});
  }, []);

  useEffect(() => {
    if(question.id == highlightPrompt?.id) {
      inputRef.current.focus();
    }
  }, [highlightPrompt]);

  const handleChange = (event) => {
    let payload = {
      id: question.id, 
      question: {example: event.target.value}
    }
    dispatch(updateQuestionById(payload))
    dispatch(setHighlightPrompt({...question, example: event.target.value}))
  }
  return (
    <TextArea
      style={{ height: 80, resize: "none" }}
      value={question.example}
      ref={inputRef}
      onChange={handleChange}
    />
  );
}
