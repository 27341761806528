import React from 'react'
import { Space, Form, Input, Checkbox, ConfigProvider } from 'antd';
import { useSelector } from "react-redux";

export default function MultipleChoiceToolkit({question}) {
  const colorSettings = useSelector((state) => state.prompt.colorSettings);
  
  const renderOptions = () => {
    return JSON.parse(question.secondary).map((option, index) => {
      return (
        <Checkbox value={option.label} key={option.id}>
          <span style={{color: colorSettings.text}}>{option.label}</span>
        </Checkbox>
      )
    })
  }

  return (
    <Form.Item name={question.id}
      label={
        <span style={{color: colorSettings.text}}>
          {question.main}
        </span>
      } 
    >
      <Checkbox.Group>
        <ConfigProvider
          theme={{
            token: {
              "colorPrimary": colorSettings.text,
              "colorBgContainer": "transparent",
            },
          }}
        >
        <Space direction="vertical">
          {renderOptions()}
        </Space>
        </ConfigProvider>
      </Checkbox.Group>
    </Form.Item>
  )
}
