import { Select, message } from "antd";
import { useDispatch } from "react-redux";
import { updatePoweredForm, updatePromptDetail } from "store/promptSlice";

const VISIBILITY_OPTIONS = [
  {
    label: "Public",
    value: 0,
  },
  {
    label: "Private",
    value: 1,
  },
];

export default function VisibilityRadioGroup({ promptDetail }) {
  const dispatch = useDispatch();

  const handleChange = (value) => {
    dispatch(updatePromptDetail({ privacy_setting: value }));
    dispatch(
      updatePoweredForm({
        prompt_id: promptDetail.id,
        payload: { privacy_setting: value },
      })
    )
      .then((response) => {
        if (response.error) {
          message.error(response.error.message);
        }
        if (response.payload) {
          message.success(response.payload.message);
        }
      })
      .catch((err) => message.error(err));
  };

  return (
    <Select
      size="large"
      value={promptDetail.privacy_setting}
      className="capitalize mt-4 xl:w-[85%] w-[75%] mr-auto border-none"
      onChange={handleChange}
      options={VISIBILITY_OPTIONS}
    />
  );
}
