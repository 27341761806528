import React, { useEffect, useRef, useState } from "react";
import { Col, Divider, Input, Empty, Button, message, Card, Tooltip } from "antd";
import PromptToolkit from "../PromptToolkit";
import VuiTypography from "components/VuiTypography";
import { useDispatch } from 'react-redux';
import { updatePromptDetail, updatePoweredForm, testPrompt, addTextToResponsePrompt, setResponsePrompt, setReceiveResponse } from "store/promptSlice";
import { fetchDetailData } from "store/projectSlice";
import { useSelector } from "react-redux";
import {Card as MuiCard} from "@mui/material";
import io from "socket.io-client";
import "./FormBuilder.css";
import { fetchUserProfile } from "store/userSlice";
import { v4 as uuidv4 } from "uuid";
import {UndoOutlined} from '@ant-design/icons';
import dumpData from './dumpData.js'
export default function FormBuilder({ promptDetail }) {
  const dispatch = useDispatch()
  const uuid = uuidv4();
  const responsePrompt = useSelector((state) => state.prompt.responsePrompt);
  const projectDetail = useSelector((state) => state.project.detail);
  const [isLoadingTestPrompt, setIsLoadingTestPrompt] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isInit, setIsInit] = useState(true)
  const [titleError, setTitleError] = useState('')
  const socket = useRef(null);

  useEffect(() => {
    // resolve the websocket connection when first loaded
    socket.current = io(process.env.REACT_APP_BASE_URL, {
      path: process.env.REACT_APP_SOCKET_PATH,
      transports: ["websocket"],
    });

    // Receiving messages from the server
    socket.current.on("receive_test_prompt", (data) => {
      if (data === null) return
      dispatch(addTextToResponsePrompt(data))
    });

    // Receiving error from the server
    socket.current.on("exception", (data) => {
      setIsLoadingTestPrompt(false)
      message.error(data.message)
      setIsInit(true)
    });

    // Receiving end-messages from the server
    socket.current.on("end_test_prompt", (data) => {
      setIsLoadingTestPrompt(false)
      dispatch(setReceiveResponse(false))
      if (data === null && data.status !== 200) return
      dispatch(fetchUserProfile())
    });

    //Disconnect socket when leaving the page
    return () => socket.current.disconnect();
  }, []);

  useEffect(() => {
    if(promptDetail && Object.keys(promptDetail).length > 0
      && !promptDetail.title && !promptDetail.description
    ) {
      dispatch(updatePromptDetail({
        title: projectDetail.name,
        description: projectDetail.description
      }))
    }
   }, [promptDetail])

  const renderQuestions = promptDetail?.questions?.map((question, index) => (
    <PromptToolkit question={question} key={question.id} />
  ));

  const handleChange = (e, key) => {
    dispatch(updatePromptDetail({[key]: e.target.value}))
  }

  const handleCheckError = (e) => { 
    if(e.target.value.length > 1) setTitleError('')
    else setTitleError('error')
  }

  const handleUpdateClick = () => {
    const filteredTags = promptDetail.tags.map(tag => tag.name ? tag.name : tag)
    const filteredPromptDetail = {...promptDetail, tags: filteredTags}
    
    setIsSubmitting(true)
    dispatch(updatePoweredForm({
      prompt_id: promptDetail.id,
      payload: filteredPromptDetail
    })).then(({error}) => {
      setIsSubmitting(false)
      if(error) { 
        message.error(error.message)
      } else {
        dispatch(fetchDetailData(projectDetail.id))
        message.success('Updated Successfully')
      }
    })
  }

  const handleTestPrompt = () => {
    setIsInit(false)
    setIsLoadingTestPrompt(true)
    dispatch(setResponsePrompt(''))
    socket.current.emit("stream_test_prompt", {...promptDetail});
    dispatch(fetchUserProfile())
    dispatch(setReceiveResponse(true))
  }

  const renderResponseTitle = () => {
    return (
      <div className="flex justify-between">
        Response
        <div>
          <Tooltip title="Clear Response">
            <Button type="dashed" icon={<UndoOutlined />} className='ml-2'
              disabled={isLoadingTestPrompt}
              onClick={() => dispatch(setResponsePrompt(dumpData.initData.responsePrompt))}
            />
          </Tooltip>
        </div>
      </div>
    )
  }

  return (
    <Col span={12}>
      <MuiCard>
        <VuiTypography variant="h2" color="white" mb={2}>
          Form Builder
        </VuiTypography>
        <div>
          <Input
            size="large"
            placeholder="Title"
            value={promptDetail.title}
            onChange={(e) => {handleChange(e, 'title'); handleCheckError(e)}}
            status={titleError}
          />
          <div className="my-4"></div>
          <Input
            placeholder="Describe your prompt to users [Optional]"
            value={promptDetail.description} 
            onChange={(e) => handleChange(e, 'description')}
          />
        </div>
        <Divider className="my-2"/>
        { !promptDetail.questions || promptDetail.questions.length <= 0 ? 
          <Empty description="No user inputs yet." /> :
          renderQuestions
        }
        <Divider className="my-2"/>
        { !isInit &&
          <Card
            title={renderResponseTitle()}
            className="my-2"
          >
            <span className="whitespace-pre-wrap">
              {responsePrompt}
            </span>
          </Card>
        }
        <div className="submit-prompt-container">
          <Button type="primary" size="large" onClick={handleTestPrompt} loading={isLoadingTestPrompt}>🚀 Generate</Button>
          <Button type="primary" size="large" onClick={handleUpdateClick} loading={isSubmitting}>Update</Button>
        </div>
      </MuiCard>
    </Col>
  );
}