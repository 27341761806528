import React, { useEffect } from 'react'
import PromptFrame from './PromptFrame'
import FormBuilder from './FormBuilder'
import { Col, Row } from 'antd';
import { Divider } from 'antd';
import { getPromptDetail } from "store/promptSlice";
import { setPromptDetail } from 'store/promptSlice';
import { resetPromptDetail } from 'store/promptSlice';
import { useDispatch, useSelector } from 'react-redux';

export default function PromptBuilder() {
  const promptDetail = useSelector(getPromptDetail)

  return (
    <Row className='mt-4'>
      <PromptFrame promptDetail={promptDetail}/>
      <Col span={1} className='text-center'>
        <Divider type="vertical" className='h-screen'/>
      </Col>
      <FormBuilder promptDetail={promptDetail}/>
    </Row>
  )
}
