import { Input, Radio, Space, Button, Typography, Checkbox } from "antd";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Card from "@mui/material/Card";
import { Filter } from "@mui/icons-material";
import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { updateQuestionById, setHighlightPrompt } from "store/promptSlice";
const { Paragraph } = Typography;

const MultipleChoiceAnswer = ({question}) => {
  const dispatch = useDispatch()
  const [options, setOptions] = useState([
    { label: "Option 1", id: uuidv4(), checked: false },
    { label: "Option 2", id: uuidv4(), checked: false },
  ]);

  useEffect(() => {
    let payload = {
      id: question.id, 
      question: {secondary: JSON.stringify(options)}
    }
    dispatch(updateQuestionById(payload))
  }, [options])

  const onChange = (e, option) => {
    let _options = options
    const index = _options.findIndex(q => q.id === option.id)
    _options[index] = {..._options[index], checked: e.target.checked}
    setOptions(_options)

    let payload = {
      id: question.id, 
      question: {example: _options.filter(e => e.checked).map(e => e.label).join(', ')}
    }
    dispatch(updateQuestionById(payload))
    dispatch(setHighlightPrompt({...question, example: _options.filter(e => e.checked).map(e => e.label).join(', ')}))
  };

  const handleAddClick = () => {
    setOptions([
      ...options,
      { label: `Option ${options.length + 1}`, id: uuidv4(), checked: false },
    ]);
  };

  const handleDeleteClick = (id) => {
    return () => {
      setOptions(options.filter((option) => option.id !== id));
    };
  };

  const handleUpdateClick = (id, value) => {
    setOptions(
      options.map((option) => {
        if (option.id === id) {
          return { ...option, label: value };
        }
        return option;
      })
    );
  };

  const renderOptions = () => {
    return options.map((option, index) => {
      return (
        <Checkbox key={option.id} onChange={(e) => onChange(e, option)}>
          <div className="flex gap-2">
            <Paragraph
              editable={{
                onChange: (str) => handleUpdateClick(option.id, str),
                icon: <EditOutlined className='ml-3 text-white' />
              }}
              className="mb-0 ml-2"
            >
              {option.label}
            </Paragraph>
            <a
              className="text-rose-600"
              onClick={handleDeleteClick(option.id)}
            >
              <DeleteOutlined />
            </a>
          </div>
        </Checkbox>
      );
    });
  };
  return (
    <Card>
      <div className="flex flex-col">
        <Space direction="vertical">{renderOptions()}</Space>
        <a className="mt-3 text-white/80 hover:text-white" onClick={handleAddClick}>+ Add Option</a>
      </div>
    </Card>
  );
};
export default MultipleChoiceAnswer;
