import { Button, message, Popconfirm } from 'antd';
import { DeleteFilled } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { deleteFile } from "store/projectSlice";

const DeleteFile = ({file}) => {
  const dispatch = useDispatch();
  const confirm = (e) => {
    dispatch(deleteFile({file_embedding_id: file.id})).then((res) => {
      if(res.payload === undefined) {
        message.error(res.error.message);
      } else if (res.payload.statusCode === 200){
        message.success("File deleted successfully");
      } else {
        message.error("File deleted failed")
      }
    })
  };
  const cancel = (e) => {
  };

  return (<Popconfirm
    title="Delete the file"
    description="Are you sure to delete this file?"
    onConfirm={confirm}
    onCancel={cancel}
    okText="Yes"
    cancelText="No"
  >
    <Button type="link" danger icon={<DeleteFilled />}>
      Delete
    </Button>
  </Popconfirm>)
};
export default DeleteFile;