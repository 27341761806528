import { Space, Input, Select } from 'antd'
import React, { useState } from 'react'
import ShortAnswer from './ShortAnswer'
import DateTimeAnswer from './DateTimeAnswer'
import SingleChoiceAnswer from './SingleChoiceAnswer'
import MultipleChoiceAnswer from './MultipleChoiceAnswer'
import { useDispatch } from 'react-redux';
import { updateQuestionById, setHighlightPrompt } from "store/promptSlice";

export default function PromptToolkit({question}) {
  const dispatch = useDispatch()
  const [optionType, setOptionType] = useState(question.type || 'text')
  
  const handleChange = (value) => {
    setOptionType(value)
    dispatch(updateQuestionById({id: question.id, question: {type: value, example: 'User Input'}}))
    dispatch(setHighlightPrompt({...question, type: value, example: 'User Input'}))
  }

  const handleMainChange = (e) => { 
    dispatch(updateQuestionById({id: question.id, question: {main: e.target.value}}))
  }

  const renderOption = () => { 
    switch (optionType) {
      case 'text':
        return <ShortAnswer question={question} />
      case 'date_time':
        return <DateTimeAnswer question={question} />
      case 'single_choice':
        return <SingleChoiceAnswer question={question} />
      case 'multiple_choices':
        return <MultipleChoiceAnswer question={question} />
      default:
        return <ShortAnswer question={question} />
    }
  }

  return (
    <div className='my-4 question-builder-item'>
      <Space.Compact block className='mb-1'>
        <Input 
          placeholder="Untitled question/instruction" 
          value={question.main}
          onChange={handleMainChange}
        />
        <Select
          value={optionType}
          style={{
            width: 200,
          }}
          options={[
            {
              value: 'text',
              label: 'Free Text',
            },
            {
              value: 'date_time',
              label: 'Date & Time',
            },
            {
              value: 'single_choice',
              label: 'Single Choice',
            },
            {
              value: 'multiple_choices',
              label: 'Multiple Choices',
            }
          ]}
          onChange={handleChange}
        />
      </Space.Compact>
      { renderOption() }
    </div>
  )
}
