import { Button, Form, Input, message } from 'antd';
import { useDispatch } from 'react-redux';
import { updateData } from '../../../../store/projectSlice';

const UpdateProjectSteps = (props) => {
  const dispatch = useDispatch();

  const updateProject = (values) => {
    const { name, description } = values; 
    dispatch(updateData({ id: props.id, kind: props.kind, name, description })).then(response => {
      if (response?.payload?.data) {
        message.success(response.payload.message)
        props.handleCancel() 
      } else {
        message.error(response.error.message)
        props.handleCancel() 
      }
    })
  };

  const onFinishFailed = (errorInfo) => {
  };

  return (
    <>
      <Form
        initialValues={{ name: props.title, description: props.description }}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 600,
        }}
        onFinish={updateProject}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className='my-4'
      >
        <Form.Item
          label="Title"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please input your title!',
            },
          ]}
        >
          <Input placeholder="Title..."/>
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
              message: 'Please input your description!',
            },
          ]}
        >
          <Input.TextArea showCount maxLength={255} placeholder="Description..."/>
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button className='mt-4' type="primary" htmlType="submit" block>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  )
};
export default UpdateProjectSteps;