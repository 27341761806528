import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { mapPaginationFormat } from "helpers/utils";
import api_v1 from "plugins/axios";

// Define initial state
const initialState = {
  usages: [],
  loading: false,
  error: null,
  paginator: [],
};

// Create async thunk for fetching data
export const fetchUsages = createAsyncThunk("usage_store/fetchUsages", async (paginator) => {
  paginator = mapPaginationFormat({ paginator: paginator });
  try {
    const response = await api_v1.get("/usage", { params: paginator });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
});

// Create Redux slice
const usageSlice = createSlice({
  name: "usage_store",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsages.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUsages.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.paginator = action.payload.paginator;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchUsages.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {} = usageSlice.actions;
export default usageSlice.reducer;
