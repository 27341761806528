

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import linearGradient from "assets/theme/functions/linearGradient";
import colors from "assets/theme/base/colors";
import DeleteFile from "./DeleteFile";
import StatusFile from "./StatusFile";
import PreviewFile from "./PreviewFile";
import TrainedContent from "./TrainedContent";
import { FILE_KINDS } from "variables/fileKinds";
import { NOT_UPLOAD_TYPES } from "variables/fileKinds";
import ReadMoreText from "examples/Buttons/ReadMoreText";
import { timestampFormat } from "helpers/times";
import { UPLOAD_TYPES } from "variables/fileKinds";
import { useEffect, useState } from "react";
import { Tooltip } from 'antd';

function File({ file, project }) {
  const { gradients } = colors;
  const { bill } = gradients;
  const [url, setUrl] = useState(null);

  const convertToMegaBytes = (number) => {
    const bytes = Number(number);
    const megabytes = (bytes / 1000000).toFixed(2);
    return megabytes + " MB";
  }

  useEffect(() => {
    if (file.kind === FILE_KINDS.WEBSITE_URL) {
      setUrl(file.file_url || file.meta_data.url);
    }
    if (UPLOAD_TYPES.includes(file.kind)) {
      setUrl(file.meta_data.originalname);
    }
  }, [file.kind]);


  return (
    <VuiBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      sx={{ background: linearGradient(bill.main, bill.state, bill.deg) }}
      borderRadius="lg"
      p="24px"
      mb="12px"
      mt="10px"
    >
      <VuiBox width="100%" display="flex" flexDirection="column">
        <VuiBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mb="5px"
        >
          <VuiTypography
            variant="button"
            color="white"
            fontWeight="medium"
            textTransform="none"
          >
            <StatusFile status={file.status_text}/>
            {file.meta_data.file_name}
          </VuiTypography>
        </VuiBox>
        <VuiBox mb={1} lineHeight={0}>
          <VuiTypography variant="caption" color="text">
            File Type:&nbsp;&nbsp;&nbsp;
            <VuiTypography
              variant="caption"
              color="text"
              fontWeight="regular"
              textTransform="uppercase"
            >
              {file.kind_text}
            </VuiTypography>
          </VuiTypography>
        </VuiBox>
        {/* <VuiBox mb={1} lineHeight={0}>
          <VuiTypography variant="caption" color="text">
            File Size:&nbsp;&nbsp;&nbsp;
            <VuiTypography variant="caption" fontWeight="regular" color="text">
              {convertToMegaBytes(file.meta_data.file_size)}
            </VuiTypography>
          </VuiTypography>
        </VuiBox> */}
        <VuiTypography mb={1} lineHeight={0} variant="caption" color="text">
          Page Count:&nbsp;&nbsp;&nbsp;
          <VuiTypography variant="caption" fontWeight="regular" color="text">
            {file.kind === 2? file.meta_data.pdf.totalPages:1}
          </VuiTypography>
        </VuiTypography>

        <VuiTypography variant="caption" color="text">
          Timestamp:&nbsp;&nbsp;&nbsp;
          <VuiTypography variant="caption" fontWeight="regular" color="text">
            {timestampFormat(file.created_at)}
          </VuiTypography>
        </VuiTypography>

        <Content file={file}/>

        {url && (
          <VuiTypography mt={1} variant="caption" color="text">
            {file.kind === FILE_KINDS.WEBSITE_URL ? "Url" : "FileName"}:&nbsp;&nbsp;&nbsp;
            <VuiTypography className={file.kind === FILE_KINDS.WEBSITE_URL && "underline"} variant="caption" fontWeight="regular" color="text">
              {url}
            </VuiTypography>
          </VuiTypography>
        )}
      </VuiBox>
      <VuiBox
        display="flex"
        alignItems="flex-start"
        flexDirection= "column"
        mt={{ xs: 2, sm: 0 }}
        ml={{ xs: -1.5, sm: 0 }}
      >
        <VuiBox mr={1}>
          <DeleteFile file={file} />
        </VuiBox>
        <Tooltip placement="leftBottom" title="We may not be able to extract all text from a website or file (for example, if there are text within images). We show the text that we were able to extract here">
          <VuiBox mr={1}>
            <TrainedContent file={file}/>
          </VuiBox>
        </Tooltip>
        <Tooltip placement="leftBottom" title="View the site or file used to train the model">
          <VuiBox mr={1}>
            <PreviewFile file={file}/>
          </VuiBox>
        </Tooltip>
      </VuiBox>
    </VuiBox>
  );
}

function Content({ file }) {
  if (file?.kind === FILE_KINDS.INPUT_TEXT) {
    return (
      <VuiBox mt={1} lineHeight={0}>
        <VuiTypography className="flex" variant="caption" color="text">
          <ReadMoreText text={`Content:  ${file?.content}`} />
        </VuiTypography>
      </VuiBox>
    )
  }
  if (file?.kind === FILE_KINDS.FAQ) {
    return (
      <>
        <VuiBox mt={1} lineHeight={0}>
          <VuiTypography className="flex" variant="caption" color="text">
            <ReadMoreText text={`Question:  ${JSON.parse(file?.content || "{}")?.question}`} />
          </VuiTypography>
        </VuiBox>
        <VuiBox mt={1} lineHeight={0}>
          <VuiTypography className="flex" variant="caption" color="text">
            <ReadMoreText text={`Answer:  ${JSON.parse(file?.content || "{}")?.answer}`} />
          </VuiTypography>
        </VuiBox>
      </>
    );
  }

  return null
}

// Setting default values for the props of File
File.defaultProps = {
  noGutter: false,
};

// Typechecking props for the File
File.propTypes = {
  file: PropTypes.object.isRequired,
};

export default File;
