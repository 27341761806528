import React from 'react'
import { Button, Input, Select, Space, message } from 'antd';
import "./InputCopy.css";
export default function InputCopy({link, type}) {
  const handleCopy = () => { 
    navigator.clipboard.writeText(link)
    .then(() => {
      message.success(`${type} copied to clipboard.`);
    })
  }

  return (
    <div className='w-full input-copy flex'>
      <Input defaultValue={link} size="large" className="xl:w-[85%] w-[75%] mr-auto border-none" disabled/>
      <Button type="primary" size="large" className="xl:w-[10%] w-[20%] ml-auto" onClick={handleCopy} >Copy {type}</Button>
    </div>
  )
}

InputCopy.defaultProps = {
  type: "Script"
}
