import { useState } from "react";
import { message, Input, Form, Button } from "antd";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie"

// slice
import { authenticatorSignup } from "store/authenticatorSlice";
import { userSuccessfullyValidated } from "store/userSlice";

// react-router-dom components
import { Link, useHistory } from "react-router-dom";

// @mui material components
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
// Icons
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";

// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import rgba from "assets/theme/functions/rgba";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgSignIn from "assets/images/signUpImage.png";
import { useGoogleLogin } from "@react-oauth/google";
import { authenticatorSigninGoogle } from "store/authenticatorSlice";
import { GoogleOutlined } from "@ant-design/icons";

function SignIn() {
  //State, useEffect, variable
  const dispatch = useDispatch();
  const history = useHistory();
  //For loading animation
  const [loading, setLoading] = useState(false);

  //---------------------UI-----------------------

  //input styles
  const inputStyle = {
    background: "none",
    backgroundColor: "none",
    border: "0.5px solid #e5e7eb",
    borderRadius: "7px",
  };
  //button styles
  const buttonStyle = {
    width: "100%",
  };

  //-----------------Data handle------------------

  //submit form and create a new account
  const onFinish = async (data) => {
    setLoading(true);
    try {
      let res = await dispatch(
        authenticatorSignup({
          name: data.name,
          email: data.email,
          password: data.password,
        })
      );
      if (res.payload.statusCode === 200) {
        setLoading(false);
        //Save token to the cookie
        Cookies.set("authToken", res.payload.data.access_token);
        dispatch(userSuccessfullyValidated(res.payload.data));
        //Navigate to the main page
        history.push("/projects");
        //Other code
      } else {
        //Feedback the failure result to the client
        message.error(res.payload.message);
        setLoading(false);
      }
    } catch (err) {
      message.error("Something went wrong, please try again")
      setLoading(false);
    }
  };

  const signinWithGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const res = await dispatch(authenticatorSigninGoogle({code: tokenResponse.code}))
      //Code success 200
      if (res.payload.statusCode === 200) {
        //Save token to the cookie
        Cookies.set("authToken", res.payload.data.access_token);
        dispatch(userSuccessfullyValidated(res.payload.data));
        //Navigate to the main page
        history.push("/projects");
        //Other code
      } else {
        //Feedback the failure result to the client
        message.error(res.payload.message);
        setLoading(false);
      }
    },
    onError: (err) => message.error(err),
    flow: "auth-code",
  });

  return (
    <CoverLayout
      title="Welcome!"
      color="white"
      description="Sign up to make something awesome"
      image={bgSignIn}
      premotto="INSPIRED BY THE FUTURE:"
      motto="THE MAKERGPT PLATFORM"
      cardContent
    >
      <GradientBorder borderRadius={borders.borderRadius.form} minWidth="100%" maxWidth="100%">
        <VuiBox
          borderRadius="inherit"
          p="45px"
          sx={({ palette: { secondary } }) => ({
            backgroundColor: secondary.focus,
          })}
        >
          <Form component="form" role="form" onFinish={onFinish}>
            <VuiTypography
              color="white"
              fontWeight="bold"
              textAlign="center"
              mb="24px"
              sx={({ typography: { size } }) => ({
                fontSize: size.lg,
              })}
            >
              Register
            </VuiTypography>
            <Stack
              mb="25px"
              justifyContent="center"
              alignItems="center"
              direction="row"
              spacing={2}
            >
              <Button
                onClick={() => signinWithGoogle()}
                type="text"
                className="rounded-[50px] border-t-[0.25px] border-b-[0.25px] border-l-[0.25px] border-r-[0.25px] border-slate-400 py-[8px] text-[15px] h-fit w-full"
                icon={<GoogleOutlined />}
              >
                Sign up with Google
              </Button>
            </Stack>

            <VuiTypography
              color="text"
              fontWeight="bold"
              textAlign="center"
              mb="14px"
              sx={({ typography: { size } }) => ({ fontSize: size.lg })}
            >
              or
            </VuiTypography>
            <VuiTypography
              color="text"
              textAlign="center"
              mb="16px"
              sx={({ typography: { size } }) => ({ fontSize: size.sm })}
            >
              Enter your name, email and password to sign up
            </VuiTypography>
            <VuiBox mb={2}>
              <VuiBox mb={1} ml={0.5}>
                <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                  Name
                </VuiTypography>
              </VuiBox>
              <Form.Item name="name" rules={[{ required: true, message: "A name is required" }]}>
                <Input type="text" placeholder="Your name..." fontWeight="500" style={inputStyle} />
              </Form.Item>
            </VuiBox>
            <VuiBox mb={2}>
              <VuiBox mb={1} ml={0.5}>
                <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                  Email
                </VuiTypography>
              </VuiBox>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "An email is required" },
                  { type: "email", message: "This is not a valid E-mail!" },
                ]}
              >
                <Input
                  type="email"
                  placeholder="Your email..."
                  fontWeight="500"
                  style={inputStyle}
                />
              </Form.Item>
            </VuiBox>
            <VuiBox mb={2}>
              <VuiBox mb={1} ml={0.5}>
                <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                  Password
                </VuiTypography>
              </VuiBox>
              <Form.Item
                name="password"
                rules={[{ required: true, message: "A password is required" }]}
              >
                <Input
                  type="password"
                  placeholder="Your password..."
                  fontWeight="500"
                  style={inputStyle}
                />
              </Form.Item>
            </VuiBox>
            <VuiBox className="space-x-1">
              <VuiTypography
                color="white"
                textAlign="center"
                mb="24px"
                sx={({ typography: { size } }) => ({
                  fontSize: "11px",
                })}
                className="space-x-1"
              >
                <span>By signing up, you agree to our Link</span>
                <Link to="/privacy-policy">
                  Privacy Policy
                </Link>
                <span>and</span>
                <Link to="/terms-and-conditions">
                  Terms and Conditions
                </Link>
              </VuiTypography>
            </VuiBox>
            <VuiBox mt={4} mb={1}>
              <Form.Item>
                <Button loading={loading} type="primary" style={buttonStyle} htmlType="submit">
                  SIGN UP
                </Button>
              </Form.Item>
            </VuiBox>
            <VuiBox mt={3} textAlign="center">
              <VuiTypography variant="button" color="text" fontWeight="regular">
                Already have an account?{" "}
                <VuiTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="white"
                  fontWeight="medium"
                >
                  Sign in
                </VuiTypography>
              </VuiTypography>
            </VuiBox>
          </Form>
        </VuiBox>
      </GradientBorder>
    </CoverLayout>
  );
}

export default SignIn;
