import React, { useState, useEffect } from 'react'
import VuiInput from "components/VuiInput";
import { setSearchQuery } from 'store/projectSlice';
import { useSelector, useDispatch } from 'react-redux';
export default function SearchProject() {
  const [inputValue, setInputValue] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      dispatch(setSearchQuery(inputValue));
    }, 500);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [inputValue]);


  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <VuiInput
      placeholder="Type here..."
      icon={{ component: "search", direction: "left" }}
      sx={({ breakpoints }) => ({
        [breakpoints.down("sm")]: {
          maxWidth: "80px",
        },
        [breakpoints.only("sm")]: {
          maxWidth: "80px",
        },
        backgroundColor: "info.main !important",
      })}
      onChange={handleInputChange}
    />
  )
}
