import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Header from "layouts/prompt/components/Header";
import PromptBuilder from "./components/PromptBuilder";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function Prompt() {
  return (
    <DashboardLayout>
      <DashboardNavbar light/>
      {/*<Header/>*/}
      <PromptBuilder />
    </DashboardLayout>
  );
}

export default Prompt;
