import { configureStore } from '@reduxjs/toolkit';
import counterReducer from './counterSlice';
import projectReducer from './projectSlice';
import authenticatorReducer from './authenticatorSlice';
import userReducer from './userSlice'
import widgetReducer from './widgetSlice'
import promptReducer from './promptSlice'
import powerFormReducer from './powerFormSlice'
import messageReducer from './messageSlice'
import usageReducer from './usageSlice'
import orderReducer from './orderSlice'
import tagReducer from './tagSlice'
import galleryReducer from './gallerySlice'

export default configureStore({
  reducer: {
    authenticator: authenticatorReducer,
    counter: counterReducer, 
    project: projectReducer,
    user: userReducer,
    widget: widgetReducer,
    message: messageReducer,
    prompt: promptReducer,
    usage: usageReducer,
    powerForm: powerFormReducer,
    order: orderReducer,
    tag: tagReducer,
    gallery: galleryReducer,
  },
});