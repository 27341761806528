import useOnUpdateEffect from 'hooks/useOnUpdateEffect';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const OFFSET = 200 // 10px
// Custom hook for pagination and infinite scrolling
const useInfiniteScroll = ({paginatorData, fetchDataAction, fetchMoreDataAction, setPageAction}) => {
  const { data, paginator, page, searchQuery } = paginatorData;
  const [loadingNextMessage, setLoadingNextMessage] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch initial data
    dispatch(fetchDataAction(paginatorData?.queryParams)).then(() => {
      dispatch(setPageAction(2)) 
      setLoadingNextMessage(false);
    });
  }, [dispatch]);

  useEffect(() => {
    // Infinite scrolling event
    const handleScroll = () => {
      if (document.documentElement.scrollHeight - document.documentElement.scrollTop <= document.documentElement.clientHeight + OFFSET) {
        setLoadingNextMessage(true);
      }
    };

    // Adding infinite scrolling event
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    // Fetch more data on infinite scrolling
    const fetchDataOnScroll = async () => {
      if (loadingNextMessage && paginator.hasNextPage && document.documentElement.scrollTop !== null) {
        setIsFetchingData(true);
        await dispatch(fetchMoreDataAction({
          ...paginatorData?.queryParams,
          q: searchQuery,
          page: page,
          limit: 10,
        }));
        setIsFetchingData(false);
        setLoadingNextMessage(false);
      }
    };

    fetchDataOnScroll();
  }, [loadingNextMessage]);

  useOnUpdateEffect(() => {
    // Fetch data when searchQuery changes
    dispatch(fetchDataAction({
      page: 1,
      q: searchQuery,
    }));
    setLoadingNextMessage(false);
    dispatch(setPageAction(2));
  }, [searchQuery, dispatch]);

  return {
    isFetchingData,
    setLoadingNextMessage
  };
};

export default useInfiniteScroll;
