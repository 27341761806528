

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Menu from "@mui/material/Menu";
// import Grid from "@mui/material/Grid";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";

// Vision UI Dashboard React example components
import DefaultNavbarLink from "examples/Navbars/DefaultNavbar/DefaultNavbarLink";
import palette from "assets/theme/base/colors";
import tripleLinearGradient from "assets/theme/functions/tripleLinearGradient";
import { useSelector } from "react-redux";

function DefaultNavbarMobile({ open, close }) {
  const { width } = open && open.getBoundingClientRect();
  const colorSettings = useSelector((state) => state.prompt.colorSettings);
  const user = useSelector((state) => state.user.user);
  
  const buttonBackgroundColor = () => {
    if(!colorSettings.background.isGradient) return {backgroundColor: colorSettings.background.background_0}
    else return {backgroundImage: `linear-gradient(159.02deg, ${colorSettings.background.background_1}, ${colorSettings.background.background_2}, ${colorSettings.background.background_3})`}
  }

  return (
    <Menu
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      anchorEl={open}
      open={Boolean(open)}
      onClose={close}
      sx={{
        marginTop: "8px",
      }}
      MenuListProps={{
        style: {
          width: `calc(${width}px - 4rem)`,
          ...buttonBackgroundColor(),
          padding: "8px",
        },
      }}
    >
      <VuiBox px={0.5}>
        <DefaultNavbarLink icon="donut_large" name="Gallery" route="/gallery" />
        { user ? 
          <DefaultNavbarLink icon="account_circle" name={user.name} route="/projects" textColor={colorSettings.text}/> :
          <>
            <DefaultNavbarLink icon="account_circle" name="sign up" route="/sign-up" textColor={colorSettings.text}/>
            <DefaultNavbarLink icon="key" name="sign in" route="/sign-in" textColor={colorSettings.text}/>
          </>
        }
      </VuiBox>
    </Menu>
  );
}

// Typechecking props for the DefaultNavbarMenu
DefaultNavbarMobile.propTypes = {
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  close: PropTypes.oneOfType([PropTypes.func, PropTypes.bool, PropTypes.object]).isRequired,
};

export default DefaultNavbarMobile;
