import { Button, Form, Input, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useDispatch } from "react-redux";
import { embeddingInputText } from "store/projectSlice";
import { fetchDetailData } from "store/projectSlice";

export default function InputText({ project }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    const { input_text } = values;

    dispatch(embeddingInputText({ projectId: project.id, inputText: input_text })).then(
      ({ error, payload }) => {
        if (error) {
          message.error(error.message);
        }
        if (payload) {
          message.success(payload.message);
          dispatch(fetchDetailData(project.id)); // re-fetch data for project.fileEmbeddings's state
          form.resetFields();
        }
      }
    );
  };

  return (
    <>
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item name="input_text">
          <TextArea rows={5} placeholder="Train the model by entering text" />
        </Form.Item>
        <Button className="flex-1" type="primary" htmlType="submit">
          Add
        </Button>
      </Form>
    </>
  );
}
