import React, { useState } from "react";
import { Card, SliderValueLabel } from "@mui/material";
import VuiTypography from "components/VuiTypography/index";
import VuiBox from "components/VuiBox/index";
import { CheckCircleFilled, CreditCardFilled } from "@ant-design/icons";
import welcome from "assets/images/welcome-profile.png";
import { Button, Popconfirm, Form, Input, message, Row, Spin, Select } from "antd";
import "./index.css";

import { useDispatch, useSelector } from "react-redux";
import { createCheckoutSession, cancelSubscription } from 'store/orderSlice';
import {fetchUserProfile} from 'store/userSlice'
import {planTypes} from "variables/plans"
const { Option } = Select;
const Tier = ({ tier }) => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.user);
  const [form] = Form.useForm();
  const [displayModal, setDisplayModal] = useState(false);
  const [benefit, setBenefit] = useState(tier.description?.split('|'))
  const [isLoadingStripe, setIsLoadingStripe] = useState(true)
  const [stripePromise, setStripePromise] = useState(null)
  const isCurrentPlan = profile.agent.package.kind === tier.kind

  //Submit the form
  const onFinish = (data) => {
    form.resetFields();
    setDisplayModal(false);
    message.success("Your information has been saved");
  };

  const handleSubscribe = () => { 
    dispatch(createCheckoutSession(tier.id)).then(({payload, error}) => {
      if(error) {
        message.error(error.message)
      } else { 
        window.open(payload.data.data.url, '_self')
      }
    })
  }

  const handleUnsubscribe = () => {
    dispatch(cancelSubscription()).then((res) => {
      dispatch(fetchUserProfile())
    })
  }

  const renderSubscribeButton = () => {
    if(tier.kind === planTypes.free_trail.value) {
      if(isCurrentPlan)
        return (
          <Button
            className="rounded-[50px] border-2 border-white bg-none bg-transparent font-bold absolute left-7 bottom-10"
            disabled={true}
          >
            Current Plan
          </Button>
        )
    } else {
      if(isCurrentPlan) 
        return (
          <Popconfirm
            title="Unsubscribe"
            description="Are you sure to unsubscribe this plan?"
            onConfirm={handleUnsubscribe}
            okText="Yes"
            cancelText="No"
          >
            <Button
              className="rounded-[50px] border-2 border-white bg-none bg-transparent font-bold absolute left-7 bottom-10"
            >
              Unsubscribe
            </Button>
          </Popconfirm>
        )
      else 
        return (
          <Button
            onClick={handleSubscribe}
            className="rounded-[50px] border-2 border-white bg-none bg-transparent font-bold absolute left-7 bottom-10"
          >
            Subscribe
          </Button>
        )
    }
  }

  return (
    <Card
      className="p-[30px] shadow-2xl rounded-[20px] h-full"
      sx={() => ({
        background: `url(${welcome})`,
        backgroundSize: "cover",
        borderRadius: "20px",
        height: "100%",
        minHeight: "400px",
      })}
    >
      <VuiBox display="flex" flexDirection="column" sx={{ height: "100%" }}>
        <VuiBox display="flex" flexDirection="column" mb="auto">
          <VuiTypography
            className="text-[1rem]"
            variant="button"
            fontWeight="bold"
            mb="50px"
            color="white"
          >
            {tier.name}
          </VuiTypography>
          <VuiTypography variant="h1" fontWeight="bold" mb="35px" color="white">
            ${tier.price}
            <span className="text-[1.3rem]">&ensp;/month</span>
          </VuiTypography>
          <VuiTypography
            className="text-[1rem] md:leading-loose sm:leading-0"
            variant="h5"
            fontWeight="bold"
            color="white"
          >
            {benefit?.map((benefit) => (
              <span key={benefit}>
                <CheckCircleFilled />
                &ensp;{benefit}
                <br />
              </span>
            ))}
          </VuiTypography>
          <VuiTypography
            id="payment"
            className="mt-auto text-white text-[1rem]"
          >
            {renderSubscribeButton()}
          </VuiTypography>
        </VuiBox>
      </VuiBox>
    </Card>
  );
};

export default Tier;
