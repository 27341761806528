import { Button, Form, Input, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useDispatch } from "react-redux";
import { fetchDetailData } from "store/projectSlice";
import { embeddingFAQ } from "store/projectSlice";

export default function FAQInputGroup({ project }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    const { question, answer } = values;

    dispatch(embeddingFAQ({ projectId: project.id, question, answer })).then(
      ({ error, payload }) => {
        if (error) {
          message.error(error.message);
        }
        if (payload) {
          message.success(payload.message);
          dispatch(fetchDetailData(project.id)); // re-fetch data for project.fileEmbeddings's state
          form.resetFields();
        }
      }
    );
  };

  return (
    <>
      <Form layout="vertical" id="faq-form" form={form} onFinish={handleSubmit}>
        <Form.Item label="Question" name="question" labelCol={{ span: 4 }} >
          <Input placeholder="What is the capital of France?" />
        </Form.Item>
        <Form.Item label="Answer" name="answer" labelCol={{ span: 4 }} >
          <TextArea placeholder="The capital of France is Paris" />
        </Form.Item>
        <Button form="faq-form" className="flex-1" type="primary" htmlType="submit">
          Add
        </Button>
      </Form>
    </>
  );
}
