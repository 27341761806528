

// @mui material components
// @mui icons
import Grid from "@mui/material/Grid";
// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import Footer from "examples/Footer";
// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// Overview page components
import Header from "./components/Header";
import TierGroup from "./components/TierGroup";
// Vision UI Dashboard React example components
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Spin } from 'antd'
import { useEffect, useState } from "react";
import {fetchPackages} from 'store/orderSlice';
function Upgrade() {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const packages = useSelector(state => state.order.packages)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    dispatch(fetchPackages()).then(() => {
      setIsLoading(false)
    })
  }, [])

  return (
    <DashboardLayout>
      <DashboardNavbar light/>
      <Header user={user}/>
      <VuiBox mt={5} mb={3}>
        <Row gutter={25} align="space-between">
          <Col span={24}>
            { isLoading ? 
              <div className="s-spin-container"><Spin tip="Loading..."/></div> :
              <TierGroup packages={packages}/>
            }
          </Col>
        </Row>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Upgrade;
