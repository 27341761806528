import React from 'react'
import { DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Modal, Space } from 'antd';
import { useDispatch } from 'react-redux';
import {deleteData} from 'store/projectSlice'
const { confirm } = Modal;
export default function DeleteProject({id}) {
  const dispatch = useDispatch();
  const showDeleteConfirm = () => {
    confirm({
      title: 'Are you sure delete this project?',
      icon: <ExclamationCircleFilled />,
      content: 'This action cannot be undone. All project data and associated files will be permanently deleted. Please note that this action is irreversible and any information that is deleted cannot be retrieved.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        dispatch(deleteData(id))
      },
      onCancel() {
      },
    });
  };

  return (
    <span onClick={showDeleteConfirm}><DeleteOutlined className="mr-2"/>Delete Project</span>
  )
}
