import { FilterOutlined } from "@ant-design/icons";
import { classNames } from "helpers/utils";

export default function FilterTagButton({ onClick, isFiltered }) {
  return (
    <button
      onClick={onClick}
      type="button"
      className={classNames(
        isFiltered && "bg-white/20",
        "transition-colors ml-2 active:bg-slate-300/50 hover:bg-slate-300/20 bg-transparent  border-slate-300/40 rounded-lg w-9 h-9 flex items-center justify-center"
      )}
    >
      <FilterOutlined className="flex items-center text-white text-xl" aria-hidden="true" />
    </button>
  );
}
