import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Modal, Input, message, Form, Button } from "antd";

// @mui material components
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import rgba from "assets/theme/functions/rgba";

import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";
import { CloseOutlined, CheckOutlined, GoogleOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";

// slice
import { authenticatorSignin, authenticatorSigninGoogle, resendVerifyEmail } from "store/authenticatorSlice";
import { userSuccessfullyValidated } from "store/userSlice";

// react-router-dom components
import { Link, useLocation } from "react-router-dom";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgSignIn from "assets/images/signInImage.png";
import { useGoogleLogin } from "@react-oauth/google";
import ForgotPassword from "./ForgotPassword";
function SignIn() {
  //-----------State, useEffect, variable--------
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  //For loading animation
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  //
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const redirect_to = params.get('redirect_to')
  //---------------------UI-----------------------
  //input styles
  const inputStyle = {
    background: "none",
    backgroundColor: "none",
    border: "0.5px solid #e5e7eb",
    borderRadius: "7px",
  };

  //button styles
  const buttonStyle = {
    width: "100%",
  };

  const showResendEmailModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setConfirmLoading(true);
    dispatch(resendVerifyEmail(form.getFieldValue('email')))
      .then((res) => {
        setConfirmLoading(false);
        if(res.payload.statusCode === 200) {
          setIsModalOpen(false);
          message.success("Resend email successfully");
        } else {
          message.error(res.payload.message);
        }
      })
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  //-----------------Data handle------------------

  //submit the form and sign in the account
  const onFinish = async (data) => {
    try {
      setLoading(true);
      const res = await dispatch(
        authenticatorSignin({
          email: data.email,
          password: data.password,
        })
      );

      //Code success 200
      if (res.payload.statusCode === 200) {
        setLoading(false);
        // if(res.payload.data.is_verify) {
          Cookies.set("authToken", res.payload.data.access_token);
          dispatch(userSuccessfullyValidated(res.payload.data));
          //Navigate to the main page
          if(redirect_to) history.push(redirect_to);
          else history.push("/projects");
        // } 
      } else if(res.payload.statusCode === 422) {
        showResendEmailModal();
        setLoading(false);
      } else {
        //Feedback the failure result to the client
        message.error(res.payload.message);
        setLoading(false);
      }
    } catch (err) {
      message.error("Something went wrong, please try again");
      setLoading(false);
    }
  };

  const signinWithGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const res = await dispatch(authenticatorSigninGoogle({code: tokenResponse.code}))
      //Code success 200
      if (res.payload.statusCode === 200) {
        //Save token to the cookie
        Cookies.set("authToken", res.payload.data.access_token);
        dispatch(userSuccessfullyValidated(res.payload.data));
        //Navigate to the main page
        if(redirect_to) history.push(redirect_to);
        else history.push("/projects");
        //Other code
      } else {
        //Feedback the failure result to the client
        message.error(res.payload.message);
        setLoading(false);
      }
    },
    onError: (err) => message.error(err),
    flow: "auth-code",
  });

  return (
    <CoverLayout
      title="Time to make something awesome"
      color="white"
      premotto="INSPIRED BY THE FUTURE:"
      motto="THE MAKERGPT PLATFORM"
      image={bgSignIn}
    >
      <Stack
        mb="25px"
        justifyContent="center"
        alignItems="center"
        direction="row"
        spacing={2}
      >
        <Button onClick={() => signinWithGoogle()} type="text" className="rounded-[50px] border-t-[0.25px] border-b-[0.25px] border-l-[0.25px] border-r-[0.25px] border-slate-400 py-[8px] text-[15px] h-fit w-full" icon={<GoogleOutlined />}>Sign in with Google</Button>
      </Stack>
      <VuiTypography
        color="text"
        fontWeight="bold"
        textAlign="center"
        mb="14px"
        sx={({ typography: { size } }) => ({ fontSize: size.lg })}
      >
        or
      </VuiTypography>
      <VuiTypography
        color="text"
        textAlign="center"
        mb="14px"
        sx={({ typography: { size } }) => ({ fontSize: size.sm })}
      >
        Enter your email and password to sign in
      </VuiTypography>
      <Form component="form" role="form" onFinish={onFinish} form={form}>
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography
              component="label"
              variant="button"
              color="white"
              fontWeight="medium"
            >
              Email
            </VuiTypography>
          </VuiBox>
          <Form.Item
            name="email"
            rules={[{ required: true, message: "An email is required" }]}
          >
            <Input
              type="email"
              placeholder="Your email..."
              fontWeight="500"
              style={inputStyle}
            />
          </Form.Item>
        </VuiBox>
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography
              component="label"
              variant="button"
              color="white"
              fontWeight="medium"
            >
              Password
            </VuiTypography>
          </VuiBox>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "A password is required" }]}
          >
            <Input
              type="password"
              placeholder="Your password..."
              style={inputStyle}
            />
          </Form.Item>
        </VuiBox>
        <VuiBox mt={4} mb={1}>
          <Form.Item>
            <Button
              loading={loading}
              type="primary"
              style={buttonStyle}
              htmlType="submit"
            >
              SIGN IN
            </Button>
          </Form.Item>
        </VuiBox>
        <VuiBox mt={3} textAlign="center">
          <ForgotPassword />
        </VuiBox>
        <VuiBox mt={1} textAlign="center">
          <VuiTypography variant="button" color="text" fontWeight="regular">
            Don&apos;t have an account?{" "}
            <VuiTypography
              component={Link}
              to="/sign-up"
              variant="button"
              color="white"
              fontWeight="medium"
            >
              Sign up
            </VuiTypography>
          </VuiTypography>
        </VuiBox>
      </Form>
      <Modal title="Verification" 
        open={isModalOpen} 
        onOk={handleOk} 
        okText="Resend Email"
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
      >
        Oops! We're sorry, but you must first verify your account by clicking on the verification link sent to your registered email address. This additional step ensures the security and authenticity of your account. Please check your inbox for the verification email. If you haven't received it, kindly check your spam folder or request a new verification email.
      </Modal>
    </CoverLayout>
  );
}

export default SignIn;