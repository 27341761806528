export const cover_letter = {
    avoidwords: '',
    category: 'Productivity, Fun',
    chatinputlength: 100,
    description: 'This form creates professional cover letters in seconds! Just answer three simple questions.',
    documentid: null,
    formdescription: 'This form creates professional cover letters in seconds! Just answer three simple questions.',
    formid: 'Auto_Cover_Letter_T4MR0',
    formtitle: 'Auto Cover Letter',
    freqpenalty: 0,
    imageortext: 'text',
    insertionpoints: '3',
    maxlength: 1000,
    membuffer: 1000,
    model: 'gpt-3.5-turbo',
    owner: 'gikizay@gmail.com',
    placeholdertext: '',
    presencepenalty: 0,
    privacysetting: 'public',
    prompt: 'A company is hiring a  <mark contenteditable="false" class="prompt-tag" id="f596e239-5584-4a66-a950-25e6a5c75923">Software Engineer</mark> They wrote the following job description.  <mark contenteditable="false" class="prompt-tag" id="bb03f417-b2b0-4434-b2b6-fff21961cd2c">The guy who makes software</mark> You are applying for the job. You have the following skills:  <mark contenteditable="false" class="prompt-tag" id="9a78953c-fde3-4f7b-a823-39abff0ad5f1">Kindness, empathy, and a sense of humor</mark> Write a 500-word cover letter for the above job that mentions your skills and explains why you\'re a great fit',
    'public': 'false',
    questions: [
      {
        id: 'f596e239-5584-4a66-a950-25e6a5c75923',
        answerlength: '90',
        example: 'Software Engineer',
        main: 'What job are applying for?',
        secondary: '',
        type: 'text'
      },
      {
        id: 'bb03f417-b2b0-4434-b2b6-fff21961cd2c',
        answerlength: '90',
        example: 'The guy who makes software',
        main: 'What is the job description? Paste it below and don\'t worry about formatting.',
        secondary: '',
        type: 'text'
      },
      {
        id: '9a78953c-fde3-4f7b-a823-39abff0ad5f1',
        answerlength: '90',
        example: 'Kindness, empathy, and a sense of humor',
        main: 'What are some skills you want to mention in your cover letter? Use brief buzzwords like \'SEO copywriting\' or \'working on cross-functional teams\'',
        secondary: '',
        type: 'text'
      }
    ],
    responseprefix: '',
    role: 'You are an adaptive and proficient assistant, dedicated to thoroughly understanding user inquiries and providing well-rounded and tailored solutions that effectively cater to their expectations. You know everything about the world',
    stopwords: '',
    success: true,
    temperature: 0.8,
    timestamp: 1683857949.8434775,
    title: 'Auto Cover Letter Sample',
    usewords: ''
};

export const faq = {
  avoidwords: '',
  category: 'Fun',
  chatinputlength: 100,
  description: 'This form will answer any questions you have about the upcoming conference!',
  documentid: null,
  formdescription: 'This form will answer any questions you have about the upcoming conference!',
  formid: 'Auto_Cover_Letter_T4MR0',
  formtitle: 'Restaurant Conference FAQbot',
  freqpenalty: 0,
  imageortext: 'text',
  insertionpoints: '1',
  maxlength: 1000,
  membuffer: 1000,
  model: 'gpt-3.5-turbo',
  owner: 'gikizay@gmail.com',
  placeholdertext: '',
  presencepenalty: 0,
  privacysetting: 'public',
  prompt: 'The Excalibur Restaurant Conference is a one-day conference on Wednesday May 15th. The conference is meant for restaurant owners to learn more about industry techniques and trends as well as network.<br /><br />\n        SPEAKERS: Joey Pasta is talking on trends in spaghetti, Charlotte Pierce on how to review a restaurant, Chef Pierre Raisin on how to make a menu, Lisa Buzz talks on NFTs and food<br /><br />\n        LOCATION: The Santa Clarita Hotel at 1800 Food Avenue<br /><br />\n        TICKETS: Tickets cost $300. Tickets cover food at the event, parking in the hotel, and accommodation in the hotel for two nights.<br /><br />\n        FOOD: There will be a free continental breakfast of pastries, coffee, and bagels. Lunch will be a full three-course meal. Dinner is not provided. Food is free for all ticketed attendees.<br /><br />\n        ACCOMMODATIONS: Free two-night stay at the Santa Clarita Hotel.<br /><br />\n        PARKING: The Santa Clarita Hotel has free parking for all guests.<br /><br /><br />\n        Answer the following question about The Excalibur Restaurant Conference. If the answer is not provided in the information above, give a logical answer without being too specific:<br /><br />\n        QUESTION:  <mark contenteditable="false" class="prompt-tag" id="6fa7f3cf-2cae-4c27-8f97-05a9ab338bbb">User Input</mark> <br />\n        \n        ANSWER:',
  'public': 'false',
  questions: [
    {
      id: '6fa7f3cf-2cae-4c27-8f97-05a9ab338bbb',
      answerlength: '90',
      example: 'User Input',
      main: 'What is your question about The Excalibur Restaurant Conference?',
      secondary: '',
      type: 'text'
    }
  ],
  responseprefix: '',
  role: 'You are an adaptive and proficient assistant, dedicated to thoroughly understanding user inquiries and providing well-rounded and tailored solutions that effectively cater to their expectations. You know everything about the world',
  stopwords: '',
  success: true,
  temperature: 0.8,
  timestamp: 1683858082.1744406,
  title: 'Restaurant Conference FAQbot',
  usewords: ''
};

export const explaining = {
  avoidwords: '',
  category: 'Productivity',
  chatinputlength: 100,
  description: 'This form will help you explain a concept to a 5 year old',
  documentid: null,
  formdescription: 'This form will help you explain a concept to a 5 year old',
  formid: 'Auto_Cover_Letter_T4MR0',
  formtitle: 'Explaining a Concept',
  freqpenalty: 0,
  imageortext: 'text',
  insertionpoints: '1',
  maxlength: 1000,
  membuffer: 1000,
  model: 'gpt-3.5-turbo',
  owner: 'gikizay@gmail.com',
  placeholdertext: '',
  presencepenalty: 0,
  privacysetting: 'public',
  prompt: 'Explain  <mark contenteditable="false" class="prompt-tag" id="9f60c099-ec8d-49ab-9ff3-3b1044a7fd2a">String Theory</mark>  to a 5 year old.',
  'public': 'false',
  questions: [
    {
      id: '9f60c099-ec8d-49ab-9ff3-3b1044a7fd2a',
      answerlength: '90',
      example: 'String Theory',
      main: 'What should be explained?',
      secondary: '',
      type: 'text'
    }
  ],
  responseprefix: '',
  role: 'You are an adaptive and proficient assistant, dedicated to thoroughly understanding user inquiries and providing well-rounded and tailored solutions that effectively cater to their expectations. You know everything about the world',
  stopwords: '',
  success: true,
  temperature: 0.8,
  timestamp: 1683858216.3585775,
  title: 'Explaining a Concept Sample',
  usewords: ''
};

export const marketing_campaign = {
  "title": "Marketing Campaign",
  "description": "Creates a Marketing Campaign Plan for your product or service",
  "prompt": "Prompt: Develop a marketing campaign for  <mark contenteditable=\"false\" class=\"prompt-tag\" id=\"e0f5b65b-59aa-4c76-bef6-4a653665c14b\">Coffee Mugs</mark>  targeting  <mark contenteditable=\"false\" class=\"prompt-tag\" id=\"5ac678c4-7ac3-4314-8a0e-6dade0528b24\">Male and Female</mark>  Office Staff. Outline the key messaging and channels you would use to reach this audience.<br /><br />Output Structure: Use the AIDA framework (Attention, Interest, Desire, Action) to structure the response. Provide a clear introduction, outline attention-grabbing tactics, describe compelling messaging and creative elements, and conclude with a call to action.",
  "questions": [
    {
      "id": "e0f5b65b-59aa-4c76-bef6-4a653665c14b",
      "answerlength": "255",
      "example": "Coffee Mugs",
      "main": "Product or Service",
      "secondary": "",
      "type": "text"
    },
    {
      "id": "5ac678c4-7ac3-4314-8a0e-6dade0528b24",
      "answerlength": "255",
      "example": "Male and Female",
      "main": "Target Demographic",
      "secondary": "",
      "type": "text"
    }
  ],
  "avoid_words": null,
  "freq_penalty": 0,
  "max_length": 1000,
  "model": "gpt-3.5-turbo",
  "presence_penalty": 0,
  "privacy_setting": null,
  "role": "Marketing Campaign Strategist",
  "stop_words": null,
  "temperature": 0.8,
  "use_words": null,
  "is_cloneable": false,
  "is_show_histories": false,
  "tags": []
}

export const travel_planner = {
  "title": "Travel Planner",
  "description": "Plans the itinerary of a trip to a specified destination given a specified budget",
  "prompt": "As an experienced travel consultant, create a customized travel itinerary with the following details:<br />1 .Destination:  <mark contenteditable=\"false\" class=\"prompt-tag\" id=\"c3e82987-474a-45bf-aa4f-38be56cb22ab\">Singapore</mark> <br />2. Duration of Stay:  <mark contenteditable=\"false\" class=\"prompt-tag\" id=\"4de5f1bc-f266-4ef4-a986-cd51062ee57b\">3</mark> <br />3. Budget (include currency):  <mark contenteditable=\"false\" class=\"prompt-tag\" id=\"0f234497-3df8-4ff3-866c-7c372bda87ac\">USD 1000</mark> <br />4. Travel Preferences:  <mark contenteditable=\"false\" class=\"prompt-tag\" id=\"f103f117-7205-4d30-ad74-3449c7fa4818\">Off the beaten track</mark> <br /><br />Based on the information provided, craft an exciting itinerary.<br />Optimize the budget to ensure a memorable experience.<br />Cite sources of the itinerary<br /><br />**Output Structure:**<br />Introduce the itinerary and explain the itinerary theme<br />[Day 1]- [Morning]:- [Afternoon]:- [Evening]:- [Accommodation]:<br />[Day 2]- [Morning]:- [Afternoon]:- [Evening]:- [Accommodation]:<br />...<br />[Day N]- [Morning]:- [Afternoon]:- [Evening]:- [Accommodation]:",
  "questions": [
    {
      "id": "c3e82987-474a-45bf-aa4f-38be56cb22ab",
      "answerlength": "255",
      "example": "Singapore",
      "main": "Country",
      "secondary": "",
      "type": "text"
    },
    {
      "id": "4de5f1bc-f266-4ef4-a986-cd51062ee57b",
      "answerlength": "255",
      "example": "3",
      "main": "Number of Days",
      "secondary": "",
      "type": "text"
    },
    {
      "id": "0f234497-3df8-4ff3-866c-7c372bda87ac",
      "answerlength": "255",
      "example": "USD 1000",
      "main": "Budget (include currency)",
      "secondary": "",
      "type": "text"
    },
    {
      "id": "f103f117-7205-4d30-ad74-3449c7fa4818",
      "answerlength": "255",
      "example": "Off the beaten track",
      "main": "Travel Preferences",
      "secondary": "",
      "type": "text"
    }
  ],
  "avoid_words": null,
  "freq_penalty": 0,
  "max_length": 1000,
  "model": "gpt-3.5-turbo",
  "presence_penalty": 0,
  "privacy_setting": null,
  "role": "Travel Planner",
  "stop_words": null,
  "temperature": 0.8,
  "use_words": null,
  "is_cloneable": false,
  "is_show_histories": false,
  "tags": []
}

const initData = {
  avoidwords: '',
  category: '',
  chatinputlength: 100,
  description: '',
  documentid: null,
  formdescription: '',
  formid: 'Auto_Cover_Letter_T4MR0',
  formtitle: '',
  freqpenalty: 0,
  imageortext: 'text',
  insertionpoints: '1',
  maxlength: 1000,
  membuffer: 1000,
  model: 'gpt-3.5-turbo',
  owner: '',
  placeholdertext: '',
  presencepenalty: 0,
  privacysetting: 'public',
  prompt: '',
  'public': 'false',
  questions:[],
  responseprefix: '',
  role: '',
  stopwords: '',
  success: true,
  temperature: 0.8,
  title: '',
  usewords: '',
  responsePrompt: ''
}

export const course_creator = {
  "title": "Course Creator",
  "description": "Creates a course outline and lesson plan given a topic and the learners' proficiency level",
  "prompt": "Craft a comprehensive course outline based on<br /><br />(a) Topic:  <mark contenteditable=\"false\" class=\"prompt-tag\" id=\"17331d3b-8a90-446a-a49d-10339c403637\">Python Coding</mark> <br />(b) Learners' Proficiency Level:  <mark contenteditable=\"false\" class=\"prompt-tag\" id=\"34d22ac4-6e71-4305-bc6c-b2fa628af6f0\">Beginner</mark> <br /><br />Output Structure:<br />- Course Title: [Title of the Course]<br />- Course Objectives:<br />Objective 1: [Objective statement describing the primary goal of the course]<br />Objective 2: [Objective statement elaborating on a secondary goal, if applicable]...<br />- Lessons:<br />a. Lesson 1: [Title of Lesson 1]<br />Lesson Objective: [Objective statement defining the purpose of this lesson]<br />Key Topics Covered:[Topic 1][Topic 2]...<br /><br />b. Lesson 2: [Title of Lesson 2]<br />Lesson Objective: [Objective statement defining the purpose of this lesson]<br />Key Topics Covered:[<br />Topic 1][Topic 2]...<br />...... (continue with additional lessons as necessary)<br /><br />Your comprehensive and well-structured courses will enable students to acquire essential knowledge, enhance their skills, and achieve their learning goals effectively. As you create these courses, tailor the content and delivery to suit the unique requirements of each experience level, fostering a dynamic and rewarding learning experience for all participants.",
  "questions": [
    {
      "id": "17331d3b-8a90-446a-a49d-10339c403637",
      "answerlength": "255",
      "example": "Python Coding",
      "main": "Topic",
      "secondary": "",
      "type": "text"
    },
    {
      "id": "34d22ac4-6e71-4305-bc6c-b2fa628af6f0",
      "answerlength": "255",
      "example": "Beginner",
      "main": "Learners' Proficiency Level",
      "secondary": "[{\"label\":\"Beginner\",\"value\":\"0d2d6d40-60b5-4a84-ba52-837452735da4\"},{\"label\":\"Intermediate\",\"value\":\"7df3a027-8b01-4cd5-b571-430f3de3bfa2\"},{\"label\":\"Advanced\",\"value\":\"34a8feb0-314b-4f28-a785-7c8f71b2d1f7\"}]",
      "type": "single_choice"
    }
  ],
  "avoid_words": null,
  "freq_penalty": 0,
  "max_length": 1000,
  "model": "gpt-3.5-turbo",
  "created_by_manager_id": 4,
  "presence_penalty": 0,
  "privacy_setting": null,
  "role": "Course Creator",
  "stop_words": null,
  "temperature": 0.8,
  "use_words": null,
  "is_cloneable": false,
  "is_show_histories": false,
  "tags": []
}

const dumpData = {
  cover_letter: cover_letter,
  faq: faq,
  explaining: explaining,
  marketing_campaign,
  travel_planner,
  course_creator,
  initData: initData
}
export default dumpData