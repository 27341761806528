export const planTypes = {
  free_trail: {
    value: 0,
    label: 'Free Trial'
  },
  starter: {
    value: 1,
    label: 'Starter'
  },
  processional: {
    value: 2,
    label: 'Processional'
  },
};