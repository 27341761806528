import { Row, Col, Space, Input, Button, Form } from "antd";
import { AudioOutlined, SyncOutlined } from "@ant-design/icons";
import defaultWidgetLogo from "assets/images/makergpt/maker_gpt_transparent.png";
import { useState, useEffect, useRef } from "react";
import Messages from "./components/Messages";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchData as fetchMessageData,
  fetchNextData as fetchNextMessageData,
  addMessage,
  addChatBotMessage,
  doneAddingChatBotMessage
} from "store/messageSlice";
import io from "socket.io-client";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";
import { fetchUserProfile } from "store/userSlice";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';


const Widgets = ({ widget }) => {
  const [loadingNextMessage, setLoadingNextMessage] = useState(false);
  const [token, setToken] = useState(uuidv4());
  const [thinking, setThinking] = useState(false);
  const message = useSelector((state) => state.message);
  const socket = useRef(null);
  const [form] = Form.useForm();
  const scrollRef = useRef();
  const dispatch = useDispatch();
  const { transcript, resetTranscript, listening } = useSpeechRecognition();
  const role = useSelector((state) => state.widget?.widget?.role);

  useEffect(() => {
    // resolve the websocket connection when first loaded
    socket.current = io(process.env.REACT_APP_BASE_URL, {
      path: process.env.REACT_APP_SOCKET_PATH,
      transports: ["websocket"],
    });

    // Receiving messages from the server
    socket.current.on("receive_message", (data) => {
      if (data === null) return
      dispatch(addChatBotMessage(data))
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    });

    // Receiving end-messages from the server
    socket.current.on("end_message", (data) => {
      if (data === null && data.status !== 200) return
      dispatch(doneAddingChatBotMessage())
      dispatch(fetchUserProfile())
      setThinking(false);
    });

    //Disconnect socket when leaving the page
    return () => socket.current.disconnect();
  }, []);

  useEffect(async () => {
    //infinite scrolling event
    const handleScroll = () => {
      if (scrollRef.current.scrollTop !== null && scrollRef.current.scrollTop < 10) {
        setLoadingNextMessage(true);
      }
    };

    //fetching message
    await dispatch(
      fetchMessageData({
        token: validateToken(),
        widgetToken: widget.token,
        page: 1,
        limit: 10,
      })
    );
    //scroll to the bottom by default
    scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    //adding infinite scrolling event
    scrollRef.current.addEventListener("scroll", handleScroll);
    return () => scrollRef.current.removeEventListener("scroll", handleScroll);
  }, []);
  //Infinite scrolling useEffect
  useEffect(async () => {
    if (loadingNextMessage === true && message.hasNextPage && scrollRef.current.scrollHeight !== null) {
      const oldHeight = scrollRef.current.scrollHeight;
      await dispatch(
        fetchNextMessageData({
          token: token,
          widgetToken: widget.token,
          page: message.page,
          limit: 10,
        })
      );
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight - oldHeight;
      setLoadingNextMessage(false);
    }
  }, [loadingNextMessage]);

  // Validate the widget and decided if it will load the old message list or not?
  const validateToken = () => {
    const browserTempToken = Cookies.get("browserTempToken");
    //Check if unavailable cookie value
    if (
      browserTempToken !== null &&
      browserTempToken !== undefined &&
      browserTempToken !== ""
    ) {
      //Check if the CookieID is correct
      const [cookieID, cookieToken] = browserTempToken.split(", ");
      if (cookieID == widget.token) {
        setToken(cookieToken);
        return cookieToken;
      }
    }
    Cookies.set("browserTempToken", `${widget.token}, ${token}`, { expires: 365 })
    return token;
  };
  //Sending the message
  const sendMessage = (value) => {
    if (value.input === undefined || value.input.trim().length === 0) return;
    setThinking(true);
    socket.current.emit("send_message", {
      uid: token,
      content: value.input,
      widget_token: widget.token,
      role: role
    });
    dispatch(
      addMessage({
        kind: 0,
        content: value.input,
      })
    )
    
    form.resetFields();
    resetTranscript();
    scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
  };

  const handleSpeech = (listening) => {

    if(listening) {
      resetTranscript();
      SpeechRecognition.startListening({
        continuous: true,
        language: 'en-GB',
      })
    }
    else SpeechRecognition.stopListening()
  };

  useEffect(() => {
    form.setFieldsValue({
      input: transcript,
    });
  }, [transcript]);

  return (
    <Form
      form={form}
      onFinish={sendMessage}
      id="loading"
      className="w-full h-[33rem] center-an-item"
    >
      <Row className="h-full w-full bg-white shadow-md">
        <Col
          className="h-1/5 p-[5px] shadow-lg"
          style={{
            backgroundColor: widget.bot_background_color,
          }}
          span={24}
        >
          <Row className="h-full" gutter={15}>
            <Col sm={4} xs={7} className="center-an-item flex">
              <img src={widget?.logo || defaultWidgetLogo} className="h-[4.5rem] w-[4.5rem] rounded-lg" />
            </Col>
            <Col sm={20} xs={17} className="h-full">
              <Row className="w-full h-full">
                <Col
                  span={24}
                  className="h-[40%] font-bold text-2xl flex items-center"
                  style={{
                    color: widget.customer_background_color,
                  }}
                >
                  {widget.bot_name}
                </Col>
                <Col
                  span={24}
                  className="h-[60%] flex items-start text-[11px] md:text-base"
                  style={{
                    color: widget.customer_background_color,
                  }}
                >
                  {widget.text1} <br /> {widget.text2}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col
          className="h-[65%] p-4 overflow-y-scroll"
          span={24}
          ref={scrollRef}
        >
          {message.message !== null ? (
              <Messages
                widget={widget}
                data={message.message}
                hasNextPage={message.hasNextPage}
              />
            ) : (
              <></>
            )}
        </Col>
        <Col className="h-[15%]" span={24}>
          <Row justify="center" className="h-full w-full center-an-item">
            <Col className="center-an-item" span={23}>
              <Space.Compact className="w-full">
                <Button
                  onClick = {() => handleSpeech(!listening)}
                  icon={<AudioOutlined className={listening ? "text-[#f53e2d] font-bold" : "text-[#002e77] font-bold"} />}
                  className="h-10 bg-transparent border-none"
                ></Button>
                <Form.Item name="input" initialValue={transcript} className="w-[90%]">
                  <Input
                    id="input"
                    placeholder="Ask me anything..."
                    disabled={thinking}
                    className="placeholder:text-[#7f7f80] h-10 bg-slate-200 border-none text-[#515152] focus:shadow-none focus:bg-slate-300"
                    autoComplete="off"
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    className="h-10 shadow-none font-bold outline-none border-0"
                    style={{
                      color: widget.customer_background_color,
                      backgroundColor: widget.bot_background_color,
                    }}
                    disabled={thinking}
                    htmlType="submit"
                  >
                    {thinking === true ? (
                      <span>
                        <SyncOutlined spin /> Sending
                      </span>
                    ) : (
                      "Send"
                    )}
                  </Button>
                </Form.Item>
              </Space.Compact>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default Widgets;
