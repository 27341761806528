import { Button, Form, ColorPicker, Select, Upload, Switch, Tooltip, message } from 'antd';
import { ChromePicker } from "react-color";
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateData } from "store/widgetSlice";
import { updateColorSettings, updateColorSettingForm } from "store/promptSlice";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import UploadAvatar from './UploadAvatar';
import { getBase64 } from 'helpers/utils';
const { Option } = Select;
const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 19,
  },
};
const tailLayout = {
  wrapperCol: {
    span: 24,
  },
};
const InformationForm = ({project}) => {
  const formRef = React.useRef(null);
  const dispatch = useDispatch();
  const colorSettings = useSelector((state) => state.prompt.colorSettings);
  const [isGradient, setIsGradient] = useState(colorSettings.background.isGradient);
  const [avatarFile, setAvatarFile] = useState(undefined);
  const defaultFileList = colorSettings.avatarUrl && colorSettings.avatarUrl.length ? [{
    uid: '0',
    name: 'project_avatar.png',
    status: 'done',
    url: colorSettings.avatarUrl,
  }] : []

  const onFinish = (values) => {
    let formData = new FormData();
    if (avatarFile && avatarFile.status !== "removed") formData.append('logo', avatarFile);
    else formData.append('logo', null);
    
    formData.append('is_gradient', colorSettings.background.isGradient);
    formData.append('bg_color0', colorSettings.background.background_0);
    formData.append('bg_color1', colorSettings.background.background_1);
    formData.append('bg_color2', colorSettings.background.background_2);
    formData.append('bg_color3', colorSettings.background.background_3);
    formData.append('text_color', colorSettings.text);

    dispatch(updateColorSettingForm({
      prompt_id: project.prompt_template.id,
      payload: formData
    })).then(({error}) => {
      if (error) {
        message.error(error.message);
      } else {
        message.success('Updated settings successfully');
      }
    })
  };
  const onReset = () => {
    formRef.current?.resetFields();
  };
  const onColorChange = (color, key) => {
    dispatch(updateColorSettings({background: {...colorSettings.background, [key]: color}}));
  }
  const onTextColorChange = (color, key) => {
    dispatch(updateColorSettings({[key]: color}));
  }
  const handleGradientChange = (checked) => { 
    setIsGradient(checked);
    dispatch(updateColorSettings({background: {...colorSettings.background, isGradient: checked}}));
  }

  const handleAvatarUrlChange = async (file) => {
    try {
      const _file = (file && file.status !== "removed") ? file : null
      setAvatarFile(_file)
      let avatarUrl = await getBase64(_file);
      dispatch(updateColorSettings({avatarUrl: avatarUrl}));
    } catch (error) {
    }
  }
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <Form
      {...layout}
      ref={formRef}
      name="control-ref"
      onFinish={onFinish}
      className="my-4"
      initialValues={{...project.widget, text_color: colorSettings.text}}
    >
      <Form.Item
        name="image"
        label="Featured Image"
        getValueFromEvent={normFile}
        valuePropName="fileList"
      >
        <UploadAvatar setAvatarUrl={handleAvatarUrlChange} 
          defaultFileList={defaultFileList}
        />
      </Form.Item>
      <Form.Item
        name="background"
        label="Background Color"
      >
        <div className='flex justify-between items-center'>
          <div>
            { !isGradient ? 
              <ColorPicker onChange={(color, hex) => onColorChange(hex, 'background_0')} value={colorSettings.background.background_0}/> :
              <div className='flex gap-3'>
                <ColorPicker onChange={(color, hex) => onColorChange(hex, 'background_1')} value={colorSettings.background.background_1}/>
                <ColorPicker onChange={(color, hex) => onColorChange(hex, 'background_2')} value={colorSettings.background.background_2}/>
                <ColorPicker onChange={(color, hex) => onColorChange(hex, 'background_3')} value={colorSettings.background.background_3}/>
              </div>
            }
          </div>
          <Tooltip title="Gradient Color">
            <Switch defaultChecked={isGradient} onChange={handleGradientChange}/>
          </Tooltip>
        </div>
      </Form.Item>
      <Form.Item 
        name="text_color"
        label="Text Color"
      >
        <ColorPicker onChange={(color, hex) => onTextColorChange(hex, 'text')}/>
      </Form.Item>
      <Form.Item {...tailLayout}>
        <div className='flex justify-between'>
          <Button htmlType="button" onClick={onReset}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
export default InformationForm;