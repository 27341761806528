// @mui material components
import Card from "@mui/material/Card";
import { Icon } from "@mui/material";
// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Grid from "@mui/material/Grid";
import React, { useEffect } from 'react'
import InputCopy from "./InputCopy";
export default function Publish({project}) {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = window.location.origin + '/public_scripts/widgets/asyns_iframe.js';
    script.type = 'text/javascript';
    script.id = 'widget-webchat';
    script.setAttribute('agent-token', project?.widget?.token)
    script.setAttribute('icon-color', '#1668dc')
    document.body.appendChild(script);

    return () => {
      let webchat = document.getElementById('wraper-webchat')
      if(webchat) webchat.remove()
    }
  }, []);

  const scriptLink = () => {
    const web_url = window.location.origin + '/public_scripts/widgets/asyns_iframe.js'
    const agent_token = project?.widget?.token
    const icon_color = '#1668dc'
    return `<script src='${web_url}' id='widget-webchat' agent-token='${agent_token}' icon-color='${icon_color}' type='text/javascript'></script>`
  }

  return (
    <VuiBox py={3}>
      <VuiBox mb={3}>
        <Grid container spacing={3} >
          <Grid item xs={12} xl={12} height="100%">
            <Card>
              <VuiBox >
                <VuiTypography variant="lg" fontWeight="bold" color="white" textTransform="capitalize">
                  Embed script / Link
                </VuiTypography>
              </VuiBox>
              <div>
                <div className="my-4">
                  <div className="text-white text-base">Embed the widget</div>
                  <div className="text-white text-xs">Copy the JavaScript below and insert it into the HEAD or BODY tags of your HTML page to embed the Widget.</div>
                </div>
                <InputCopy link={scriptLink()}/>
              </div>
              <div>
                <div className="my-4">
                  <div className="text-white text-base">Chat with the bot</div>
                  <div className="text-white text-xs">Navigate to the URL below to chat with the bot.</div>
                </div>
                <InputCopy link={`${window.location.origin}/webchat/${project.widget.token}`} type={"URL"}/>
              </div>
            </Card>
            <div>
            </div>
          </Grid>
        </Grid>
      </VuiBox>
    </VuiBox>
  )
}
