import { classNames } from "helpers/utils";

function Spinner({title, shouldSpin, onClick, isDisabled = false}) {
  return (
    <button
      disabled={isDisabled}
      title={title}
      className={classNames(shouldSpin && "animate-spin animate-infinite animate-duration-1000","flex rounded-full aspect-square  w-12 h-12 shrink-0 justify-center items-center  ring-inset  text-base font-medium text-white hover:bg-[#0e1122] active:bg-darkBlue-500/20 active:outline-none active:ring-2 active:ring-red active:ring-offset-gray-100  hover:bg-blend-darken active:transition-transform  active:-rotate-180")}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="2"
        stroke="currentColor"
        aria-hidden="true"
        height="22"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
        ></path>
      </svg>
    </button>
  );
}

export default Spinner;
